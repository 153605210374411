import { Dialog, DialogContent, DialogTitle, Slide, IconButton, Box, Paper, Backdrop, Container, useMediaQuery, Hidden } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AIChatHeader from './AIChatHeader';
import ChatContainer from './ChatContainer';
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function AiChatModal({ aiModalOpen, setAiModalOpen }) {
    const user = useSelector(state => state.userReducer.user);
    const matches = useMediaQuery('(max-width:600px)');

    const handleClose = (e) => {
      setAiModalOpen(false);
    }

    const handleEscapeKey = (e) => {
      if(e.key === 'Escape') {
        handleClose();
      }
    }

    React.useEffect(() => {

      window.addEventListener('keydown', handleEscapeKey);

      return () => {
        window.removeEventListener('keydown', handleEscapeKey);
      }
    }, []);
  return (
    <Backdrop
        open={aiModalOpen}
        // onClick={handleClose}
        onClick={(e) => {
          if(e.target === e.currentTarget) {
            handleClose();
          }
        }}
        sx={{ zIndex: 900, height: matches ? "calc(100vh - 56px)" : "100vh" }} // zIndex less than bottom tabs zIndex. 
            aria-hidden={false}
    >
    <Box
      sx={{
        position: 'fixed',
        bottom: matches? 56 : 0,
        right: 0,
        marginBottom: 0,
        width: matches ? '90%' : '500px',
        height: matches ?'500px' : '600px',
        // bgcolor: 'background.paper',
        boxShadow: 24,
        p: 0,
        // border:"2px solid red",
        borderRadius: '7px',
        display: 'flex',
        flexDirection: 'column',
      }}
      onClick={(e) => e.stopPropagation()}
      role="dialog"
      aria-modal="true"
      aria-hidden={false}
    >
      <Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', bgcolor: 'background.paper' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
         <AIChatHeader />
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                alignItems: 'center',
                color: '#fff'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
         <Box sx={{ flex: 1, overflow: 'auto' }}>
            <ChatContainer user={user} trigger={aiModalOpen}/>
         </Box>
      </Paper>
    </Box>
  </Backdrop>
  );
}

export default AiChatModal;