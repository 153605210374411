import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    InputLabel,
    Box,
    MenuItem,
    Tooltip,
    Typography
} from "@mui/material";
import { SelectValidator } from "react-material-ui-form-validator";
import LanguageTranslate from '../../partials/UnderScoreLanguage'
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    toolTipIcon: {
        fontSize: 14,
        marginLeft: 5
    },
}));

/**
 *
 * @name PaymentTerms
 * @alias PaymentTerms
 * @component
 * @exports PaymentTerms
 *
 * @description
 * Payment Terms input field
 *
 */

export default function PaymentTerms(props) {
    const classes = useStyles();

    return (
        <Box >
            {/* <InputLabel className={classes.marginBottom}><LanguageTranslate s='Payment Terms' /><sup style={{ color: "red" }}>*</sup>
              
            </InputLabel> */}
            <SelectValidator
                native="true"
                variant="outlined"
                name="payment_term_id"
                fullWidth
                required
                label= <span style={{ fontSize: '13px'}}>Payment Terms</span>
                size="small"
                validators={[
                    "required",
                ]}
                errorMessages={[
                    "Payment Terms field is required",
                ]}
                value={props.paymentTermId}
                onChange={(e, newval) => {
                    //    console.log(newval.props)
                   
                    props.setPaymentTermId(e.target.value);
                    props.setvarName(newval.props.varname);
                    props.setvarPlaceholder(newval.props.varPlaceholder);
                    props.setvarDefaultValue(newval.props.varDefaultValue);
                    // props.setAdvance(e.target.value == 4 ? 10 : 0);
                    // props.setNumberOfCreditDays(e.target.value == 10 ? 0 : (e.target.value == 2 || e.target.value == 3) ? '30' : '');
                }}
            >
                {/* {(props.paymentTermId != 6 && props.paymentTerms && props.paymentTerms.length > 0) && props.paymentTerms.map((p, key) => (
                    <MenuItem
                        value={p.id}
                        key={key}
                    >
                        {p.payment_title}
                    </MenuItem>
                ))} */}
                {(props.paymentTerms.length > 0) && props.paymentTerms.map((p, key) => (
                        <MenuItem
                            value={p._id}
                            varname={p.varName}
                            varPlaceholder={p.varPlaceholder}
                            varDefaultValue={p.varDefaultValue}
                            key={key}
                        >
                            {p.name}
                        </MenuItem>))}
               
            </SelectValidator>
        </Box>
    );
}
