import React, { useState, useRef, useEffect } from 'react';
import { Box, Paper, CircularProgress } from '@mui/material';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { AI_ASSISTANT_ENDPOINTS, DEFAULT_HEADERS } from './config/api_config';
import { CommodityAssistant } from '../../api/resources/CommodityAssistant';

const  ChatContainer = ({ user, trigger }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  React.useEffect(() => {
    if(trigger === true) {
      initializationAssistant();
    }
  }, [trigger]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getDeliveryAddresses = () => {
    if (!user || !user.companies) {
        return [];
    }

    // Find the active company
    const activeCompanyId = user.activeCompanyId;
    const activeCompany = user.companies.find(
        company => company._id === activeCompanyId
    );

    // Return the addresses from the active company
    return activeCompany?.addresses || [];
}

    /**
     * Sends user data to the backend
     * @returns {Promise<Object>} - Backend response
     */
    const initializationAssistant = async() => {
      try {
          const userData = {
            _id: user._id,
            firstName: user.firstName,  
            lastName: user.lastName,    
            deliveryAddresses: getDeliveryAddresses().map(address => ({
                _id: address._id,
                full: address.full
            }))
          };

          // const response = await fetch(AI_ASSISTANT_ENDPOINTS.INITIALIZE_USER, {
          //     method: 'POST',
          //     headers: DEFAULT_HEADERS,
          //     body: JSON.stringify(userData)
          // });
          // console.log('user data stringed: ', JSON.stringify(userData))
          // const params = {
          //   userData: userData
          // }
          const response = await CommodityAssistant.initializeUser(userData);
          // const response = await axios.post(`${config.ai_api_commodity_url}/initialize-user`, userData, { headers: { 'Content-Type': 'application/json', 
          //  } });
          // console.log('response axios: ', response)

          if(response.status === 200) {
            const initialMessage = `Hi, I'm ${user.firstName}. Menu`;
            handleSendMessage(initialMessage);
          }
          // if (!response.ok) {
          //     throw new Error(`HTTP error! status: ${response.status}`);
          // }
          // if(response.status !== 200) {
          //     throw new Error(`HTTP error! status: ${response.status}`);
          // }

          // const result = await response.json();
          // console.log('User data sent successfully:', result);
          // return result;
          console.log('User data sent successfully:', response);
          return response;
      } catch (error) {
          console.error('Error sending user data:', error);
          throw error;
      }
  }

  const addMessage = (text, sender, type = 'text', displayData = null) => {
    const newMessage = {
      id: Date.now(),
      text: typeof text === 'string' ? text.replace(/\\n/g, '<br/>') : text,
      sender,
      type,
      displayData,
      timestamp: new Date().toISOString(),
    };
    setMessages(prevMessages => [...prevMessages, newMessage]);
  };

  const handleResponse = (data) => {
    if (data.response) {
      try {
        const parsedResponse = JSON.parse(data.response);
        // console.log("Parsed response:", parsedResponse);
        
        if (parsedResponse && typeof parsedResponse === 'object') {
          const { header, result, footer } = parsedResponse;
          
          if (Array.isArray(result)) {
            const formattedResults = result.map(item => {
              if (item.reply && typeof item.reply === 'string') {
                return {
                  display_name: item.display_name.replace(/\\n/g, '<br/>'),
                  reply: item.reply.replace(/\\n/g, '<br/>'),
                  id_type: item.id_type,
                  id_value: item.id_value
                };
              } else {
                return item.display_name?.replace(/\\n/g, '<br/>') || item.toString();
              }
            });

            addMessage(null, 'assistant', 'display_box', {
              header: header?.replace(/\\n/g, '<br/>'),
              result: formattedResults,
              footer: footer?.replace(/\\n/g, '<br/>')
            });
          } else {
            addMessage('Received unexpected response format.', 'assistant');
          }
        } else {
          addMessage(data.response.replace(/\\n/g, '<br/>'), 'assistant');
        }
      } catch (error) {
        addMessage(data.response.replace(/\\n/g, '<br/>'), 'assistant');
      }
    }
  };

  const handleSendMessage = async (message) => {
    if (!message.trim()) return;

    addMessage(message, 'user');
    
    try {
      setLoading(true);
      // const response = await fetch(AI_ASSISTANT_ENDPOINTS.CHAT, {
      //   method: 'POST',
      //   headers: DEFAULT_HEADERS,
      //   body: JSON.stringify({
      //     user_input: message
      //   }),
      // });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }

      // const data = await response.json();
      // handleResponse(data);
      let params = {user_input: message}
      const response = await CommodityAssistant.chat(params);
      // console.log('response axios: ', response)
      if(response.status === 200) {
        handleResponse(response.data);
      }
    } catch (error) {
      console.error('Error:', error);
      addMessage('Error processing your request.', 'assistant');
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = async (buttonData) => {
    const { result } = buttonData;
    const { reply, id_type, id_value } = result;
    
    addMessage(reply.replace(/\\n/g, '<br/>'), 'user');

    try {
      setLoading(true);
      // const response = await fetch(AI_ASSISTANT_ENDPOINTS.CHAT, {
      //   method: 'POST',
      //   headers: DEFAULT_HEADERS,
      //   body: JSON.stringify({
      //     // user_input: `${reply}, ${id_type}:${id_value}`
      //     user_input: `${reply}, ${id_type}Id :${id_value}`
      //   }),
      // });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }

      // const data = await response.json();
      // handleResponse(data);
      let params = {user_input: `${reply}, ${id_type}Id :${id_value}`}
      const response = await CommodityAssistant.chat(params);
      // console.log('response axios: ', response)
      if(response.status === 200) {
        handleResponse(response.data);
      }
    } catch (error) {
      console.error('Error:', error);
      addMessage('Error processing your request.', 'assistant');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper 
      elevation={3} 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth: '800px',
        marginBottom: 0,
      }}
    >
      <Box sx={{ 
        flexGrow: 1, 
        overflow: 'auto', 
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}>
        <MessageList messages={messages} onButtonClick={handleButtonClick} />
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <CircularProgress />
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>
      <Box sx={{ p: 2, backgroundColor: 'background.default', flexShrink: 0 }}>
        <MessageInput onSendMessage={handleSendMessage} disabled={loading} />
      </Box>
    </Paper>
  );
};

export default ChatContainer;
