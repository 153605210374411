import React from 'react'
import { Box, Button, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { ellipsisText } from '../../../helpers';
import { useSelector } from 'react-redux';

import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';


function CommunicationBox(props) {
    const user = useSelector(state => state.userReducer.user);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const { communication, setChatData } = props;
    const lastMessage = communication?.lastMessage;
    const [bannerColor, setBannerColor] = React.useState('');

    // const getBackgroundColor = () => {
    //     if(lastMessage) {
    //         if(lastMessage.isSystem) {
    //             return "#e0e0e0";
    //         } else {
    //             if(lastMessage.action === 2) {
    //                 return "#ff000033";
    //             } else if(lastMessage.action === 1) {
    //                 return "#4e9f3d4c";
    //             } else {
    //                 return "#3d9f944b"
    //             }
    //         }
    //     }
    // }

    const getBuyerSeller = () => {
        if(communication?.buyerUserId === user?._id) {
            return "buyer";
        } else if(communication?.sellerUserId === user?._id) {
            return "seller";
        }
    }

    const setData = () => {
        setChatData({
            show: true,
            listing_id: communication.listingId,
            chat_company_id: communication._id,
            product: communication.listing,
            price: communication.listing.price,
            days: communication?.listing.liftingDays,
            payment_term_id: communication?.listing?.paymentTerm?._id,
            cc_percentage: '',
            productCompanyId: communication.listing.companyId,
            product_type: communication.listing.listingType,
            // currency: listing?.listing?.currency,
            minimumOrderQuantity: communication?.contract?.minQuantityListingSize,
            quantityTickSize: communication?.contract?.quantityTickSize,
            contract: communication?.contract,
            // quantityRangeMax: '',
            // quantityRangeMin: '',
            priceTickSize: communication?.contract?.priceTickSize,
            contractId: communication?.contract?._id,
            commNo: communication?.commNumber,
            listing: communication?.listing,
            originListingId: communication?.originListingId
            // listingNo: communication?.listing?.listingNumber
        })
    }

  return (
    <Box  sx={{
        // backgroundColor: getBackgroundColor(),
        // backgroundColor: '#ff000033',
        padding: 2,
        // borderRadius: 1,
        // borderBottom: 1,
        // borderTop: 0,
        // borderWidth: 1,
        // borderColor: '#ddd',
        mb: 0.5,
        mt: 0,
        boxShadow: 1,
        pt: 0,
        pb: 1,
        px: 2,
        ":hover": {cursor: 'pointer'},
      }}>
        {/* <Button onClick={(e)=>{e.stopPropagation();console.log(lastMessage)}}>lastMessage</Button>
        <Button onClick={()=>console.log(user._id)}>user</Button>
        <Button onClick={()=>console.log( getBuyerSeller())}>buysell</Button> */}
        <Box 
        onClick={setData}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {
                !lastMessage.isSystem ? (
                    getBuyerSeller() === "buyer"? (
                        <Box sx={{ borderBottom: '1px solid green', borderRadius: 2, px: 1, mt: 0, pt: 0.8, mr: 0 }}>
                        {
                            // !lastMessage.isSystem && 
                            (lastMessage.senderUserId === user._id ?
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Typography variant="button" sx={{ fontSize: '12px', fontWeight: 'bold', color: '#32CD32' }}>
                                    BID
                                </Typography>
                                <CallMadeIcon color="success" sx={{ fontSize:"14px", mt: 0.3 }} /> 
                            </Box> : 
                            lastMessage.receiverUserId === user._id ?
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Typography variant="button" sx={{ fontSize: '12px', fontWeight: 'bold', color: '#EF0107' }}>
                                    OFFER
                                </Typography>
                                <CallReceivedIcon color="error" sx={{ fontSize:"14px", mt: 0.3 }} />
                            </Box> : <></>)
                        }
                        </Box>
                    ) : (
                        <Box sx={{ borderBottom: '1px solid red', borderRadius: 2, px: 1, mt: 0, pt: 0.8, mr: 0 }}>
                            {
                                // !lastMessage.isSystem && 
                                (lastMessage.senderUserId === user._id ?
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Typography variant="button" sx={{ fontSize: '12px', fontWeight: 'bold', color: '#EF0107' }}>
                                        OFFER
                                    </Typography>
                                    <CallMadeIcon color="success" sx={{ fontSize:"14px", mt: 0.3 }} /> 
                                </Box> : 
                                lastMessage.receiverUserId === user._id?
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Typography variant="button" sx={{ fontSize: '12px', fontWeight: 'bold', color: '#32CD32' }}>
                                        BID
                                    </Typography>
                                    <CallReceivedIcon color="error" sx={{ fontSize:"14px", mt: 0.3 }} />
                                </Box> : <></>)
                            }
                        </Box>
                    )
                ) : (
                <Box sx={{ borderBottom: '2px solid gray', borderRadius: 2, px: 1, mt: 0, pt: 0.8, mr: 0 }}>
                    <Typography variant="button" sx={{ fontSize: '12px', fontWeight: 'bold', color: '#4f4f4f' }}>
                        SYS
                    </Typography>
                </Box>
                )
            }
            <Tooltip title={communication.lastMessage.message}>
                <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1 }}>
                    {matches ? `${ellipsisText(communication.lastMessage.message, 60)}` : `${ellipsisText(communication.lastMessage.message, 32)}`}
                </Typography>
            </Tooltip>
            <KeyboardArrowRight sx={{ top: 2 }} />
        </Box>
    </Box>
  )
}

export default CommunicationBox