import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
// import { useSelector } from 'react-redux'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import OfferAndBidMessage from './OfferAndBidMessage'
import { green } from "@mui/material/colors";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import clsx from "clsx";
// import AutomationQuoteDialog from '../../send-quotation-single-page/AutomationQuoteDialog'
// import AutoAwardDialog from '../../send-quotation-single-page/AutoAwardDialog'
// import PopUpDialog from './PopUpDialog';
import { Product } from '../../../api/resources/Product';
import { CommodityUser } from '../../../api/resources/CommodityUser';
import { ConsoleCommodity } from '../../../api/resources/admin-api/ConsoleCommodity';

const useStyles = makeStyles(theme => ({
    inputCheckBox: {
        // display: 'inline-block',
        paddingTop: theme.spacing(.7),
        paddingBottom: theme.spacing(.7),
        textAlign: 'left',
        marginRight: theme.spacing(0),
        marginBottom: theme.spacing(1),
        '&.MuiFormControlLabel-root': {
            marginLeft: theme.spacing(0),
        },
        '& .MuiButtonBase-root': {
            padding: 5
        }
    },
    inputBG: {
        background: '#eef2f5',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700]
        }
    },
    buttonLoaderWrapper: {
        margin: theme.spacing(1),
        position: "relative"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
}));

export default function AcceptRejectCounterOptionsAndMessage({  
    contractData, 
    bid, 
    offer, 
    action, 
    price, 
    paymentTermId, 
    ccPercentage, 
    liftingDays, 
    quantity, 
    product_type, 
    product, 
    productCompanyId, 
    hideAcceptMessage, offerBidLoader, 
    bidOfferCounterSuccess, onSubmit, sendingMessageSet, sendingMessage, minimumOrderQuantity, 
    quantityTickSize, quantityRangeMax, quantityRangeMin, priceTickSize, currency, 
    creditDays, lastMessage, userReferenceList, userReferenceListSet, specialRequest, 
    paymentDays, brandStore,rejectreasons,deliverytermsArray,contract,expiryValue,
    counterSettlementFields,setCounterSettlementFields, settlementUnits,
    source,
    contractClusterIds
}) {
    // console.log({counterSettlementFields,setCounterSettlementFields, settlementUnits})
    // const acceptMessage = "Hi, I am happy to accept your "+(bid ? "Bid. I am sending you a formal quote." : "")+(offer ? "Offer. Kindly send me a formal quote." : "")
    const acceptMessage = "Congratulations! We are happy to accept your " + (bid ? "bid. We are sending you a formal quote now." : "") + (offer ? "offer. Kindly, send a formal quote." : "")

    // const reasons = [
    //     rejectreasons
    // ]
    const [selectedReasons, selectedReasonsSet] = React.useState([]);

    const [reasonList, setReasonList] = React.useState([]);

    const classes = useStyles();

    const offerBidButtonClassname = clsx({
        [classes.buttonSuccess]: bidOfferCounterSuccess
    });


    const bidOfferRejectReasonList = (type) => {
        if(window.location.pathname.includes("console/admin")){
ConsoleCommodity.dealConsoleRejectReason(type).then(response => {          
    if (response.status == 200) {
        setReasonList(response.data.data.rejectReasons)
    }
});
        }else{
            CommodityUser
            .dealRejectReason(type)
            .then(response => {
               
                if (response.status == 200) {
                    setReasonList(response.data.data.rejectReasons)
                }
               
            });
        }
    
    };


    const makeRejectMessage = () => {
        var tempReasons = reasonList.filter(r => selectedReasons.includes(r._id))
        var reasonsString = ''
        if (tempReasons.length > 1) {
            for (var i = 0; i < tempReasons.length; i++) {
                reasonsString += tempReasons[i].label

                if (i == tempReasons.length - 2) {
                    reasonsString += " and "
                }
                else if (i != tempReasons.length - 1) {
                    reasonsString += ", "
                }
            }
        }
        else {
            reasonsString = tempReasons[0] ? tempReasons[0].label : ""
        }

        return "Hi, We want to reject this " + (bid ? "Bid" : "") + (offer ? "Offer" : "") + " due to " + reasonsString
    }

    const handleSelectedReasonChange = (rid) => {
        const tempVals = selectedReasons?.includes(rid) ? selectedReasons?.filter(val => val !== rid) : [...(selectedReasons ?? []), rid];
        selectedReasonsSet(tempVals);
        return tempVals;
    }

    // const submitAction = (data) => {
    //     onSubmit({ message: data.message, action: action, data: data })
    // }

    React.useEffect(() => {
        bidOfferRejectReasonList(1);
        console.log('hit')
    }, [action == 5])


    return <>
    {/* {console.log(action)} */}
    {/* <Button onClick={()=>console.log(action, source)}>accProps</Button> */}
        {action == 4 &&
            <>
                {/* {(!(hideAcceptMessage != undefined)) && <Box mb={1}>{acceptMessage}</Box>} */}
                <Box textAlign='center'>
                    <span className={classes.buttonLoaderWrapper}>
                        <Button
                            type="button" variant="contained" size='small' color="primary"
                            className={offerBidButtonClassname}
                            onClick={e => {
                                // console.log({e})
                                // if (globalSetting?.settings_enable_auto_quote == 1 && bid && (lastMessage?.message_log?.data?.payment_id == 1 || lastMessage?.message_log?.data?.payment_id == 4)) {
                                //     showAutoQuoteDialogSet(true);
                                // } else if (globalSetting?.settings_enable_auto_award == 1 && offer && lastMessage?.message_log?.data?.payment_id == 3) {
                                //     showAutoAwardDialogSet(true);
                                // } else {
                                ReactGA.event({ category: 'User', action: 'User accepted ' + (bid ? "Bid" : "") + (offer ? "Offer" : "") });
                                onSubmit({ message: acceptMessage,  })
                                // }
                            }}
                            disabled={sendingMessage}
                        >
                            {sendingMessage && <><CircularProgress size={24} /> &nbsp; </>}Submit
                        </Button>
                    </span></Box>
            </>
        }
        {action == 5 &&
            <>
                <Box mb={1}>What is the reason to reject this {bid && "Bid"}{offer && "Offer"}?</Box>
                <FormControl component="fieldset" className='w-100'>
                    {/* {reasonList && reasonList.length > 0 && reasonList.map((reason, index) => (
                        <FormControlLabel
                            name="selectedReasons"
                            key={index}
                            control={<Checkbox key={index}
                                color='primary'
                                name="selectedReasons"
                                value={reason._id}
                                checked={selectedReasons.includes(reason._id)}
                                onChange={e => { handleSelectedReasonChange(reason._id) }} />
                            }
                            label={reason.title}
                            className={`${classes.inputCheckBox} ${classes.inputBG}`}
                        />
                    ))} */}
                    {/* reasons for buyer */}
                    {reasonList && bid && reasonList.length > 0 && reasonList.filter((reason, index) => reason.userType == 1).map((reason, index) => {
                            if (reason.title.toLowerCase() === 'other') {
                                
                            }
                        return (
                            <FormControlLabel
                            name="selectedReasons"
                            key={index}
                            control={<Checkbox key={index}
                                color='primary'
                                name="selectedReasons"
                                value={reason._id}
                                checked={selectedReasons.includes(reason._id)}
                                onChange={e => { handleSelectedReasonChange(reason._id) }} />
                            }
                            label={reason.title}
                            className={`${classes.inputCheckBox} ${classes.inputBG}`}
                        />
                        )
                    } )}
                    {/* reasons for seller */}
                    {reasonList && offer && reasonList.length > 0 && reasonList.filter((reason, index) => reason.userType == 2).map((reason, index) => {
                            if (reason.title.toLowerCase() === 'other') {
                                console.log(reason)
                            }
                        return (
                            <FormControlLabel
                            name="selectedReasons"
                            key={index}
                            control={<Checkbox key={index}
                                color='primary'
                                name="selectedReasons"
                                value={reason._id}
                                checked={selectedReasons.includes(reason._id)}
                                onChange={e => { handleSelectedReasonChange(reason._id) }} />
                            }
                            label={reason.title}
                            className={`${classes.inputCheckBox} ${classes.inputBG}`}
                        />
                        )
                    } )}
                </FormControl>
                <Box mb={1.5}>
                    <span className={classes.buttonLoaderWrapper}>
                        <Button
                            type="button" variant="contained"
                            fullWidth size='small' color="primary"
                            className={offerBidButtonClassname}
                            disabled={sendingMessage}
                            onClick={e => {
                                ReactGA.event({ category: 'User', action: 'User rejected ' + (bid ? "Bid" : "") + (offer ? "Offer" : "") });
                                onSubmit({ reasons: selectedReasons, message: makeRejectMessage() })
                            }
                            }
                        >
                            {sendingMessage && <><CircularProgress size={24} /> &nbsp; </>}Submit
                        </Button>
                    </span>
                </Box>
            </>
        }
        {(action == 1 || action == 2) &&
            <OfferAndBidMessage
            contractClusterIds={lastMessage?.contractClusterIds}
            source={'action 1 or 2 from acccept reject counter'}
            settlementUnits={settlementUnits}
            counterSettlementFields={counterSettlementFields}
            setCounterSettlementFields={setCounterSettlementFields}
            contractData={contractData}
                sendingMessageSet={e => sendingMessageSet(e)} sendingMessage={sendingMessage} userReferenceListSet={e => userReferenceListSet(e)} userReferenceList={userReferenceList} paymentDays={paymentDays} brandStore={brandStore}
                reBid={!!(action == 1)} reOffer={!!(action == 2)} specialRequest={specialRequest}
                action={action} productCompanyId={productCompanyId} product={product} price={price} paymentTermId={paymentTermId} ccPercentage={ccPercentage} liftingDays={liftingDays} product_type={product_type} quantity={quantity} currency={currency} minimumOrderQuantity={minimumOrderQuantity} quantityTickSize={quantityTickSize} quantityRangeMax={quantityRangeMax} quantityRangeMin={quantityRangeMin} priceTickSize={priceTickSize} creditDays={creditDays}
                deliverytermsArray={deliverytermsArray}
                contract={contract}
                expiryValue={expiryValue}
                onSubmit={e => {
                    // console.log({eInIcounter:e})
                    // if (globalSetting?.settings_enable_auto_quote == 1 && product_type == 1 && !!(action == 1) && (paymentTermId == 1 || paymentTermId == 4)) {
                    //     showPopUpDialogSet(true);
                    //     dataSet(e)
                    // } else if (globalSetting?.settings_enable_auto_award == 1 && product_type == 2 && !!(action == 2) && paymentTermId == 3) {
                    //     showPopUpDialogSet(true);
                    //     dataSet(e)
                    // }
                    // else {
                    onSubmit({ message: e.message, action: action, data: e.data });
                    // }
                }}
            />
        }
       
    </>
}
