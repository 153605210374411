import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { InputLabel, Box, Checkbox, FormControl, FormLabel, FormControlLabel } from "@mui/material";
import { SelectValidator } from "react-material-ui-form-validator";
import LanguageTranslate from "../../partials/UnderScoreLanguage";

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginTop: theme.spacing(4),
  },
}));

/**
 *
 * @name IsHot
 * @alias IsHot
 * @component
 * @exports IsHot
 *
 * @description
 * IsHot input field
 *
 */

export default function IsHot(props) {
  const classes = useStyles();

  const handleChange = (event) => {
    props.isHotSet(event.target.checked);
  };

  return (
    <FormControl component="fieldset" size="small" style={{ fontSize: '1rem', color: '#0009' }}>
      <Box component="span" mr={1}><LanguageTranslate s='Make Your Listing Hot?' /> {' '}</Box>
      <FormControlLabel control={<Checkbox
        checked={props.isHot}
        disabled={props.disabledStatus}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />}
        label={<>Hot <img src="../../images/hot-listing.gif" alt="hot" width="20" />
        </>}
      />
    </FormControl>
  );
}
