import React, { useEffect, useState } from 'react';

// material ui dependencies
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Tooltip, Typography } from '@mui/material'
import { makeStyles, styled } from '@mui/styles';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';

// internal dependencies
import CommunicationBox from './CommunicationBox';
import ErrorHandler from '../../partials/ErrorHandler';
import { ellipsisText } from '../../../helpers';
import CardLoader from '../../partials/content-loaders/CardListLoader';
import LanguageTranslate from '../../partials/UnderScoreLanguage';
import ThemeIconButton from '../../partials/ThemeIconButton';
import { Link } from 'react-router-dom';
import ThemeButton from '../../partials/ThemeButton';
import { green } from '@mui/material/colors';

const useStyles = makeStyles(theme => {
  return ({
    card2: {
      marginBottom: 0,
      border: '1px solid #ddd',
      borderRadius: 4,
      padding: theme.spacing(1.5),
      background: theme.palette.common.white,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(.75),
      },
      // '&:focus-within': {
      //   borderTop: '2px solid #3f51b5',
      //   borderLeft: '2px solid #3f51b5',
      //   borderBottom: '2px solid #3f51b5',
      //   borderRight: '0 solid transparent',
      // }
    },
    card: {
      width: '100%',
      boxShadow: theme.shadows[0],
      // '&:hover': {
      //   boxShadow: theme.shadows[4],
      // },
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      // marginBottom: 0,
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
          backgroundColor: green[700]
      }
  },
  });
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CornerBadge = styled(Box)(({ theme, status }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: status === 1 ? '#15dab9' : status === 2 ? '#efdb01' : status === 3 ? '#686868' : '',
  color: 'white',
  padding: '10px 20px',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  borderRadius: '0 0 8px 0',
  zIndex: 1,
  boxShadow: '2px 2px 4px rgba(0,0,0,0.1)',
}));

const BadgeMainText = styled(Typography)({
  fontSize: '0.75rem',
  fontWeight: 'bold',
  lineHeight: '0.5',
});

const ListingCard = React.memo((props) => {
  const classes = useStyles();
  const { 
    // expanded, 
    // onAccordionChange,
    index, 
    listing, 
    selectedListing, 
    handleListingClick, 
    communications, 
    chatData, 
    setChatData,
    matches,
    loadingComms,
    handleDeleteListing,
    loadingExpiry,
    expireSuccess,
    setExpireSuccess
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // setExpanded(!matches && listing._id === selectedListing._id);
    if(index === 0) {
      setExpanded(true);
    }
  }, []);
  const listingDetails = () => {
    return (
      // <Box sx={{ border:"2px solid red", mt: 0, mb: matches && 1 }}>
      <Card className={classes.card} 
      sx={{
        // backgroundColor: (matches && listing._id === selectedListing._id) ? '#ababab' : '#fff',
        borderLeft: (matches && listing._id === selectedListing._id) ? '4px solid #095bcd' : 'none',
        // border:"1px solid red",
        backgroundColor: '#fff',
        mb: matches && 1,
        position: 'relative',
        filter: listing.status === 3 && 'grayscale(100%)'
      }}
      onClick={()=>{matches && handleListingClick(listing)}}>
        {/* <Button onClick={()=>console.log(listing.status)}>listing</Button> */}
        {
          listing.status !== 0 && 
            <CornerBadge status={listing.status}>
              <BadgeMainText>
                {
                  listing.status === 1 ? "PENDING CONFIRMATION" : 
                  listing.status === 2 ? "AWARDED" : 
                  listing.status === 3 ? 'EXPIRED' : 
                  ''
                }
              </BadgeMainText>
            </CornerBadge>
        }
          <CardContent sx={{ pt: 0, px: 1, pb: !matches && '0 !important', mt: listing.status === 0 ? -0.7 : 0.8 }}>
            <Box sx={{
                display: 'flex', 
                justifyContent: 'space-between',
                // border:"2px solid red",
                // borderTop:"1px solid #7d7d7d", 
                // borderBottom:"1px dashed #7d7d7d", 
                // borderLen: '70%',
                alignItems: 'center', 
                // mt: 1,
                p: 0, mb: 0, py: 0, pt: 1.4,
                mt: listing.status !== 0 ? 2 : -1
              }}>
              <Box sx={{  }}>
                <Typography color="text.secondary" variant="subtitle1" sx={{ }}>
                  #{listing.listingNumber}
                </Typography>
              </Box>
              <Box sx={{  }}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ 
                    // mt: 1,
                    backgroundColor: listing.listingType === 1 ? '#32cd32' : '#ef0107',
                    '&:hover': {
                      backgroundColor: listing.listingType === 1 ? '#4dc64d' : '#e7373d',
                    },
                  }}
                >
                  {listing.listingType === 1 ? "BUY" : "SELL"}
                </Button>
                {
                  listing.status === 0 &&
                  <IconButton
                  sx={{ mt: 0}}
                  onClick={(e) => { 
                    e.stopPropagation(); 
                    setOpen("expireModal"); 
                  }}>
                    <DeleteIcon />
                  </IconButton>
                }
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1, mb: 0 }}>
            
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, 
                // width: '50%', 
                mt: 0 }}>
                {/* <Inventory sx={{ color: 'text.secondary' }} /> */}
                <Tooltip title={listing.contract?.name} placement="top" arrow>
                  <Typography variant="h6" component="div" sx={{ mb: 0, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {
                      !matches && (
                        !expanded ? 
                        `${ellipsisText(listing.contract?.name, 20)}` :
                        `${listing.contract?.name}`
                      )
                    }
                    { 
                      // (matches && listing._id === selectedListing._id) ? 
                      matches && listing.contract?.name 
                      // : 
                      // ellipsisText(listing.contract?.name, 30)
                    }
                  </Typography>
                </Tooltip>
              </Box>
              {/* {
                matches &&
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '80%' }}>
                  <Tooltip title={`${listing.contract?.commodity?.commodityType?.name}-${listing.contract?.commodity?.name}`} placement="top" arrow>
                    <Typography color="text.secondary">
                      {
                        (!expanded && !matches) ? 
                        `${ellipsisText(listing.contract?.commodity?.name, 10)} - ${ellipsisText(listing.contract?.commodity?.commodityType?.name, 10)}` :
                        `${listing.contract?.commodity?.name} - ${listing.contract?.commodity?.commodityType?.name}`
                      }
                      {
                        matches && 
                        `${ellipsisText(listing.contract?.commodity?.name, 30)} - ${ellipsisText(listing.contract?.commodity?.commodityType?.name, 30)}`
                      }
                    </Typography>
                  </Tooltip>
                </Box>
              } */}
              <Typography variant="h6" component="div">
                  {/* {ellipsisText(`${listing.currency} ${listing.price} / ${listing.quantityUnit?.name}`, 15)} */}
                  {listing.currency} {listing.price} / {listing.quantityUnit?.name}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0 }}>
              {
              // !matches ? (
                  <Tooltip title={`${listing.contract?.commodity?.commodityType?.name}-${listing.contract?.commodity?.name}`} placement="top" arrow>
                    <Typography color="text.secondary" sx={{whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                      {
                      `${listing.contract?.commodity?.name} - ${listing.contract?.commodity?.commodityType?.name}`
                      }
                    </Typography>
                  </Tooltip> 
                  // ) : (
                  // <Typography color="text.secondary">
                  //   {listing.quantity} {listing.quantityUnit?.name}
                  // </Typography>
                // )
            }
            {
              // !matches && 
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1, mb: 0 }}>
                <Typography color="text.secondary">
                  {listing.quantity} {listing.quantityUnit?.name}
                </Typography>
              </Box>
            }
              {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography color="text.secondary" variant="body2">
                  #{listing.listingNumber}
                </Typography>
              </Box> */}
            </Box>
            <Box sx={{ mb: 0 }}>
              {
                listing.settlementFields && listing.settlementFields.length > 0 && (
                  <Typography variant="body2" color='text.secondary' sx={{  }}>
                    {listing.settlementFields.map((settlementField, index) => (
                      `${settlementField?.name} : ${settlementField?.value} ${settlementField?.units[0]?.name}`
                    )).join(', ')}
                  </Typography>
                )
              }
            </Box>
            {
              !matches && 
              <Box sx={{display: 'flex', justifyContent: 'center', mt: 0 }}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
            }
          </CardContent>
      </Card>
      // </Box>
    )
  }
  return (
    <>
      {/* <Button onDoubleClickCapture={()=>console.log(listing)}>listing</Button>
      <Button onDoubleClickCapture={()=>console.log(selectedListing)}>selctlisting</Button>
      <Button onDoubleClickCapture={()=>console.log(matches)}>matches</Button> */}
     {
        !matches && 
        <Accordion expanded={expanded} sx={{ mt: 0, mb: 2, pt: 0 }}>
          <AccordionSummary sx={{ py: 0, px: 0, mb: 0 }} onClick={(e) => {handleListingClick(listing); setExpanded(!expanded)}}>
            {listingDetails()}
          </AccordionSummary> 
          <AccordionDetails sx={{ 
              // borderBottom: '1px solid #ddd',
              mt: 0,
              mb: 0,
              // border:"2px solid blue",
              p: 0
            }}>
            <Box sx={{ p: 0 }}>
              {
                loadingComms && !matches && <CardLoader size={4} />
              }
              {
                !loadingComms && !matches && communications.length > 0 ? 
                    (
                      communications.map((communication, index) => (
                        <ErrorHandler key={communication._id}>
                          <CommunicationBox
                            communication={communication}
                            listing={listing}
                            chatData={chatData}
                            setChatData={setChatData}
                          />
                        </ErrorHandler>
                      ))
                    ) : (
                  <Typography variant="body2" sx={{
                    backgroundColor: "#f07c7c",
                    padding: 2,
                    borderRadius: 1,
                    borderBottom: 1,
                    borderWidth: 1,
                    borderColor: '#ddd',
                    mb: 0.5,
                    mt: 0.5,
                    boxShadow: 1,
                    py: 1,
                    px: 2,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}>No communications found</Typography>
                )
              }
            </Box>
          </AccordionDetails>
        </Accordion>
     }
      {
        matches && listingDetails()
      }
      {/* <Button onClick={()=>console.log(listing)}>listing</Button>
      <Button onClick={()=>console.log(communications)}>comms</Button> */}
      {/* Listing expiry modal */}
        <Dialog
          open={open === "expireModal"}
          TransitionComponent={Transition}
          keepMounted
          onClose={e => {
              setOpen('');
          }}
      >
          {expireSuccess && (
              <Box style={{ maxWidth: "290px" }}>
                  <ThemeIconButton
                      aria-label="close"
                      className="closeBtn"
                      onClick={e => {
                          // props.onReload("reload")
                          setOpen('');
                      }}
                      to="/orders"
                      component={Link}
                  >
                      <img src="/images/cross-icon.svg" alt="Cancel" />
                  </ThemeIconButton>
                  <DialogTitle
                      align="center"
                      className={`${"text-white"}`}
                  >
                      <img src="/images/expired-image.svg" alt="Expired" />
                  </DialogTitle>
                  <DialogContent>
                      <Box textAlign="center" mb={1}>
                          <Typography variant="h5">Listing expired</Typography>
                      </Box>
                      <Box mb={2}>
                          <Typography variant="subtitle1">
                              Your listing ID {listing?.listingNumber} has been expired!
                          </Typography>
                      </Box>
                      <Box mb={2}>
                          <ThemeButton
                              onClick={e => {
                                  // props.onReload("reload")
                                  setOpen('');
                                  setExpireSuccess(false);
                              }}
                              to="/orders"
                              component={Link}
                              disableElevation
                              color="primary"
                              variant="contained"
                              size="small"
                              fullWidth
                          >
                              <LanguageTranslate s='Close' />
                          </ThemeButton>
                      </Box>
                  </DialogContent>
              </Box>
          )}
          {!expireSuccess && (
              <Box style={{ maxWidth: "290px" }}>
                  <DialogContent>
                      <Box>
                          <Box mb={2} fontWeight="bold" fontSize={16}>Are you sure you want to expire/remove this listing?</Box>
                          <Box fontSize={14} className="textMuted">Listing
                              ID: {listing?.listingNumber}
                              <br />
                              <LanguageTranslate s='Quantity' />: {listing?.quantity} {listing?.quantityUnit?.name}</Box >
                      </Box>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: "center", padding: "24px", paddingTop: "0px" }}>
                      <Grid container spacing={1}>
                          <Grid item xs={6}>
                              <ThemeButton
                                  onClick={() => setOpen('')}
                                  //variant="contained"
                                  className={classes.buttonSuccess}
                                  color="inherit"
                                  type="text"
                                  style={{ width: "100%" }}
                              >
                                  <LanguageTranslate s='NO THANKS' />
                              </ThemeButton>
                          </Grid>
                          <Grid item xs={6} style={{}}>
                              <ThemeButton
                                  onClick={e => handleDeleteListing(listing)}
                                  color="primary"
                                  //variant="light"
                                  type="text"
                                  // className={classes.buttonSuccess}
                                  disabled={loadingExpiry}
                                  style={{ width: "100%" }}
                              >
                                  {loadingExpiry && (
                                      <CircularProgress
                                          size={24}
                                          className={classes.buttonProgress}
                                      />
                                  )}{" "} &nbsp;
                                  <LanguageTranslate s='YES EXPIRE IT' />
                              </ThemeButton>
                          </Grid>
                      </Grid>
                  </DialogActions>
              </Box>
          )}
      </Dialog>
    </>
  )
});

export default ListingCard;