import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

// material library
import { makeStyles, useTheme } from '@mui/styles'
import { Box, Button, Divider, Grid, IconButton, Paper, Tab, Tabs, TextField, Typography, useMediaQuery } from '@mui/material';

// icons
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from "@mui/icons-material/Search";

// internal dependencies
import { CommodityProduct } from '../../../api/resources/CommodityProduct';
import Loader from '../../partials/Loader';
import OnLoadTransition from '../../partials/OnLoadTransition';
import { config } from '../../../config';
import ErrorHandler from '../../partials/ErrorHandler';
import ListingCard from './ListingCard';
import { sellerColors } from '../../../theme';
import ChatModalV3 from '../../partials/CommodityChatModal';
import CommunicationBox from './CommunicationBox';
import CardLoader from '../../../components/partials/content-loaders/CardListLoader'; 
import { socket } from '../../../socket';
import { CommodityUser } from '../../../api/resources/CommodityUser';
import { debounce } from 'lodash';

const useStyles = makeStyles( theme => ({
    root: {
        width: '100%',
        padding: '0 0 0 16px'
    },
    paperContainer: {
        width: '100%',
        padding: '0 0 0 16px',
        marginBottom: theme.spacing(4),
        margin: 'auto',
        minHeight: '100vh',
        '& .sellerTheme-Tabs .MuiTab-textColorPrimary.Mui-selected': {
            color: sellerColors.primary.main
        },
    },
    paperContainerMob: {
        width: '100%',
        padding: '0 0 0 16px',
        marginBottom: theme.spacing(4),
        minHeight: '100vh',
        '& .sellerTheme-Tabs .MuiTab-textColorPrimary.Mui-selected': {
            color: sellerColors.primary.main
        },
    },
    btnSpacingX: {
        marginRight: theme.spacing(1)
    },
}));

function useQuery() {
    const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ListingsInbox() {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const query = useQuery();
    const debouncedHandler = React.useCallback(debounce((f, v) => { f(v) }, 600), []);
    // const user = useSelector((state) => state.useReducer.user);
    
    const navigate = useNavigate();

    const [listings, setListings] = useState([]);
    const [liveListings, setLiveListings] = useState([]);
    const [selectedListing, setSelectedListing] = useState();
    const [tabValue, setTabValue] = useState('');
    const [communications, setCommunications] = React.useState({});
    const [expandedListingId, setExpandedListingId] = React.useState(null);
    const [chatData, setChatData] = React.useState({});
    const [searchListingNumber, setSearchListingNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingComms, setLoadingComms] = React.useState(false);
    const [loadingExpiry, setLoadingExpiry] = React.useState(false);
    const [expireSuccess, setExpireSuccess] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({
        page: 1,
        rowsPerPage: 10,
        total: 0,
        lastPage: 1
    });
    const [showSearchField, setShowSearchField] = React.useState(false);
    const loadMoreRef = useRef(null);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleTabChange = (newVal) => {
        navigate(`/orders?tab=${newVal}`);
    }
    
    const handleAccordionChange = (listingId) => {
        setExpandedListingId(prev => prev === listingId ? null : listingId);
    }

    const handleListingClick = (listing) => {
        // console.log('received listing', listing)
        setSelectedListing(listing);
        fetchCommunications(listing);
    }

    const handleDeleteListing = (listing) => {
        console.log('expiring listing', listing)
        expireListing(listing);
        // fetchListings();
    }

    const handleCloseChatModal = () => {
        setChatData({});
        fetchCommunications(selectedListing);
    }
    
    const fetchListings = async (props) => {
        console.log(props)
        try {
            setLoading(true);
            let params = {
                // limit: 100
                page: 
                (props.src === 'tabChange' || props.src === 'refresh') ? 1 : 
                paginationData.page
            };
            if(searchListingNumber.length > 1) {
                Object.assign(params, {"search[listingNumber]": searchListingNumber});
            }

            const tab = query.get('tab');
            if(tabValue === 'live' || (tab && tab === 'live')) {
                Object.assign(params, {"search[status]": 0});
            } else if(tabValue === 'assigned' || (tab && tab === 'assigned')) {
                Object.assign(params, {"search[status]": 1});
            } else if(tabValue === 'expired' || (tab && tab === 'expired')) {
                Object.assign(params, {"search[statusne]": 0});
                Object.assign(params, {"search[statusne]": 1});
            }
            console.log({params})
            const res = await CommodityProduct.virtualOffice.myListings(params);
            console.log({resListings: res});
            console.log({props})
            if(res.status === 200) {
                const fetchedListings = res.data.data;
                if(props.src === 'tabChange' || props.src == 'refresh') {
                    setPaginationData({
                        page: 1,
                        rowsPerPage: 10,
                        total: 0,
                        lastPage: 1
                    });
                    console.log('set listings', fetchedListings)
                    setListings(fetchedListings);
                    setPaginationData(prev => ({
                        ...prev,
                        total: res.data.paginationData.total,
                        lastPage: res.data.paginationData.last
                    }));
                } else if(props.src === 'observer') {
                    setListings(prev => {
                        console.log('setting listings prev', prev, {fetchedListings});
                        // return [...prev, ...fetchedListings];
                        const newListings = fetchedListings.filter(listing => !prev.some(l => l._id === listing._id));
                        // console.log('newListings', newListings);
                        // console.log('fetchedListings', fetchedListings);
                        return [...prev, ...newListings];
                    });
                    setPaginationData(prev => ({
                        ...prev,
                        total: res.data.paginationData.total,
                        lastPage: res.data.paginationData.last
                    }));
                }
                setSelectedListing(fetchedListings[0]);
                fetchCommunications(fetchedListings[0]);
            }
        } catch (error) {
            console.error('error while fetching liveListings', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const fetchCommunications = async (listing) => {
        if(!listing) return;
        try {
          setLoadingComms(true);
          let params = {
            "search[listingId]": listing._id,
          }
          const res = await CommodityProduct.virtualOffice.inboxChatList(params);
          console.log({resInbox: res})
          if(res.status === 200) {
            const fetchedComms = res.data.data.communications;
            if(fetchedComms.length > 0) {
            //   setCommunications(fetchedComms);
            //   setCommunications({listingd: fetchedComms})
            setCommunications(prev => {
                    return {
                        ...prev,
                        [listing._id]: fetchedComms
                    }
                });
            }
          }
        } catch (error) {
          console.error('error while fetching chats', error);
          setLoadingComms(false);
        } finally {
          setLoadingComms(false);
        }
      }

    const expireListing = async (listing) => {
        if(!listing) return;
        setLoadingExpiry(true);
        setExpireSuccess(false);
        try {
            const res = await CommodityUser.expireListing({ listingId: listing._id});
            console.log({resExp: res});
            setExpireSuccess(true);
        } catch(error) {
            setLoadingExpiry(false);
            console.error('error while expiring listing', error);
        } finally {
            setLoadingExpiry(false);
        }
    }

    const fetchOneListing = async (listingId) => {
        try {
            let params = { "search[_id]": listingId }
            const res = await CommodityProduct.virtualOffice.myListings(params);
            console.log({resOne: res})
            if(res.status === 200) {
                if(res.data.data.length > 0) {
                    return res.data.data[0].status === 0 && res.data.data[0];
                }
            }
        } catch (error) {
            console.error('error while fetching one listing', error);
        }
    }

    const fetchOneCommunication = async (data) => {
        try {
            let params = { "search[_id]": data.communicationId }
            const res = await CommodityProduct.virtualOffice.inboxChatList(params);
            console.log({resOneComm: res})
            if(res.status === 200) {
                return res.data.data.communications[0];
            }
        } catch (error) {
            console.error('error while fetching one communication', error);
        }
    }

    const refreshCommunication = async (e) => {
        console.log('refreshCommunication event', e)
        const newCommunication = await fetchOneCommunication(e);
        console.log('newCommunication', newCommunication)
        if(newCommunication) {
            // const existingComm = communications[e.listingId] || communications[e.originListingId];
            // const existingListingId = Object.keys(communications).filter((c) => c === e.listingId || c === e.originListingId).map(c => c)
            // const matchedComms = communications[e.originListingId] || communications[e.listingId];
            const existingListingId = e.listingId in communications ? e.listingId : e.originListingId;
            // const existingCom = matchedComms.filter(c => c._id == newCommunication._id).map(c => c);
            // console.log('existing comm', existingCom);
            // console.log('existing listing id', existingListingId)
            // console.log('new comm', newCommunication)
            // console.log('prev comms', communications[existingListingId])
            console.log('existing comms', communications)
            if(newCommunication.firstMessage._id !== newCommunication.lastMessage._id) {
                console.log('existing comm')
                setCommunications(prevCommunications => {
                    const updatedComms = prevCommunications[existingListingId].map(c => {
                        return c._id === newCommunication._id ? newCommunication : c
                    });
                    return { ...prevCommunications, [existingListingId]: updatedComms }
                // return { ...prevCommunications, [existingListingId]: newCommunication }
                });
            } else {
                // fetchOneCommunication(e.listingId);
                fetchCommunications(selectedListing);
                // TODO: update the communications state with the new communication to show the new comm without having to refetch all communications for the listing
                // console.log('initiate com')
                // console.log('refresh event listing id', e)
                // setCommunications(prevCommunications => {
                //     console.log('inside set comms', prevCommunications)
                //     return { ...prevCommunications, [e.listingId]: newCommunication }
                // // return { ...prevCommunications, [existingListingId]: newCommunication }
                // });
            }
        }
    }

    const refreshListings = async (e) => {
        console.log('refreshListings', e);
        // let newUserListing;
        const newUserListing = await fetchOneListing(e.listingId);
        console.log('newUserListing', newUserListing)
        setListings(prevListings => {
            return [ newUserListing, ...prevListings]
        });
        setTimeout(async () => {
            await fetchCommunications(newUserListing);
        }, 500);
        // if(newComm) {
        //     setCommunications(prev => {
        //         return {
        //             ...prev,
        //             [newUserListing._id]: [newComm]
        //         }
        //     })
        // }
    }

    const refreshListingStatusChanged = async (e) => {
        if(tabValue === 'live') {
            setListings(prevListings => {
                return [...prevListings.filter(listing => listing._id !== e.listingId)]
            });
        } else if(tabValue === 'assigned') {
            const newListing = await fetchOneListing(e.listingId);
            setListings(prevListings => {
                return [ newListing, ...prevListings ];
            });
        }  else if(tabValue === 'expired') {
            const newListing = await fetchOneListing(e.listingId);
            setListings(prevListings => {
                return [ newListing, ...prevListings ];
            });
        }
    }

    // use effects
    useEffect(() => {
        const tab = query.get('tab');
        if(tab) {
            setTabValue(tab);
            navigate(`/orders?tab=${tab}`);
        } else if(!tab) {
            query.set('tab', 'live');
            setTabValue('live');
            navigate(`/orders?tab=live`);
        }
    }, []);

    useEffect(() => {
        if(tabValue) {
            fetchListings({src: 'tabChange'});
        }
    }, [tabValue]);

    useEffect(() => {
        if(searchListingNumber.length > 1) {
            debouncedHandler(fetchListings({src: 'search'}));
        } else if(searchListingNumber.length === 0) {
            fetchListings({src: 'refresh'});
        }
    }, [searchListingNumber])

    useEffect(() => {
        if (selectedListing) {
            socket.on("newUserListing", refreshListings);
            socket.on("listingAccepted", refreshListingStatusChanged);
            socket.on("listingExpired", refreshListingStatusChanged);
            socket.on("listingAssigned", refreshListingStatusChanged);
            socket.on("newChatUserCommunicationMessage", refreshCommunication);
            // socket.on('contractBestBuyerSellerUpdate', refreshContract);
        }
        return () => {
            socket.off("newUserListing", refreshListings);
            socket.off("listingAccepted", refreshListingStatusChanged);
            socket.off("listingExpired", refreshListingStatusChanged);
            socket.off("listingAssigned", refreshListingStatusChanged);
            socket.off("newChatUserCommunicationMessage", refreshCommunication);
            // socket.off('contractBestBuyerSellerUpdate', refreshContract);
        };
    }, [selectedListing]);

    useEffect(() => {
        console.log('observer use eff', {loading, page: paginationData.page, totalNo: paginationData.total, totalLists:listings.length})
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !loading && listings.length < paginationData.total) {
                setLoading(true);
                setPaginationData(prev => {
                    return {
                        ...prev,
                        page: prev.page + 1
                    }
                });
                setLoading(false);
            }
        });

        if(loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            if(loadMoreRef.current) {
                observer.unobserve(loadMoreRef.current);
            }
        }
    }, [
        loading, 
        listings.length, 
        paginationData.total
    ]);

    useEffect(() => {
        if(listings.length >= paginationData.total) return;
        fetchListings({src: 'observer'});
    }, [paginationData.page]);

  return (
    <Paper className={matches ? classes.paperContainer : classes.paperContainerMob}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', paddingTop: '15px', display:'flex', justifyContent: 'space-between', alignItems:'center' }}>
            <Box sx={{ display:'flex', justifyContent:'space-between' }}>
                {/* <Typography id="pageHeading" variant="h2" style={{ paddingTop: '5px', fontSize: '24px', fontWeight: 'bold', marginBottom: '10px', marginLeft: '20px' }}>
                    Orders { tabValue === 'live' && `(${paginationData.total})`}
                </Typography> */}
                <Button 
                // variant= {tabValue === 'live' ? "contained" : 'text'}
                sx={tabValue === 'live' && { borderBottom: '2px solid', borderColor: 'primary', borderRadius: 0}}
                onClick={()=> {
                    setTabValue('live');
                    navigate(`/orders?tab=live`);
                }}>
                    Orders { tabValue === 'live' && `(${paginationData.total})`}
                </Button>
            <Divider variant='' orientation="vertical" flexItem sx={{ mx: 2, bgcolor: '#808080', height: 2 }} />
                <Button 
                // variant= {tabValue === 'live' ? "contained" : 'text'}
                    sx={tabValue === 'assigned' && { borderBottom: '2px solid', borderColor: 'primary', borderRadius: 0}}
                    onClick={()=> {
                        setTabValue('assigned');
                        navigate(`/orders?tab=assigned`);
                    }}>
                    Assigned { tabValue === 'assigned' && `(${paginationData.total})`}
                </Button>
            <Divider variant='' orientation="vertical" flexItem sx={{ mx: 2, bgcolor: '#808080', height: 2 }} />
                <Button 
                // variant= {tabValue === 'expired' ? "contained" : 'text'}
                    sx={tabValue === 'expired' && { borderBottom: '2px solid', borderColor: 'primary', borderRadius: 0}}
                    onClick={()=> {
                        setTabValue('expired');
                        navigate(`/orders?tab=expired`);
                    }}>
                    Expired { tabValue === 'expired' && `(${paginationData.total})`}
                </Button>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                {
                    matches && showSearchField && 
                    <TextField
                        // sx={{ width: '100%' }}
                        value={searchListingNumber}
                        onChange={e => {
                            setSearchListingNumber(e.target.value);
                        }}
                        label="Search"
                    />
                }
                <IconButton
                    color="inherit"
                    variant="outlined"
                    disableElevation
                    // className={`${classes.btnSpacingX}`}
                    sx={{  mt: 1.5 }}
                    onClick={(e) => { setShowSearchField(!showSearchField) }}
                    aria-label="filter"
                    size="small">
                    <SearchIcon />
                </IconButton>
            </Box>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
            {/* <Button onClick={()=>console.log(paginationData)}>pagData</Button>
            <Button onClick={()=>console.log(listings)}>listings</Button> */}
            {
                !matches && showSearchField && 
                <TextField
                    sx={{ mb: 2, width: '100%' }}
                    value={searchListingNumber}
                    onChange={e => {
                        setSearchListingNumber(e.target.value);
                    }}
                    label="Search"
                />
            }
        </Box>
        <Grid container spacing={1}>
            <Grid item sm={6} xs={12} 
            sx={{ backgroundColor: '#EEF2F5',
                //  borderTop:"2px solid", borderColor:'#4b393956', 
                 mt: 1,
                // border:"2px solid red"
            }}> 
                {
                loading &&
                    (
                    <Box textAlign='center'>
                        <Loader />
                    </Box>
                )}
                {
                !loading && (
                    <>
                    {
                        <Grid container spacing={2} sx={{ mt: 0, maxHeight: '100vh', overflowY: 'auto' }}>
                            <Grid  item xs={12} sm={12}>
                            {
                                <Box sx={matches ? 
                                    {display: {xs: 'none', md: 'block'}} : 
                                    {display: {xs: 'block', md: 'none'}}
                                }>
                                    {/* <Button onClick={()=>console.log(communications)}>comms</Button>
                                    <Button onClick={()=>console.log(communications[selectedListing._id])}>commselect</Button> */}
                                <OnLoadTransition transition="fade" timeout={config.user_page_transition_timeout}>
                                {
                                    listings.length > 0 && 
                                    listings.map((listing, index) => {
                                        return (
                                        <ErrorHandler key={listing?._id}>
                                            <ListingCard
                                                index={index}
                                                listing={listing}
                                                selectedListing={selectedListing}
                                                setSelectedListing={setSelectedListing}
                                                handleListingClick={handleListingClick}
                                                communications={communications[listing._id] || []}
                                                // expanded={expandedListingId === listing._id}
                                                // onAccordionChange={() => handleAccordionChange(listing._id)}
                                                chatData={chatData}
                                                setChatData={setChatData}
                                                matches={matches}
                                                handleDeleteListing={handleDeleteListing}
                                                loadingComms={loadingComms}
                                                loadingExpiry={loadingExpiry}
                                                expireSuccess={expireSuccess}
                                                setExpireSuccess={setExpireSuccess}
                                            />
                                        </ErrorHandler>   
                                    )} 
                                )}
                                {loading && <CardLoader />}
                                <div 
                                    ref={loadMoreRef} 
                                    style={{ height: '20px', backgroundColor: 'transparent' }}
                                />
                                {listings.length >= paginationData.total && !loading &&(
                                    <Box sx={{ textAlign: 'center' }}>No more listings available</Box>
                                )}
                                </OnLoadTransition>
                                </Box>
                            }
                            </Grid>
                        </Grid>
                    }
                    </>
                )}
            </Grid>
            <Grid item xs={12} sm={6}>
                {
                    loadingComms && matches && <CardLoader size={4} />
                }
                {
                    !loadingComms && matches && 
                    <Box sx={{ xs: 'none', sm: 'block'}}>
                        {
                            selectedListing 
                            && communications[selectedListing._id] 
                            && communications[selectedListing._id]?.length > 0 
                            ? 
                                (
                                    communications[selectedListing._id].map((communication, index) => (
                                    <ErrorHandler key={communication._id}>
                                        <CommunicationBox 
                                        communication={communication} 
                                        listing={selectedListing}
                                        chatData={chatData}
                                        setChatData={setChatData}
                                        />
                                    </ErrorHandler>
                                    ))
                                ) : (
                                <Typography variant="body2" sx={{
                                    backgroundColor: "#f07c7c",
                                    padding: 2,
                                    borderRadius: 1,
                                    borderBottom: 1,
                                    borderWidth: 1,
                                    borderColor: '#ddd',
                                    mb: 0.5,
                                    mt: 0.5,
                                    boxShadow: 1,
                                    py: 1,
                                    px: 2,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}>No communications found</Typography>
                            )}
                    </Box>
                }
                {
                    chatData && chatData.show && (
                        <>
                        {matches ? (
                            <Box sx={{ display: {xs: 'none', sm: 'block'} }}>
                                <ChatModalV3 
                                    key={`${chatData.listing_id}-${chatData.chat_company_id}`}
                                    data={chatData}
                                    onClose={() => handleCloseChatModal()}
                                    matches={matches}
                                />
                            </Box>
                        ) : (
                            <Box sx={{ display: {xs: 'block', sm: 'none'}, top: 40 }}>
                                <ChatModalV3 
                                    key={`${chatData.listing_id}-${chatData.chat_company_id}`}
                                    data={chatData}
                                    onClose={() => handleCloseChatModal()}
                                />
                            </Box>
                        )}
                        </>
                    )
                }
            </Grid>
            {/* <Grid item sm={12} xs={12}>
                <Box sx={{ backgroundColor: '#FFF' }}>
                    <Button onClick={()=>console.log(paginationData)}>pagdata</Button>
                    <ErrorHandler>
                        <MaterialTablePaginator
                            count={paginationData?.total}
                            rowsPerPage={paginationData?.rowsPerPage}
                            page={paginationData?.page}
                            additionalClass={'w-100 bg-white'}
                            onPageChange={(newPage) => {
                                console.log('new page recvd', newPage);
                                setPaginationData(prev => ({
                                    ...prev,
                                    page: newPage
                                }));
                            }}
                            onChangeRowsPerPage={newRowsPerPage => {
                                setPaginationData( prev => ({
                                    ...prev,
                                    rowsPerPage: newRowsPerPage
                                }));
                            }}
                        />
                    </ErrorHandler>
                </Box>
            </Grid> */}
        </Grid>
    </Paper>
  )
}

export default ListingsInbox