import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import makeStyles from '@mui/styles/makeStyles';
import { loader } from '../../redux/actions/commonActions';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dateformat from "dateformat";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import {
  Grid,
  Box,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Slide,
  Typography,
  DialogContent,
  DialogActions,
  CircularProgress,
  Rating,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
  Divider,
  DialogContentText,
  Hidden,
  useMediaQuery,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import MuiLink from '@mui/material/Link';
import Link from '@mui/material/Link';
import { NavLink, Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import Countdown, { zeroPad } from 'react-countdown';
import SpecialRequest from './partials/SpecialRequest';
import LoadingButton from '@mui/lab/LoadingButton';
import { User } from '../../api/resources/User';
import { Product } from '../../api/resources/Product';
import Emitter from '../../services/emitter';
import genOfferAndBidMessage from '../partials/chat/genOfferAndBidMessage';
import HighlightOffIcon from '@mui/icons-material/Close';
import ThemeButton from '../partials/ThemeButton';
import ThemeIconButton from '../partials/ThemeIconButton';
import { green } from '@mui/material/colors';
import { AccountCircle } from '@mui/icons-material';
import { cDecrypt, cEncrypt, mutualCount, timeAgo } from '../../helpers';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ShipIcon from '../partials/icons/ShipIcon';
import { CommodityUser } from '../../api/resources/CommodityUser';
import Flag from '../partials/Flag';
import ImageGalleryBox from '../ImageGalleryBox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CollectionsIcon from '@mui/icons-material/Collections';
import BuySell from './BuySell';
import { mt } from 'date-fns/locale';
import trackingEvents from '../../eventsTracking';
import ImageUploadingInput from '../ImageUploadingInput';
import BottomTabsNav from '../partials/BottomTabsNav';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  blink: { 
    animation: '$blinkBackground 1s ease-in-out 3'
  }, 
  '@keyframes blinkBackground': { 
    '0%': { backgroundColor: 'initial' }, 
    '50%': { backgroundColor: 'darkgray' }, 
    '100%': { backgroundColor: 'initial' }
  },
  paperSpacing: {
    // color: theme.palette.common.white,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5, 0.75),
    },
  },
  fs16: {
    fontSize: '16px',
  },
  textBlue: {
    color: '#0645ad',
  },
  fs14: {
    fontSize: '14px',
    color: '#0645ad',
  },
  price: {
    textDecoration: 'underline',
    fontSize: '13px',
    fontWeight: '600',
    display: 'inline-block',
  },
  buyPrice: {
    color: 'red',
  },
  sellPrice: {
    color: '#4E9F3D',
  },

  gridCol2: {
    display: 'grid',
    gridTemplateColumns: '.8fr 1fr',
    alignItems: 'center',
    gridGap: '10px',
    marginBottom: '10px',
  },
  isPaidUserListing_Seller: {
    //background: "#f9ecf1",
    backgroundImage: `url('/images/hot-listing.gif')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30px',
    backgroundPosition: 'center -6px',
  },
  isPaidUserListing_Seller_BG: {
    background: '#f9ecf1',
  },
  isPaidUserListing_Buyer: {
    //background: "#eefffd",
    backgroundImage: `url('/images/hot-listing.gif')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30px',
    backgroundPosition: 'center -6px',
  },
  isPaidUserListing_Buyer_BG: {
    background: '#eefffd',
  },
  gridCols2: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
     alignItems: 'center',
    gridGap: '10px',
    // marginBottom: "10px",
    // borderBottom: "1px solid #ccc",
  },
  gridVesselsCol3: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',

    gridGap: '10px',
    marginBottom: '10px',
  },
  gridCol3: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'center',
    gridGap: '10px',
    marginBottom: '10px',
    borderBottom: '1px solid #ccc',
    borderTop: '1px solid #ccc',
    padding: '4px 0',
  },
  gridCols3: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'center',
    //gridGap: "5px",
    color: '#6A6A6A',
    fontSize: 12,
    //padding: "4px 0",
  },
  preFilledInputContainer: {
    background: '#F2F3F5',
    padding: theme.spacing(1.8, 1.8, 0),
    borderRadius: '6px',
  },
  textMuted: {
    color: '#6A6A6A',
    fontSize: 13,
  },
  dashedBorder: {
    borderRight: '1px dashed #CCCCCC',
    paddingRight: '7px',
  },
  padding: {
    padding: 0,
  },
  px1: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  buttonPrimaryLight: {
    backgroundColor: '#d4def1',
    color: '#2959BA',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#3f51b557',
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  expireIcon: {
    color: '#bdbdbd',
    padding: 0,
    '&:hover': {
      color: '#424242',
    },
  },
  gridCol2Icon: {
    display: 'grid',
    gridGap: '5px',
    gridTemplateColumns: '10px auto',
    alignItems: 'center',
    fontSize: '12px',
    '& img': {
      width: 12,
    },
  },
  thumbnailBoxImage: {
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: '#ddd',
    '&:hover': {
      borderColor: '#bac5ff',
    },
  },
}));

// const UserPlanIcon = ({ user }) => {
//   return user?.active_revenueable_invoice_consumption == null ? (
//     <span title={'UnPaid User'}>
//       <AccountCircle
//         size="small"
//         color="primary"
//         style={{ fontSize: '1rem' }}
//       />
//     </span>
//   ) : (
//     <img
//       src={user?.active_revenueable_invoice_consumption?.plan?.icon_src}
//       width={20}
//       title={
//         user?.active_revenueable_invoice_consumption?.plan?.title + ' User'
//       }
//       alt={user?.active_revenueable_invoice_consumption?.plan?.title + ' User'}
//     />
//   );
// };
export const ListingThumb = ({ image, ...any }) => {
  const classes = useStyles();

  return (
   <>
   
   <Box >
   <img
        {...any}
        style={{ width: '100%', height: "100%", objectFit: "cover" }}
        src={`${process.env.REACT_APP_API_URL_COMMODITY}/${image.url}`}
        alt={image.filename}
        className={classes.thumbnailBoxImage}
      />
   </Box>
   
   
   </>
  );
};

const ListingViewButton = ({listing, ...any}) => <Button color="primary" component={RouterLink} to={`/listings/${listing.slug}`} {...any}>View</Button>

/**
 *
 * @name MarketPrices
 * @alias MarketPrices
 * @component
 * @exports MarketPrices
 *
 * @description
 * MarketPrices component to show market prices
 */

function MarketPrices(props) {
  // console.log({propsPrices:props})
  const classes = useStyles();
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.userReducer);
  const user_mode = useSelector((state) => state.userReducer.user_mode);
  const user = useSelector((state) => state.userReducer.user);
  const isLoggedIn = useSelector((state) => state.userReducer.isLoggedIn);
  const matchesViewport = useMediaQuery("(max-width:600px)");
  
  const [open, setOpen] = React.useState(false);
  const [closeBuyAccordion,setCloseBuyAccordion] = React.useState(false)
  const [closeSellAccordion,setCloseSellAccordion] = React.useState(false)
  const [openEAuctionOfferDialog, setOpenEAuctionOfferDialog] =
    React.useState(false);
  const [buyerData, setBuyerData] = React.useState(false);
  const [chats, setChats] = useState([]);
  const [fetching, fetchingSet] = React.useState(false);
  const [message, setMessage] = useState('');
  const [noMoreListingStatus, setNoMoreListingStatus] = React.useState(true);
  const [totalBuyerQtySet, setTotalBuyerQtySet] = React.useState(null);
  const [totalSellerQtySet, setTotalSellerQtySet] = React.useState(null);
  const [tabvalue,setTabvalue]=React.useState('')
  // const [userTotalBuyerQty, setUserTotalBuyerQty] = React.useState(null);
  // const [userTotalSellerQty, setUserTotalSellerQty] = React.useState(null);
  const [selectedQuantity, setSelectedQuantity] = React.useState(false);
  const [selectedListingID, setSelectedListingId] = React.useState(false);
  const [selectedListingQuantityUnit, setSelectedListingQuantityUnit] = React.useState();
  const [selectedListing, setSelectedListing] = React.useState('');
  const [expireLoader, setExpireLoader] = React.useState(false);
  const [expireSuccess, setExpireSuccess] = React.useState(false);
  // const [currentUserSystemTime, setCurrentUserSystemTime] = React.useState(null)
  // const [sellerListingTime, setSellerListingTime] = React.useState(null)
  // const [buyerListingTime, setBuyerListingTime] = React.useState(null)
  // const [showBuySellForm, setShowBuySellForm] = React.useState(false)
  // const [userNotAuthorizedBuyError, setUserNotAuthorizedBuyErrorSet] = React.useState('')
  // const [userNotAuthorizedSellError, setUserNotAuthorizedSellErrorSet] = React.useState('')
  // const [expandedOne, setExpandedOne] = React.useState(true);
  // const [expandedTwo, setExpandedTwo] = React.useState(true);
  // Load more listings
  const [visibleItems, visibleItemsSet] = React.useState(50);
  const [openVesselInfoAccordion,setOpenVesselInfoAccordion] = React.useState(false)

  const [userCompanyAddAlertOpen, setUserCompanyAddAlertOpen] = React.useState(false)
  const [selectedListingThumb, selectedListingThumbSet] = React.useState(null);
const[openBuySellFormAccordian,setOpenBuySellFormAccordian]=React.useState(false);

const [loginAlert, setLoginAlert] = React.useState(false);
const [addImageDialogOpen, setAddImageDialogOpen] = React.useState(false);
const [listingImages, setListingImages] = React.useState([]);
const [listingType, setListingType] = React.useState('');
const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const listingRef = React.useRef(null);

const navigate = useNavigate();
  const showMoreItems = () => {
    visibleItemsSet((prevValue) => prevValue + 20);
  };
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const expireButtonClassname = clsx({
    [classes.buttonSuccess]: expireSuccess,
  });

  const handleClickOpen = (modalName) => {
    setOpen(modalName);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const AuctionFinished = () => {
  //   React.useEffect(() => {
  //     // props.setAuctionFinished(true);
  //     setOpen(true);
  //   }, []);

  //   return <>Finished</>;
  // };

  // const RenderCountdown = ({ hours, minutes, seconds, completed }) => {
  //   const Span = ({ children }) => {
  //     return (
  //       <span
  //         // style={{backgroundColor: "red", color: "#FFF"}}
  //         className="countdown-span"
  //       >
  //         {children}
  //       </span>
  //     );
  //   };
  //   if (completed) {
  //     // Render a completed state
  //     return <AuctionFinished />;
  //   } else {
  //     // Render a countdown
  //     return (
  //       <Box className="countdown">
  //         <Span>{zeroPad(hours)}</Span>:<Span>{zeroPad(minutes)}</Span>:
  //         <Span>{zeroPad(seconds)}</Span>
  //       </Box>
  //     );
  //   }
  // };

  const expireAction = () => {
    console.log({expireInActuion:expireSuccess})
    setExpireLoader(true);
    // setExpireSuccess(false);
    CommodityUser.expireListing({ listingId: selectedListingID })
      .then((response) => {
        setExpireSuccess(true);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setExpireLoader(false);
      });
  };

  // Get total Qty. of buyer/seller data
  React.useEffect(() => {
    var buyerSum = 0;
    var sellerSum = 0;
    if (props.vesselPriceBuyer !== null && props.vesselPriceBuyer.length > 0) {
      props.vesselPriceBuyer.forEach((p) => (buyerSum += p.quantity));
    }
    setTotalBuyerQtySet(buyerSum);

    if (
      props.vesselPriceSeller !== null &&
      props.vesselPriceSeller.length > 0
    ) {
      props.vesselPriceSeller.forEach((p) => (sellerSum += p.quantity));
    }
    setTotalSellerQtySet(sellerSum);
  }, [props.vesselPriceBuyer, props.vesselPriceSeller]);

  // const prepareAndSubmitMessage = (data) => {
  //   const msgProps = {
  //     product_type: data?.chat_data?.product_type,
  //     currency: data?.price_currency,
  //   };
  //   let msgData = {
  //     price: data?.price_value,
  //     lifting: data?.days,
  //     paymentMode: data?.payment_term_text,
  //     paymentModeId: data?.payment_term_id,
  //     quantity: data?.chat_data?.quantity,
  //     validityTime: 30,
  //     deliveryTerm:
  //       data.chat_data?.origin_of_coal == 1 &&
  //       data.chat_data?.coal_type?.id == 5
  //         ? 'FOB (free on board)'
  //         : data.chat_data?.origin_of_coal == 1 &&
  //           data.chat_data?.coal_type?.id != 5
  //         ? 'Ex-mine/depo'
  //         : data.chat_data?.origin_of_coal == 2 && 'Ex-port',
  //     comment: data?.comments,
  //     advance: data?.cc_percentage,
  //     balance: 100 - parseInt(data?.cc_percentage),
  //     creditDays: data?.num_credit_days,
  //     paymentDays: '',
  //   };

  //   const message = genOfferAndBidMessage(msgProps, msgData);

  //   setMessage(message);
  // };

  // const sendMessageHttpReq = (data) => {
  //   //sendingMessageSet(true)
  //   fetchingSet(true);
  //   var postData = {
  //     company_id: data.chat_data?.chat_company_id,
  //     listing_id: data.chat_data?.listing_id,
  //     session_company_id: user.company_id,
  //     message: message,
  //     action: 2,
  //     reason_ids: [],
  //     price: data.price_value,
  //     quantity: data?.chat_data?.quantity,
  //     lifting_days: data?.days,
  //     payment_id: 6,
  //     payment_text: 'Payment through CoalShastra',
  //     delivery_term_id: 2,
  //     advance: '',
  //     balance: '',
  //     no_credit_days: '',
  //     validity_time: 30,
  //     comment: '',
  //     is_top_five: 0,
  //     initiated_from: 0,
  //     source: 2,
  //   };
  //   User.chats
  //     .sendMessage(postData)
  //     .then((response) => {
  //       if (response.data.status === 'success') {
  //         fetchingSet(false);
  //         setOpenEAuctionOfferDialog(false);
  //         Emitter.emit('SNACKBAR_NOTIFICATION', {
  //           message: response.data.message,
  //           params: { variant: 'success' },
  //         });
  //         Emitter.emit('CHAT_MESSAGE_SENT', postData);
  //       }
  //       if (response.data.status === 'fail') {
  //         fetchingSet(false);
  //       }
  //     })
  //     .finally(() => {
        
  //     });
  //   }
  // const ClusterFieldsNames = ({ contract }) => {
  //   return contract?.publishedClusters?.map(cluster => cluster?.fields.map(name => name.isMandatory == 1 ? '(' + name.name + ":-" + name.value + ')' : '').join(' '));
  // }
React.useEffect(()=>{
  // if(props.selectedContract?.publishedClusters.length===0){
  if(props.selectedContract?.publishedClusters){
    setOpenVesselInfoAccordion(false)
  }
  else{
    setOpenVesselInfoAccordion(true)
  }

},[props.selectedContract?.publishedClusters])

const handleBuyClick=()=>{
 
  setTabvalue("buyer")
  setCloseBuyAccordion(true)
   setOpenBuySellFormAccordian(true)
}
const handleSellClick=()=>{
  setTabvalue('seller');
  setCloseSellAccordion(true)
  setOpenBuySellFormAccordian(true)
}
const updateListingImages = async () => {
  // console.log('called update function', {listingImages})
  const formdata = new FormData();
  listingImages.map(i => {
    if(i.url){
      let exist = {};
      Object.assign(exist, {filename: i.filename, url: i.url})
        return formdata.append('existingImages', JSON.stringify(exist))
      } else {
        return formdata.append('images', i.file)
      }
     });
    //  console.log('user: ', user)
    //  console.log('isLoggedIn: ', isLoggedIn)
     
     try {
      const res = await CommodityUser.updateListing(formdata, selectedListingID);
      console.log(' updated listing', res);
    if(res.status === 200) {
      setListingImages([]);
      setAddImageDialogOpen(false);
      const newListing = res.data.data.listing;
      if(newListing?.listingType === 1) {
        props.getbestbuyer(newListing?.contract?._id);
      } else {
        props.getbestSeller(newListing?.contract?._id);
      }
    }
  } catch (error) {
    console.log('error while updating listing', error);
    console.log('error response::- ', error.response)
  }
}

  return (
    <Paper elevation={2} className={classes.paperSpacing} >
      {
        !matchesViewport && 
        <Box
          key={props.key}
          className={matchesViewport ? `${classes.px1}` : ''}
          mb={0.5}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            pt: 1,
            mb: 0.5,
            borderBottom: '1',
            borderColor: 'divider',
          }}
        >
          {props.selectedContract && <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box fontWeight="bold" className={classes.fs16}>
              {props?.selectedContract?.name}
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <p style={{ color: "#2A59B7" }}>
                  <b>{props?.selectedContract?.commodity?.commodityType?.name}-{props?.selectedContract?.commodity?.name}</b>
                </p>           
            </Box>
          </Box>}

        </Box>
      }
     
      <Box style={{ marginTop: 0, marginBottom: 5}}>
        <Typography variant='subtitle2'>
         {/* {props.selectedContract?.publishedClusters[0]?.fields?.filter((item) => item?.isMandatory === 1)
            .map((item) => `${item?.name}: ${item?.value}${' '}${item?.unit?.name || ''}`)
            .join(', ')} */}
            {
              // props.selectedContract?.publishedClusters[0]?.length>0?props.selectedContract?.publishedClusters[0]?.fields?.filter((item) => item?.isMandatory === 1)
              // .map((item) => `${item?.name}: ${item?.value}${' '}${item?.unit?.name || ''}`)
              // .join(', '):<></>}
            props.selectedContract?.publishedClusters?.length > 0 ? 
            Array.from(new Set(
              props.selectedContract?.publishedClusters
              .flatMap(cluster => cluster?.fields?.filter((item) => item?.isMandatory === 1)
              .map((item) => `${item?.name}: ${item?.value}${' '}${item?.unit?.name || ''}`))
            ))
            .join(', '):<></>}
        </Typography> 
      </Box>

      <Grid container spacing={1} >
   
          <Grid item xs={6} sm={6} style={{textAlign:"center"}} >
            {/* admin side */}
            {window.location.pathname.includes('/console/admin')
            ?
            <>
<Button style={{width:"100%",color:"white",padding:3,marginTop:1,backgroundColor:"#32CD32",marginBottom:3}} onClick={
  ()=>{
    {props.authenticateUser?handleBuyClick():props.setUserNotSeletectedBuyerError('Kindly select your user to post Buy Listing.')}
  }
  }>
    CLICK HERE TO BUY
  </Button>
  <Box style={{marginTop:5}}>
    
  <strong style={{fontSize:"15px"}}>{props.userNotSeletectedBuyerError}</strong>
  </Box>
</>
: 
// user side
<Button style={{width:"100%",color:"white",padding:3,marginTop:1,backgroundColor:"#32CD32",marginBottom:3}} onClick={() => {
  // console.log(user)
        {
         if(!isLoggedIn) {
          setLoginAlert(true);
         } else {
            if (user.companies.length > 0 ) {
                setTabvalue("buyer");
                setCloseBuyAccordion(true);
                setOpenBuySellFormAccordian(true);
              } else  {
              setTabvalue("");
              setCloseBuyAccordion(false);
              setOpenBuySellFormAccordian(false);
              console.log('no company')
                // alert("Please add your company first");
                setUserCompanyAddAlertOpen(true)
            }
          }
          trackingEvents.buyListingButton({product: props.selectedContract.name});
        }
        
    }}>
      CLICK HERE TO BUY
    </Button>}
                    <Dialog
              open={loginAlert}
              TransitionComponent={Transition}
              keepMounted
              maxWidth="sm"
              onClose={() => setLoginAlert(false)}
            >
                <DialogContent sx={{ p: 2, backgroundColor: "#dfd6d6", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Box mb={1} style={{ position: "relative", padding: '4px 8px' }}>
                  <Typography variant="body2" textAlign="left">
                    Hi
                    <br />
                    Glad you like our platform. To explore further, please
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    style={{
                      background: '#4CAF50',
                      color: '#fff',
                      marginRight: "10px"
                    }}
                    onClick={() => navigate(`/sign-up/${cEncrypt(0)}`)}
                    variant="contained"
                    size="small"
                  >
                    Signup
                  </Button>
                  <NavLink to="/login" style={{ marginLeft: "10px", color: 'blue', textDecoration: 'underline' }}>
                    Login
                  </NavLink>
                </Box>
              </DialogContent>
            </Dialog>

            <Dialog
              open={userCompanyAddAlertOpen}
              TransitionComponent={Transition}
              keepMounted
              maxWidth="sm"
              onClose={(e) => {
                setUserCompanyAddAlertOpen(false);
              }}
            >
        <Box>
          <DialogTitle>
            <Box
              sx={{
                fontSize: '14px',
                color: '#2a2a2a',
                mb: 0.75,
                fontWeight: 'normal',
              }}
            >
              <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                Dear {user.first_name},
              </span>
              <br /> Please add your company before making a listing. Click on the 'Add Company' button above to add your company.
            </Box>

            <Box sx={{ textAlign: 'center', mb: 1.5 }}>
              <Button
                onClick={(e) => {
                  setUserCompanyAddAlertOpen(false);
                }}
                disableElevation
                size="small"
                variant="contained"
                color="primary"
              >
                Okay
              </Button>
            </Box>
          </DialogTitle>
        </Box>
            </Dialog>
           {/* <Button onClick={()=>console.log(user)}>userrrrrrrrrr</Button> */}
          
            </Grid>
            <Grid item xs={6} sm={6}  style={{textAlign:"center"}} >
            {window.location.pathname.includes('/console/admin') ? (
  <>
    <Button
      style={{
        backgroundColor: "#EF0107",
        width: "100%",
        color: "white",
        padding: 3,
        marginTop: 1,
        marginBottom: 3,
      }}
      onClick={() => {
        props.authenticateUser? handleSellClick(): props.setUserNotSeletectedSellerError("Kindly select your user to post Sell Listing.");
      }
    }
    >
      CLICK HERE TO SELL
    </Button>
    <Box style={{ marginTop: 5 }}>
      <strong style={{fontSize:"15px"}}>{props.userNotSeletectedSellerError}</strong>
    </Box>
  </>
) : (
  <Button
    style={{
      backgroundColor: "#EF0107",
        width: "100%",
        color: "white",
        padding: 3,
        marginTop: 1,
        marginBottom: 3,
    }}
    onClick={() => {
      {
        if(!isLoggedIn) {
          setLoginAlert(true);
        } else {
          if (user.companies.length > 0 ) {
            // console.log('is company')
            setTabvalue("seller");
            setCloseSellAccordion(true)
            setOpenBuySellFormAccordian(true);
            // setTabvalue("buyer");
            // setCloseBuyAccordion(true);
            // setOpenBuySellFormAccordian(true);
          } else  {
              setTabvalue("seller");
              setCloseSellAccordion(false)
              setOpenBuySellFormAccordian(false);
    
            // console.log('no company')
              // alert("Please add your company first");
              setUserCompanyAddAlertOpen(true)
          }
        }
        trackingEvents.sellListingButton({product: props.selectedContract.name});
      }
      
    }}
  >
    CLICK HERE TO SELL
  </Button>
  
)}

            </Grid>
            </Grid>
      
      {openBuySellFormAccordian && (closeBuyAccordion||closeSellAccordion) &&
<>
<Accordion style={{marginBottom:5,marginTop:3}} expanded={true}  
 onChange={()=>setOpenBuySellFormAccordian(!openBuySellFormAccordian)}
>

      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
   
        >
          <Box  className={classes.fs16}>
           {tabvalue=='buyer'?'Please Enter Your Buying Requirement Details':'Please Enter Your Product Details for Selling'}
          </Box>
        </AccordionSummary>
       
       <AccordionDetails>
       <BuySell
        setNewListingCreated={(e) => {
          if(e == 'seller' && props.vesselPriceBuyer.filter(list => list?.user?._id !== user?._id).length > 0) {
            setOpen('newListingCreated');
            setListingType(e)
          } else if(e == 'buyer' && props.vesselPriceSeller.filter(list => list?.user?._id !== user?._id).length > 0) {
            setOpen('newListingCreated');
            setListingType(e)
          }
        }}
        closeSellAccordion={closeSellAccordion}
        closeBuyAccordion={closeBuyAccordion}
        setCloseSellAccordion={setCloseSellAccordion}
        setCloseBuyAccordion={setCloseBuyAccordion}
        matchesViewport={matchesViewport}
        selectedContract={props.selectedContract}
     
        getbestbuyer={props.getbestbuyer}
        getbestSeller={props.getbestSeller}
        // isMarketPage={props.true}
        user_mode={user_mode}
        user={props.user}
        // loadingVesselPrice={props.loadingVesselPrice}
        // marketDetails={props.marketDetails}
        // priceRange={props.priceRange}
        price={props.price}
        expiryValue={props.expiryValue}
        // setexpiryValue={props.setexpiryValue}
        // marketplaceId={props.marketplaceId}
        setPrice={props.setPrice}
        // marketData={props.marketData}
        vessels={props.vessels}
        // vesselType={props.vesselType}
        // marketAttrData={props.marketAttrData}
        // portId={props.portId}
        setTabValue={setTabvalue}
        tabValue={tabvalue}
        // vesselInfo={props.vesselInfo}
        // minimumOrderQuantity={props.minimumOrderQuantity}
        quantityTickSize={props.quantityTickSize}
        // quantityRangeMax={props.quantityRangeMax}
        // quantityRangeMin={props.quantityRangeMin}
        isValidQuantity={props.isValidQuantity}
        setIsValidQuantity={props.setIsValidQuantity}
        // priceTickSize={props.priceTickSize}
        isValidPrice={props.isValidPrice}
        setIsValidPrice={props.setIsValidPrice}
        vesselPriceSeller={props.vesselPriceSeller}
        vesselPriceBuyer={props.vesselPriceBuyer}
        vesselInfoData={props.vesselInfoData}
        setVesselInfoData={props.setVesselInfoData}
        userId={props.userId}
        // onAddListing={() => {
        //   // fetchMarketplaceTabs();
        //   props.setRefreshBestBuyerSeller(true);
        // }}
        />

       </AccordionDetails>
      
      </Accordion>
</>
      }

      
      {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ marginTop: 5, marginBottom: 2, width: '70%'}} />
      </div> */}


      {/* <Box style={{ display: "flex", justifyContent: "center", alignItems: 'center', marginBottom: 5 }}>
  <Accordion sx={{ borderBottom: 'none' }}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{
        '&:before': {
          display: 'none',
        },
        borderBottom: 'none', // Add this line to remove the bottom border
      }}
    >
      <Typography>Quality  Details</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography>
        {props.selectedContract?.publishedClusters?.map((cluster) =>
          cluster?.fields.map(
            (name) =>
              name.isMandatory == 1
                ? "(" + name.name + " - " + name.value + ")"
                : ""
          ).join(" ")
        )}
      </Typography>
    </AccordionDetails>
  </Accordion>
</Box> */}
      <Box sx={matchesViewport && { px: 1.25 }} >
        {/* <Box fontWeight="bold" className={classes.fs16} mb={1}>
          All Listings
        </Box> */}
        <Grid container spacing={1} style={{marginTop:3}} >
          <Grid item xs={6}  sm={6} className={classes.dashedBorder}>
            <Box className={classes.gridCols2} style={{
              //   marginBottom: "10px",
              borderBottom: "1px solid #ccc"
            }}>
              <small className={classes.textMuted}>Required Qty</small>
           
              <Box textAlign="right">
                <small className={classes.textMuted}>
                Buyers
                </small>
              </Box>
            </Box>
            <Box style={{ height: '2px' }} mb={1}>{props.loadingBuyers && <LinearProgress />}</Box>
       
            {props.vesselPriceBuyer &&
              props.vesselPriceBuyer == null &&
              'No Data'}

            {props.vesselPriceBuyer &&
              props.vesselPriceBuyer !== null &&
              props.vesselPriceBuyer.length > 0 &&
              props.vesselPriceBuyer
                .slice(0, visibleItems)
                .map((buyer_data, key) => (
                  <Box
                  className={buyer_data._id == cDecrypt(searchParams.get('listingRefId')) && `${classes.blink}`}
                  style={
                    buyer_data._id == cDecrypt(searchParams.get('listingRefId')) ?
                    {
                      borderLeft: '3px solid #2959BA',
                      paddingLeft: '2px',
                      marginBottom: "15px",
                      borderBottom: '2px solid black',
                      paddingBottom:"13px",
                      paddingTop:"5px",
                      boxShadow:"rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                      padding:5,
                      borderRadius:"7px",
                    } :
                    buyer_data.user._id == user._id ? 
                    {
                      borderLeft: '3px solid #2959BA',
                      paddingLeft: '2px',
                      marginBottom: "15px",
                      borderBottom: '2px solid black',
                      paddingBottom:"13px",
                      paddingTop:"5px",
                      boxShadow:"rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                      padding:5,
                      borderRadius:"7px",
                    //  cursor: 'pointer',
                    } : 
                    {
                      borderLeft: 0,
                      marginBottom: "15px",
                      borderBottom: '2px solid black',
                      paddingBottom:"13px",
                      paddingTop:"5px",
                      boxShadow:"rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                      padding:5,
                      borderRadius:"7px",
                      // cursor: 'pointer'
                    }
                  }
                
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                        marginBottom:1
                        
                      }}
                     
                    >
                      <Grid  container style={{ marginBottom: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center',  }}>
                      <Grid item sm={4} style={{ flex: 1 }}>
                        <small className={classes.textMuted}  style={{color:"#2A59B7"}}>
                          <strong>
                          {/* {props.selectedContract&&props.selectedContract.liveBuyerListingCount}{' '}Views {' '} <br/> */}
                          {buyer_data?.views}{' '}Views {' '} <br/>
                          {/* # {buyer_data?.listingNumber} */}
                          </strong>
                        </small>
                        </Grid>
                        <Grid item sm={8} style={{ textAlign: 'right',}}>
    <strong>{timeAgo(dateformat(buyer_data.createdAt, 'yyyy-mm-dd HH:MM:ss'))}</strong>
  </Grid>
                      </Grid>
                      {/* <Button onClick={()=>{
                        console.log('sel list', selectedListingID);
                        console.log('sel quant', selectedQuantity);
                        console.log('open', open);
                        console.log('expireSuccess', expireSuccess);
                        console.log(buyer_data)
                      }}>states</Button> */}
                      {buyer_data.user._id == user._id && (
                        <Box textAlign="right" style={{cursor:"pointer"}}>
                          <Tooltip title="Expire Listing" aria-label="expire">
                            <IconButton
                              //disabled={true}
                              onClick={(e) => {
                                setSelectedListingId(buyer_data._id);
                                setSelectedListing(buyer_data);
                                // setSelectedQuantity(buyer_data.quantity);
                                // setSelectedListingQuantityUnit(buyer_data?.quantityUnit?.name);
                                handleClickOpen('expireModal');
                              }}
                              size="small"
                              className={classes.expireIcon}
                            >
                              <HighlightOffIcon
                                style={{
                                  fontSize: matchesViewport
                                    ? '12px'
                                    : '18px',
                                }}
                                fontSize="small"
                                className="vAlignBottom"
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>

                    <Box
                      className={
                         classes.gridCols2
                      }
                      key={key}
                      sx={matchesViewport && { alignItems: 'baseline' }}
                      
                    >
                      <Grid container gap={1} >

                          <Box sx={{ display: 'flex' }} >
                            <strong>
                              {buyer_data.quantity}{' '}
                              {buyer_data?.quantityUnit?.name} 
                            </strong>
                            {/* <SpecialRequest
                              specialRequestData={buyer_data.comments}
                              view_count={buyer_data?.views}
                            /> */}
                            {/* <ListingViewButton listing={buyer_data} size='small' variant='outlined' /> */}

                            {/* buyer_data.chat_data.chat_company_id != user.active_company */}
                          </Box>
                          <small className={classes.textMuted}>
                            {buyer_data?.liftingDays} Lifting Days
                          </small>
                      
                        
                      </Grid>

                      <Box sx={{ textAlign: 'right', color: 'green', }}>
                        <Box
                          sx={{
                            ...(matchesViewport && {
                              display: 'flex',
                              fontSize: '13px',
                            }),
                            // border:"2px solid red"
                          }}
                          className={`${classes.sellPrice} ${classes.price}`}
                        >
                          {buyer_data.user._id == user._id ? (
                            <Box className={classes.price} >
                              {buyer_data.price ? buyer_data.price : '0'} {buyer_data?.currency}/{buyer_data?.quantityUnit?.name} 
                            </Box>
                          ) : (
                            <Box
                            >
                              <Box className={classes.price} >
                              {buyer_data.price ? buyer_data.price : '0'} {buyer_data?.currency}/{buyer_data?.quantityUnit?.name} 
                              </Box >
                              {/* <IconButton
                                size="small"
                                className={classes.padding}
                              >
                                
                              </IconButton> */}
                            </Box>
                          )}
                        </Box>{' '}
                        
                        {/* {props.marketData?.is_auction != true && <br />} */}
                        <small className={classes.textMuted}>
                          {buyer_data.paymentTerm?.name+ ' Payment'}
                        </small>
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{display:"flex", justifyContent:"center"}}>
                        <Divider style={{ marginTop: 5, marginBottom: 5, width: '50%' }} />
                      </Box>
                          {/* <Button onClick={()=>console.log(seller_data)}>listiing</Button> */}
                          {/* {
                            buyer_data?.contractClusters && 
                            buyer_data?.contractClusters.length > 0 && 
                            buyer_data?.contractClusters.map((cluster, index) => (
                                <Typography variant="subtitle2">
                                  {cluster?.clusterName?.plural} : {`${cluster?.name}`}
                                </Typography>
                              ))
                          } */}
                          <Typography variant="subtitle2">
                              {buyer_data.contractClusters[0].clusterName.plural}: &nbsp;
                              {
                                buyer_data?.contractClusters && 
                                buyer_data?.contractClusters.length > 0 && 
                                buyer_data?.contractClusters.map((cluster, index) => (
                                    <>
                                      {`${cluster?.name}${index !== buyer_data?.contractClusters.length - 1 ? ', ' : ''}`}
                                    </>
                                  ))
                              }
                            </Typography>
                          <Box sx={{display:"flex", justifyContent:"center"}}>
                            <Divider style={{ marginTop: 5, marginBottom: 5, width: '50%' }} />
                          </Box>
                    </Box>
                    <Box>
                        {/* <Button onClick={()=>console.log(seller_data)}>loi</Button> */}
                        <NavLink to={`/listings/${buyer_data.slug}`}>view details</NavLink>
                      </Box>

                    <Box mb={.55} mt={.55} >
                  {/* {buyer_data?.settlementFields && Array.isArray(buyer_data?.settlementFields) && buyer_data?.settlementFields?.length> 0 && buyer_data?.settlementFields?.map((item,index,array)=>(item.value ? <> */}
                  {buyer_data?.settlementFields && Array.isArray(buyer_data?.settlementFields) && buyer_data?.settlementFields?.length> 0 && buyer_data?.settlementFields?.filter(sf => sf?.value).map((item,index,array)=>(item.value ? <>
                    <small key={item?._id} className={classes.textMuted}>
                    {item?.fieldKey}: {item?.value}
        {item?.units && item?.units[0] && ` ${item?.units[0]?.name}`}&nbsp;
        {index !== array.length - 1 && ', '}
                  </small>
                  </> : <></>))}
                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Divider style={{ marginTop: 5, marginBottom: 5, width: '50%' }} />
      </div>
                    {/* <Box style={{alignItems:"center"}}>
  {  buyer_data.address&&buyer_data.address.stateName&&   <Box><small className={classes.textMuted}>Delivery:{' '}{buyer_data.address.stateName}</small></Box>}
  {  buyer_data.deliveryTerm&&    <Box><small className={classes.textMuted}>Delivery Terms:{' '}Deliver to my Location</small></Box>}
      </Box> */}
      {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Divider style={{ marginTop: 5, marginBottom: 5, width: '50%' }} />
      </div> */}
                    {/* grid for images and special comment for buyer listing */}
                     <Grid container sx={{mt:1}} style={{ display:"flex", flexDirection:"column" }}>
                    <Grid item sm={4}>
                    {buyer_data.images &&
                              buyer_data.images.length > 0 && (
                               
                                  <ListingThumb
                                    onClick={() =>
                                      selectedListingThumbSet(buyer_data)
                                    }
                                    image={buyer_data.images[0]}
                                  />
                               
                              )}
                    </Grid>
                    {buyer_data.comments? <Grid item sm={8}  >
                    <Box textAlign="left" >
                    <Typography variant="caption" style={{display:"block",}} className={classes.textMuted}>Special Comments:</Typography>
                    <Typography variant="caption" style={{overflowWrap: "break-word"}} className={classes.textMuted}>{buyer_data.comments}</Typography>
                    </Box>
                   
                   
                    </Grid>:<></>}
                   
                    </Grid>
                    {
                      buyer_data.user._id === userReducer.user._id && (
                        <>
                          <Grid sm={12}>
                            <Button onClick={()=>{
                              setAddImageDialogOpen(true);
                              setSelectedListingId(buyer_data._id);
                              setListingImages(buyer_data.images.length > 0 && buyer_data.images);
                              // console.log('buyer data', buyer_data)
                              }}>+ Add images</Button>
                            {/* <Button onClick={()=>console.log(buyer_data)}>images</Button> */}
                          </Grid>
                        </>
                      )
                    }
                   {/* <Grid sm={12}>
                     { buyer_data.user._id != user._id &&(
                      <Button size="small" style={{backgroundColor:"#32CD32",width:"100%",color:"white",padding:3,marginTop:5}}  onClick={(e) => {
                            let commid =''
                            if(window.location.pathname.includes('/console/admin')){
                              commid =
                              props.buyerCommunications.length > 0 &&
                              props.buyerCommunications.find((id) =>
                                id.listingId == buyer_data._id ? id._id : ''
                              );
                              props.authenticateUser?
                              props.setChatData({
                                show: true,
                                listing_id: buyer_data._id,
                                chat_company_id: commid ? commid._id : '',
                                product: '',
                                badges_data: '',
                                badges_label: 'Buyer Info',
                                price: buyer_data.price,
                                payment_term_id: '',
                                cc_percentage: '',
                                days: buyer_data?.liftingDays,
                                product_type: buyer_data?.listingType,
                                productCompanyId: '',
                                currency: '',
                                minimumOrderQuantity: '',
                                quantityTickSize: '',
                                quantityRangeMax: '',
                                quantityRangeMin: '',
                                priceTickSize: '',
                                creditDays: '',
                                expiryValue: props?.expiryValue,
                                contractId: buyer_data?.contractId,
                                listingSlug: buyer_data?.slug,
                                listing: buyer_data
                              })
                              :
                              props.setUserNotSeletectedBuyerError('Kindly select your user to open chat.')
                                props.setSettlementFields(buyer_data.settlementFields)
                                // props.eventsTracking.contractDetailChat();
                                props.onCloseClick();
                                
                            }else{
                            commid =
                              props.buyerCommunications.length > 0 &&
                              props.buyerCommunications.find((id) =>
                                id.listingId == buyer_data._id ? id._id : ''
                            );

                            if (user.companies.length == 0 ){
                              console.log('called true')
                              setUserCompanyAddAlertOpen(true)
                            } else {
                            props.setChatData({
                              show: true,
                              listing_id: buyer_data._id,
                              chat_company_id: commid ? commid._id : '',
                              product: '',
                              badges_data: '',
                              badges_label: 'Buyer Info',
                              price: buyer_data.price,
                              payment_term_id: '',
                              cc_percentage: '',
                              days: buyer_data?.liftingDays,
                              product_type: buyer_data?.listingType,
                              productCompanyId: '',
                              currency: '',
                              minimumOrderQuantity: '',
                              quantityTickSize: '',
                              quantityRangeMax: '',
                              quantityRangeMin: '',
                              priceTickSize: '',
                              creditDays: '',
                              expiryValue: props?.expiryValue,
                              contractId: buyer_data?.contractId,
                              listingSlug: buyer_data?.slug,
                              listing: buyer_data
                            });


                              props.setSettlementFields(buyer_data.settlementFields)
                              // props.eventsTracking.contractDetailChat();
                              props.onCloseClick();
                              props.openChatMobileSet(true)
                            }
                            if(!isLoggedIn) {
                              setLoginAlert(true)
                            }
                            if (isLoggedIn) {
                              trackingEvents.negotiateWithBuyerClick({listingNumber: buyer_data.listingNumber, product: buyer_data.contract.name, listingMadeBy: buyer_data.user.activeCompany.name})
                            } else {
                              trackingEvents.negotiateWithBuyerClick()
                            }
                          }
                          
}}>Negotiate with Buyer</Button>
                    )} 
                       <Button onClick={()=>console.log(user.companies, userCompanyAddAlertOpen, user)}>clckcl</Button> 
                    </Grid>*/}
                  </Box>
                ))}
        
          </Grid>

          <Grid item xs={6} sm={6} >
            <Box className={classes.gridCols2} style={{
              //   marginBottom: "10px",
              borderBottom: "1px solid #ccc"
            }}>
              <small className={classes.textMuted}>
                Sellers
              </small>

              <Box textAlign="right">
                <small className={classes.textMuted}>Offer Qty</small>
              </Box>
            </Box>
            <Box style={{ height: '2px' }} mb={1}>
              {props.loadingSellers && <LinearProgress />}
            </Box>
            {props.vesselPriceSeller &&
              props.vesselPriceSeller == null &&
              'No Data'}

            {
            // props.firstDataId !=
              // props.marketData?.vessel_attribute_oldest?.id &&
            userReducer.noCompany
              ? ''
              : props.vesselPriceSeller &&
                props.vesselPriceSeller !== null &&
                props.vesselPriceSeller.length > 0 &&
                props.vesselPriceSeller
                  .slice(0, visibleItems)
                  .map((seller_data, key) => (
                    <Box
                    // ref={listingRef}
                    className={seller_data._id == cDecrypt(searchParams.get('listingRefId')) && `${classes.blink}`}
                      style={
                        seller_data._id == cDecrypt(searchParams.get('listingRefId')) ?
                        {
                          borderLeft: '3px solid #2959BA',
                          paddingLeft: '2px',
                          marginBottom: "15px",
                          borderBottom: '2px solid black',
                          paddingBottom:"13px",
                          paddingTop:"5px",
                          boxShadow:"rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                          padding:5,
                          borderRadius:"7px",
                        } :
                        seller_data.user?._id == user._id ? 
                        {
                          borderLeft: '3px solid #2959BA',
                          paddingLeft: '2px',
                          marginBottom: "15px",
                          borderBottom: '2px solid black',
                          paddingBottom:"13px",
                          paddingTop:"5px",
                          boxShadow:"rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                          padding:5,
                          borderRadius:"7px",
                          // cursor: 'pointer',
                        } : 
                        {
                          borderLeft: 0,
                          marginBottom: "15px",
                          borderBottom: '2px solid black',
                          paddingBottom:"13px",
                          paddingTop:"5px",
                          boxShadow:"rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                          padding:5,
                          borderRadius:"7px",
                          // cursor: 'pointer'
                        }
                      }
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                                marginBottom:1
                        }}
                      >
                       
                       <Grid container style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginBottom: 5 }}>
                          <Grid item sm={8} style={{ flex: 1 }}>
                            <strong>{timeAgo(dateformat(seller_data.createdAt, 'yyyy-mm-dd HH:MM:ss'))}</strong>
                          </Grid>
                          <Grid item sm={4} style={{ textAlign: 'right' }}>
                            <small className={classes.textMuted} style={{ color: '#2A59B7' }}>
                          
                              {/* <strong>{ props.selectedContract?.liveSellerListingCount} Views</strong> */}
                              <strong>{ seller_data?.views} Views</strong>
                            </small>
                          </Grid>
                        </Grid>
                        {seller_data.user._id == user._id && (
                          <Box textAlign="right"  style={{cursor:"pointer"}}>
                            <Tooltip title="Expire Listing" aria-label="expire">
                              <IconButton
                                //disabled={true}
                                onClick={(e) => {
                                  setSelectedListingId(seller_data._id);
                                  // setSelectedQuantity(seller_data.quantity);
                                  // setSelectedListingQuantityUnit(seller_data?.quantityUnit?.name);
                                  setSelectedListing(seller_data);
                                  handleClickOpen('expireModal');
                                }}
                                size="small"
                                className={classes.expireIcon}
                              >
                                <HighlightOffIcon
                                  style={{
                                    fontSize: matchesViewport
                                      ? '12px'
                                      : '18px',
                                  }}
                                  fontSize="small"
                                  className="vAlignBottom"
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    <Box
                      className={
                           classes.gridCols2
                      }
                      key={key}
                      sx={matchesViewport && { alignItems: "baseline" }}
                      style={{paddingTop:2}}
                    >
                      <Box style={{ textAlign: 'left', color: "red" }} >
                        <Box
                          sx={
                            matchesViewport && {
                              display: "flex",
                              fontSize: "13px",
                            }
                          }
                          className={`${classes.buyPrice} ${classes.price}`}
                        >
                          {
                            seller_data.user._id == user._id ? (
                              <Box className={classes.price}>
                                {seller_data.price} {seller_data?.currency}/{seller_data?.quantityUnit?.name}
                              </Box>
                            ) : (
                              <Box>
                                <Box className={classes.price}>
                                {seller_data.price} {seller_data?.currency}/{seller_data?.quantityUnit?.name}
                                </Box>  
                              </Box>
                            )}
                          </Box>
                          {/* {props.marketData?.is_auction != true && <br />} */}
                          <br />
                          <small className={classes.textMuted}>
                            {seller_data.paymentTerm?.name + ' Payment'}
                          </small>
                        </Box>
                        <Box style={{display:'flex',flexDirection:'column',marginTop:3}}>
                            <Box style={{textAlign:'right'}} >
                              <strong>
                                  {seller_data.quantity}{' '}
                                  {seller_data?.quantityUnit?.name}
                                </strong>

                            </Box>
                            <Box style={{textAlign:'right'}}>
                                {/* seller_data.chat_data.chat_company_id != user.active_company */}
                                {/* <ListingViewButton listing={seller_data} size='small' variant='outlined' /> */}

                                <small className={classes.textMuted}>
                                  {seller_data?.liftingDays} Lifting Days{' '}
                                </small>
                              </Box>

                        </Box>
                    </Box>
                    <Box>
                      <Box sx={{display:"flex", justifyContent:"center"}}>
                        <Divider style={{ marginTop: 5, marginBottom: 5, width: '50%' }} />
                      </Box>
                          {/* <Button onClick={()=>console.log(seller_data)}>listiing</Button> */}
                          {/* <Box> */}
                            <Typography variant="subtitle2">
                              {seller_data.contractClusters[0].clusterName.plural}: &nbsp;
                              {
                                seller_data?.contractClusters && 
                                seller_data?.contractClusters.length > 0 && 
                                seller_data?.contractClusters.map((cluster, index) => (
                                    <>
                                      {`${cluster?.name}${index !== seller_data?.contractClusters.length - 1 ? ', ' : ''}`}
                                    </>
                                  ))
                              }
                            </Typography>
                          {/* </Box> */}
                      <Box sx={{display:"flex", justifyContent:"center"}}>
                        <Divider style={{ marginTop: 5, marginBottom: 5, width: '50%' }} />
                      </Box>
                    </Box>
                      <Box>
                        {/* <Button onClick={()=>console.log(seller_data)}>loi</Button> */}
                        <NavLink to={`/listings/${seller_data.slug}`}>view details</NavLink>
                      </Box>
                      <Box mb={0.55} mt={0.55}>
                   
                   {seller_data?.settlementFields &&
                      Array.isArray(seller_data?.settlementFields) &&
                      seller_data?.settlementFields.length > 0 &&
                      seller_data?.settlementFields.every((el) => el !== null) &&
                      seller_data.settlementFields.map((item, index, array) => ( item.value ?
                        <>
                          <small key={item._id} className={classes.textMuted}>
                            {item.fieldKey&&item.fieldKey}: {item.value&&item.value}
                          {item.units && item.units[0] && ` ${item.units[0].name}`}&nbsp;
                          {index !== array.length - 1 && ', '}
                          </small>
                        </> : <></>
                      ))}
                   {/* <br /> */}

                   </Box>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Divider style={{ marginTop: 5, marginBottom: 5, width: '50%' }} />
      </div>

      {/* do it in case of repost as well */}
      <Box style={{alignItems:"center"}}>
   { seller_data.address&& seller_data.address.stateName&& <Box><small className={classes.textMuted}>Delivery:{' '}{seller_data.address.stateName}</small></Box>}
{/* {   seller_data.deliveryTerm.name&&     <Box><small className={classes.textMuted}>Delivery Terms:{' '} From my Nursery</small></Box>} */}
  {seller_data.deliveryTerm.name &&
    <Box><small className={classes.textMuted}>Delivery Terms:{' '} {seller_data.deliveryTerm.name}</small></Box>
  }
      </Box>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Divider style={{ marginTop: 5, marginBottom: 5, width: '50%' }} />
      </div> 
                 {/* grid for images and special comment for seller listing */}
                    <Grid container sx={{mt:1}}>
                    <Grid item sm={4}>
                    {seller_data.images &&
                              seller_data.images.length > 0 && (
                               
                                  <ListingThumb
                                    onClick={() =>
                                      selectedListingThumbSet(seller_data)
                                    }
                                    image={seller_data.images[0]}
                                  />
                               
                              )}
                    </Grid>
                    {seller_data.comments?<Grid item sm={8}  >
                    <Box textAlign="center">
                    <Typography variant="caption" style={{display:"block"}} className={classes.textMuted}>Special Comments:</Typography>
                    <Typography variant="caption" style={{overflowWrap: "break-word"}} className={classes.textMuted}>{seller_data.comments}</Typography>
                    </Box>
                   
                    {/* <SpecialRequest
                                  specialRequestData={
                                    seller_data.comments
                                  }
                                  view_count={seller_data?.views}
                                /> */}
                    </Grid>:<></>}
                    
                    </Grid>
                  {
                    seller_data.user._id === userReducer?.user._id && (
                    <>
                        <Grid sm={12}>
                        <Button onClick={()=>{
                          setAddImageDialogOpen(true);
                          setSelectedListingId(seller_data._id);
                          setListingImages(seller_data.images.length > 0 && seller_data.images);
                          console.log('seller data', seller_data)
                          }}>+ Add images</Button>
                        {/* <Button onClick={()=>console.log(listingImages)}>images</Button> */}
                        {/* <Button onClick={()=>console.log(seller_data)}>listing</Button> */}
                      </Grid>
                    </>
                    )
                  }
                    
                     <Grid sm={12}  >
                     {/* {  seller_data.user._id != user._id &&(
  <Button size="small" style={{backgroundColor:"#EF0107",width:"100%",color:"white",padding:3,marginTop:1,}}  onClick={(e) => {
    let commid =''
    if(window.location.pathname.includes('/console/admin')){
                console.log('admin called', props.authenticateUser)

                commid =
                props.sellerCommunications.length > 0 &&
                props.sellerCommunications.find((id) =>
                id.listingId == seller_data._id
                ? id._id
                : ''
                );
                props.authenticateUser ? (
                props.setChatData({
                  show: true,
                  listing_id: seller_data._id,
                  chat_company_id: commid ? commid._id : '',
                  product: '',
                  badges_data: '',
                  badges_label: 'Seller Info',
                  price: seller_data.price,
                  payment_term_id: '',
                  cc_percentage: '',
                  days: seller_data?.liftingDays,
                  product_type: seller_data?.listingType,
                  productCompanyId: '',
                  currency: seller_data?.currency,
                  minimumOrderQuantity:
                  props?.selectedContract
                  ?.minQuantityListingSize,
                  quantityTickSize:
                  props?.selectedContract?.quantityTickSize,
                  quantityRangeMax: '',
                  quantityRangeMin: '',
                  priceTickSize:
                  props?.selectedContract?.priceTickSize,
                  creditDays: '',
                  quanityt: seller_data?.quantity,
                  expiryValue: props?.expiryValue,
                  contractId: seller_data?.contractId,
                  comments:seller_data?.comments,
                  deliveryTerm:seller_data?.deliveryTerm,
                  listingSlug: seller_data?.slug,
                  listing: seller_data
                  }) 
                  )
                  : (
                    props.setUserNotSeletectedSellerError('Kindly select your user to open chat.')
                  )
                
                  
                  // props.eventsTracking.contractDetailChat();
                  props.setSettlementFields(seller_data.settlementFields)
                  props.onCloseClick();
              }else{
              console.log('kelse case')
                commid= props.sellerCommunications.length > 0 &&
                props.sellerCommunications.find((id) =>
                id.listingId == seller_data._id
                ? id._id
                : ''
                );
            
              
                if(!isLoggedIn) {
                  setLoginAlert(true)
                  return;
                }
              if (user.companies.length == 0 ){
                console.log('called true')
                setUserCompanyAddAlertOpen(true)
              } else {
                props.setChatData({
                show: true,
                listing_id: seller_data._id,
                chat_company_id: commid ? commid._id : '',
                product: '',
                badges_data: '',
                badges_label: 'Seller Info',
                price: seller_data.price,
                payment_term_id: '',
                cc_percentage: '',
                days: seller_data?.liftingDays,
                product_type: seller_data?.listingType,
                productCompanyId: '',
                currency: seller_data?.currency,
                minimumOrderQuantity:
                props?.selectedContract
                ?.minQuantityListingSize,
                quantityTickSize:
                props?.selectedContract?.quantityTickSize,
                quantityRangeMax: '',
                quantityRangeMin: '',
                priceTickSize:
                props?.selectedContract?.priceTickSize,
                creditDays: '',
                quanityt: seller_data?.quantity,
                expiryValue: props?.expiryValue,
                contractId: seller_data?.contractId,
                comments:seller_data?.comments,
                deliveryTerm:seller_data?.deliveryTerm,
                listingSlug: seller_data?.slug,
                listing: seller_data
                });
                // props.eventsTracking.contractDetailChat();
                props.setSettlementFields(seller_data.settlementFields)
                props.onCloseClick();
                props.openChatMobileSet(true)
                if (!user.companies.length > 0 ){
                  setUserCompanyAddAlertOpen(true)
                }
              }
              if (isLoggedIn) {
                trackingEvents.negotiateWithSellerClick({listingNumber: seller_data.listingNumber, product: seller_data.contract.name, listingMadeBy: seller_data.user.activeCompany.name})
              } else {
                trackingEvents.negotiateWithSellerClick()
              } 
            }
          
}}>Negotiate with Seller</Button>
                     )} */}
                     </Grid>
                    </Box>
                    
                  ))}
                  
          </Grid>
          
          <Grid item sm={12} xs={12} >
            <Box className={classes.gridCol3}>
              <Box>
                <small className={classes.textMuted}>
                  {
                  // props.firstDataId !=
                    // props.marketData?.vessel_attribute_oldest?.id &&
                  userReducer.noCompany
                    ? 0
                    : totalBuyerQtySet}
                </small>
              </Box>
              <Box textAlign="center">
                <small className={classes.textMuted}>Total Quantity</small>
              </Box>
              <Box textAlign="right">
                <small className={classes.textMuted}>
                  {
                  // props.firstDataId !=
                    // props.marketData?.vessel_attribute_oldest?.id &&
                  userReducer.noCompany
                    ? 0
                    : totalSellerQtySet}
                </small>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box textAlign="center" my={1}>
          {(props.vesselPriceBuyer !== null &&
            props.vesselPriceSeller !== null) &&
            noMoreListingStatus === false && (
              <Button
                disableElevation
                size="small"
                onClick={(e) => {
                  showMoreItems(e);
                  setNoMoreListingStatus(true);
                }}
              >
                View more listings{' '}
                <KeyboardArrowDownIcon
                  fontSize="small"
                  className="vAlignBottom"
                />
              </Button>
            )}
          {noMoreListingStatus === true && (
            <Button disableElevation size="small">
              No more listings
            </Button>
          )}
        </Box>
        <Box textAlign="center" my={1}>
          {
          // props?.marketData.is_auction == true &&
            user.allowed_buying_for_auction == 1 &&
            props.vesselPriceBuyer !== null &&
            props.vesselPriceBuyer.length > 0 && (
              <Typography>
                {user._id == props.vesselPriceBuyer[0].user_id ? (
                  <Typography style={{ color: 'green' }}>
                    Congratulations! you are the highest bidder
                  </Typography>
                ) : (
                  <Typography style={{ color: 'red' }}>
                    Your bid is not highest!
                  </Typography>
                )}
              </Typography>
            )}
        </Box>
      </Box>

      <Accordion
      // expanded={expandedOne}
      // onChange={(e) => setExpandedOne(!expandedOne)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box fontWeight="bold" className={classes.fs16}>
            Product Info
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container className={classes.preFilledInputContainer}>
            <Grid item xs={12} sm={12} className={classes.gridCol2}>
              <Box className={classes.spacing}>Commodity Type</Box>
              <Box textAlign="right">
                <strong>
                  {props?.selectedContract?.commodity?.commodityType?.name}
                </strong>
              </Box>
            </Grid>
            <Divider style={{ marginTop: 5, marginBottom: 5, width: '100%'}} />
            <Grid item xs={12} sm={12} className={classes.gridCol2}>
              <Box className={classes.spacing}>Commodity</Box>
              <Box textAlign="right">
                <strong>{props?.selectedContract?.commodity?.name}</strong>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {props.selectedContract?.publishedClusters && (
        <Accordion
         expanded={openVesselInfoAccordion}
        // expanded={expandedTwo}
        // onChange={(e) => setExpandedTwo(!expandedTwo)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
           onClick={()=>setOpenVesselInfoAccordion(!openVesselInfoAccordion)}
          >
            <Box fontWeight="bold" className={classes.fs16}>
              {props.selectedContract?.clusterName?.plural} Info
            </Box>
          </AccordionSummary>
          <AccordionDetails>
          <Grid container className={classes.preFilledInputContainer}>
  {/* {(props.selectedContract?.publishedClusters && props.selectedContract?.publishedClusters?.length > 0 && props.selectedContract?.publishedClusters[0]) && */}
  {(props.selectedContract?.publishedClusters && props.selectedContract?.publishedClusters?.length > 0 && props.selectedContract?.publishedClusters) &&
    props.selectedContract?.publishedClusters.map((va, key) => (
      <Grid container key={key} alignItems="center" style={{ margin: '10px 0' }}>
        <Grid item xs={3} sm={3} >
          <Box textAlign="left">
            {va.name}
          </Box>
        </Grid>
        <Grid item xs={7} sm={7} >
          {va.fields.map((field, i) => (
            <Box key={i} style={{ display: 'flex', justifyContent: "space-around" }}>
              <Box><span>{field.name}:</span></Box>
              <Box><span>{field.value} {field?.unit?.name}</span></Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={1} sm={1}>
          {
            va.attachment ? 
            <Box textAlign="right" style={{ marginTop: 5, cursor: "pointer" }}>
              <a
                disabled={va.attachment === ""}
                component={Link}
                target="_blank"
                href={`${process.env.REACT_APP_API_URL_COMMODITY}/${va.attachment?.url}`}
                // onClick={handleClose}
                download={va.attachment?.filename}
              >
                <GetAppIcon style={{ fontSize: 16 }} />
              </a>
            </Box>
            :
            <Box style={{ opacity: 0.5, cursor: "not-allowed" }} textAlign="right">
              <GetAppIcon style={{ fontSize: 16 }}/>
            </Box>
          }
        </Grid>
        <Divider style={{ marginTop: 5, marginBottom: 5, width: '100%' }} />
      </Grid>
    ))}
</Grid>
          </AccordionDetails>
        </Accordion>
      )}

      <Hidden smUp >
        <Box sx={{ marginTop: 8 }}>
          <BottomTabsNav />
        </Box>
      </Hidden>
    <Dialog
      open={open === 'newListingCreated'}
      TransitionComponent={Transition}
      // keepMounted
      maxWidth="xs"
      onClose={(e) => {
        setOpen("");
      }}
    >
      <Box>
        <DialogContent>
          <Box
            sx={{
              fontSize: '14px',
              color: '#2a2a2a',
              // mb: 0.75,
              fontWeight: 'normal',
            }}
          >
            <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
              Dear {user.firstName},
            </span>
            <br /> 
            {/* Congratulations on creating a new listing.
            Please click on the link to check your sent {listingType === 'seller' ? 'offers' : 'bids'}.
            {props.vesselPriceSeller.length > 0 && props.vesselPriceSeller.length <= 5 ? 
              props.vesselPriceSeller.length : '5'} */}
              <Typography variant="body1" sx={{fontSize: '18px'}}>
              {/* Dear {user.firstName}, */}
                {
                  listingType === 'buyer' ? 
                  // `Congratulations on creating a new listing. Please click on the link to check your ${props.vesselPriceSeller.length > 0 && props.vesselPriceSeller.length <= 5 ? 
                  //   props.vesselPriceSeller.length : '5'} sent bids`:
                  `Congratulations on creating a new listing. Please click on the link to check your sent bids`:
                  // `Congratulations on creating a new listing. Please click on the link to check your ${props.vesselPriceBuyer.length > 0 && props.vesselPriceBuyer.length <= 5 ? 
                  //   props.vesselPriceBuyer.length : '5'} sent offers`
                  `Congratulations on creating a new listing. Please click on the link to check your sent offers`
                }
              </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{display:'flex', justifyContent:'space-around', alignItems:'center', width: '100%'}}>
            <Button
                onClick={(e) => {
                  setOpen("");
                }}
                disableElevation
                size="small"
                variant="contained"
                color="primary"
                sx={{mr:5, fontSize: '15px'}}
              >
                Okay
              </Button>
              <MuiLink 
                sx={{ fontSize: '15px'}}
                component={RouterLink}
                to="/orders"
                color="primary"
                underline="hover"
              >
                Check Inbox
              </MuiLink>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>

      <Dialog
      open={addImageDialogOpen}
      TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        onClose={(e) => {
          setAddImageDialogOpen(false);
        }}
      >
        <Box
          sx={{
            m: 4
          }}
        >
        <DialogTitle>
            <Box
              sx={{
                fontSize: '14px',
                color: '#2a2a2a',
                mb: 2,
                fontWeight: 'normal',
              }}
            >
              <ImageUploadingInput images={listingImages} onImageChange={(selectedImages) => {
                // console.log('bef setting images')
                setListingImages(selectedImages)
                // console.log('after setting images')
                }} />
            </Box>

            <Box sx={{ textAlign: 'center', mb: 0.75 }}>
              <Button
                onClick={(e) => {
                  updateListingImages();
                }}
                disableElevation
                size="small"
                variant="contained"
                color="primary"
                disabled={!listingImages.length > 0}
              >
                Submit
              </Button>
            </Box>
          </DialogTitle>
        </Box>
      </Dialog>

      <Dialog
        open={open === 'expireModal'}
        TransitionComponent={Transition}
        keepMounted
        //maxWidth="sm"
        onClose={(e) => {
          handleClose(e);
          if (expireSuccess) {
            props.onExpireListing();
          }
        }}
      >
        {expireSuccess && (
          <Box style={{ maxWidth: '300px' }}>
            <ThemeIconButton
              aria-label="close"
              className="closeBtn"
              onClick={(e) => {
                // props.onExpireListing();
                handleClose(e);
              }}
              to="/orders"
              component={Link}
            >
              <img src="/images/cross-icon.svg" alt="Cancel" />
            </ThemeIconButton>
            <DialogTitle align="center" className={`${'text-white'}`}>
              <img src="/images/expired-image.svg" alt="Expired" />
            </DialogTitle>
            <DialogContent>
              <Box textAlign="center" mb={1}>
                <Typography variant="h5">
                  {user_mode === 1 && 'Requirement'}{' '}
                  {user_mode === 2 && 'Product'} expired
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="subtitle1">
                  Your {user_mode === 1 && 'Requirement'}{' '}
                  {/* {user_mode === 2 && 'Product'} ID {selectedListingID} has been */}
                  {user_mode === 2 && 'Product'} ID {selectedListing?.listingNumber} has been
                  expired!
                </Typography>
              </Box>
              <Box mb={2}>
                <ThemeButton
                  onClick={(e) => {
                    // props.onExpireListing();
                    handleClose(e);
                    setSelectedListingId('');
                    setSelectedListing('');
                    // setSelectedQuantity('');
                    // setSelectedListingQuantityUnit('');
                    setExpireSuccess(false)
                    // handleClickOpen('expireModal');
                  }}
                  to="/orders"
                  component={Link}
                  disableElevation
                  color="primary"
                  variant="contained"
                  size="small"
                  fullWidth
                >
                  Close
                </ThemeButton>
              </Box>
            </DialogContent>
          </Box>
        )}
        {!expireSuccess && (
          <Box style={{ maxWidth: '300px' }}>
            <DialogContent>
              <Box>
                <Box mb={2} fontWeight="bold" fontSize={16}>
                  Are you Sure you want to expire listing?
                </Box>
                <Box fontSize={14} className="textMuted">
                  {/* Listing ID: {selectedListingID} */}
                  Listing ID: {selectedListing?.listingNumber}
                  <br />
                  {/* Quantity: {selectedQuantity} {selectedListingQuantityUnit} */}
                  Quantity: {selectedListing?.quantity} {selectedListing?.quantityUnit?.name}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                justifyContent: 'center',
                padding: '24px',
                paddingTop: '0px',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ThemeButton
                    onClick={handleClose}
                    //variant="contained"
                    color="inherit"
                    type="text"
                    style={{ width: '100%' }}
                  >
                    NO THANKS
                  </ThemeButton>
                </Grid>
                <Grid item xs={6}>
                  <ThemeButton
                    onClick={(e) => expireAction()}
                    color="primary"
                    //variant="light"
                    type="text"
                    className={expireButtonClassname}
                    disabled={expireLoader}
                    style={{ width: '100%' }}
                  >
                    {expireLoader && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}{' '}
                    &nbsp; YES EXPIRE IT
                  </ThemeButton>
                </Grid>
              </Grid>
            </DialogActions>
          </Box>
        )}
      </Dialog>
      <Dialog
        open={!!selectedListingThumb}
        onClose={() => selectedListingThumbSet(null)}
        aria-labelledby="selectedListingThumb-title"
        aria-describedby="selectedListingThumb-description"
        maxWidth="md"
      >
        {selectedListingThumb && (
          <DialogTitle id="selectedListingThumb-title">
            <Typography variant="overline" display="block">
              {selectedListingThumb?.contract?.commodity?.commodityType?.name} -{' '}
              {selectedListingThumb?.contract?.commodity?.name}
            </Typography>
            <Grid container>
              <Grid item sm={6}>
               
                <Box
                  style={{
                    color:
                      selectedListingThumb.listingType == 1 ? 'green' : 'red',
                  }}
                >
                  {selectedListingThumb.listingType == 1 ? 'Buy' : 'Sell'}{' '}
                  {selectedListingThumb.price} {selectedListingThumb?.currency}
                </Box>
                <Box>
                  <span className={classes.textMuted}>
                    {selectedListingThumb.paymentTerm?.name + ' Payment'}
                  </span>
                </Box>
              </Grid>
              <Grid item sm={6} textAlign="right">
                <Box>
                  <strong>
                    {selectedListingThumb.quantity}{' '}
                    {selectedListingThumb?.quantityUnit?.name} |
                  </strong>
                  <SpecialRequest
                    specialRequestData={selectedListingThumb.comments}
                    view_count={selectedListingThumb?.views}
                  /><Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={`/listings/${selectedListingThumb.slug}`}
                 
                  size="small">View</Button>
                </Box>
                <Box>
                  <span className={classes.textMuted}>
                    {selectedListingThumb?.liftingDays} Lifting Days
                  </span>
                </Box>
              </Grid>
            </Grid>
          </DialogTitle>
        )}
        {selectedListingThumb && (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item sm={8}>
              <ImageGalleryBox
    thumbnailPosition="left"
    items={selectedListingThumb?.images.map((i) => ({
        original: `${process.env.REACT_APP_API_URL_COMMODITY}/${i.url}`,
        thumbnail: `${process.env.REACT_APP_API_URL_COMMODITY}/${i.url}`,
    }))}
/>

              </Grid>
              <Grid item sm={4}>
                <DialogContentText mb={2} id="selectedListingThumb-description">
                  <Typography variant="h6">
                    {selectedListingThumb?.contract?.name} -{' '}
                    {selectedListingThumb?.contract?.clusterName?.plural}
                  </Typography>
                  {selectedListingThumb?.contractClusters.map((cc) => (
                    <Box mb={2} key={cc._id}>
                      <Typography
                        variant="subtitle1"
                        style={{ margin: 0, padding: 0 }}
                      >
                        {cc.name}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ margin: 0, padding: 0 }}
                      >
                        {cc.fields
                          .filter((f) => f.isMandatory)
                          .map((f) => `${f.name} - ${f.value}`)
                          .join(' | ')}
                      </Typography>
                    </Box>
                  ))}
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => selectedListingThumbSet(null)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { loader })(MarketPrices);