import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    InputLabel,
    Box,
    InputAdornment,
    Typography,
    Button
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { validatorRegEx } from "../../../helpers";
import LanguageTranslate from '../../partials/UnderScoreLanguage'

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
}));

/**
 *
 * @name OfferPrice
 * @alias OfferPrice
 * @component
 * @exports OfferPrice
 *
 * @description
 * Offer Price input field
 *
 */

export default function OfferPrice(props) {
    // console.log('props', props);
    const classes = useStyles();
    // const priceTickSize = props.selectedContract.priceTickSize === null ? 1 :props.selectedContract.priceTickSize;
  

    const [validators, validatorsSet] = React.useState([
        "required",
        // "isNumber"
    ])

    const [errorMessages, errorMessagesSet] = React.useState([
        "Price field is required",
       
    ])

  
    React.useEffect(() => {

        let vald = [
            "required",
            "isFloat",
           
        ]
        let errormsg = [
            "Price field is required",
           
            "Price cannot be in negative numbers!",
           
        ]

       
        validatorsSet(vald)
        errorMessagesSet(errormsg)
    }, [ props.selectedContract, props.listingType, props.vesselPriceSeller, props.user])

    
    return (
        <Box>
       {/* <Button>click hereeeeeeeeee </Button> */}
        <TextValidator
            variant="outlined"
            fullWidth
            size="small"
            validators={validators}
            required
      
            label={(
                <>
                    {
                    // (props.listingType === "seller" && 
                    // // props.marketData.is_auction === 1 && 
                    // (!props.vesselPriceSeller || props.vesselPriceSeller?.length === 0))
                    //     ? <span style={{ fontSize: '13px',marginLeft:-1 }}>Floor Price</span>
                    //     : (
                    //         // props.marketData?.is_auction === 1 && 
                    //         props.vesselPriceSeller?.length > 0)
                    //         ? <span style={{ fontSize: '13px',marginLeft:-1 }}>Bid Price</span>
                    //         : 
                            props.listingType === "buyer"
                                ?  <span style={{ fontSize: '13px',marginLeft:-1 }}>Bid Price</span>
                                : <span style={{ fontSize: '13px',marginLeft:-1 }}>Offer Price</span>
                    }
                    {/* {(props.vesselInfo && props.vesselInfo.vessel_currency && props.vesselInfo.vessel_currency === 'INR') && (
                        <Box sx={{ fontSize: 11, display: 'inline-block' }}> (Including CESS)</Box>
                    )} */}
                </>
            )}
            errorMessages={errorMessages}
            InputProps={{
                endAdornment: (
                    <InputAdornment
                        position="end"
                        className="small"
                    >
                        {props?.selectedContract?.currency}/{props?.qunatityUnit?.name}
                    </InputAdornment>
                )
            }}
            value={props.price}
            onChange={e => { props.setPrice(e.target.value) }}
        />
    
       
    </Box>
    
    );
}