import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { TextValidator } from "react-material-ui-form-validator";
import { storeCTAClick } from "../../../helpers"
import {
    Grid,
    InputLabel,
    Box,
    CircularProgress,
    Button
} from "@mui/material";
import { Autocomplete } from '@mui/material';
import LanguageTranslate from '../../partials/UnderScoreLanguage'
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },

    gridCol2: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: 'center',
        gridGap: '5px',
        '& .MuiButton-startIcon': {
            marginRight: '1px'
        },
        [theme.breakpoints.only("xs")]: {
            gridTemplateColumns: "1fr 200px",
        },
    },
    labelColor: {
        color: theme.palette.primary.main
    },
    fxSM: {
        [theme.breakpoints.only("xs")]: {
            fontSize: 14
        }
    }

}));

/**
 *
 * @name SelectAddress
 * @alias SelectAddress
 * @component
 * @exports SelectAddress
 *
 * @description
 * SelectAddress input field
 *
 */

export default function SelectAddress(props) {
    // console.log(props)
    const classes = useStyles();
    return (
        <Box>
          
            {/* <InputLabel className={classes.marginBottom}>{props?.selectedContract?.clusterName?.plural
}<sup style={{ color: "red" }}>*</sup></InputLabel> */}
            <Autocomplete
                // multiple
                // InputLabel='nik'
                name="SelectAddress_name"
                size="small"
                filterSelectedOptions
                options={
                    props.SelectAddress_list 
                }
                onChange={(e,address) => {
                    // console.log('eeeeeeeeeeeee',address);
                    props.setSelectAddressInfoData(address);
                }}
                getOptionLabel={(option) => option.full}
                renderOption={(props, option) => <Box component="li" {...props} key={option._id}>{option.full}</Box>}
                value={props.SelectAddressInfoData}
                renderInput={params => (
                    <TextValidator
                        {...params}
                        variant="outlined"
                        label= <span style={{ fontSize: '13px'}}>Delivery Address</span>
                        
                        fullWidth
                        
                        value={props.SelectAddressInfoData}
                    />
                )}
            />
        </Box>
    );
}
