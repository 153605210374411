import React from 'react'
import CommentIcon from '@mui/icons-material/Comment';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export default function SpecialRequest({ specialRequestData, view_count }) {
    const [openSpecialRequest, openSpecialRequestSet] = React.useState(false);

    const CustomTooltip = styled(({ className, ...props }) => (
        <ClickAwayListener onClickAway={() => { openSpecialRequestSet(false); }}>
            <Tooltip {...props} classes={{ popper: className }} />
        </ClickAwayListener>
    ))
        (({ theme }) => ({
            [`& .${tooltipClasses.arrow}`]: {
                color: theme.palette.common.white,
                "&::before": {
                    backgroundColor: '#2959ba',
                    border: "1px solid #2959ba"
                }
            },
            [`& .${tooltipClasses.tooltip}`]: {
                // backgroundColor: theme.palette.common.white,
                backgroundColor: '#2959ba',
                color: '#fff',
                boxShadow: theme.shadows[1],
                padding: 12,
                fontSize: 11,
            },
        }));

    return (
        <CustomTooltip arrow

            PopperProps={{
                disablePortal: true,
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={() => { openSpecialRequestSet(false); }}
            open={openSpecialRequest}
            title={specialRequestData !== null && specialRequestData}
            aria-label="additional info"
        >
            <IconButton onClick={() => { openSpecialRequestSet(true); }} disabled={specialRequestData == null ? true : false} size="small">
                <CommentIcon color={specialRequestData !== null ? 'primary' : 'default'} style={{ fontSize: '13px', marginRight: .5 }} fontSize='small' className="vAlignBottom" />
                
            </IconButton>
        </CustomTooltip>
    )
}
