import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { loader } from "../../redux/actions/commonActions";
import { Product } from "../../api/resources/Product";
import ThemeButton from "../partials/ThemeButton";
import SendIcon from "@mui/icons-material/Send";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
	Container,
	Grid,
	Box,
	Paper,
	Tabs,
	CircularProgress,
	Dialog,
	Tab,
	IconButton,
	DialogContent,
	DialogTitle,
	TextField,
	InputLabel,
	Typography,
	Button,
	Slide,
	InputAdornment,
	Autocomplete,
	DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditAddress from "../requirement/partials/EditAddress";
import AnimatedSuccess from "../partials/AnimatedSuccess";
import Quantity from "./partials/Quantity";
import AddIcon from "@mui/icons-material/Add";
import OfferPrice from "./partials/OfferPrice";
import PaymentTerms from "./partials/PaymentTerms";
import LiftingDays from "./partials/LiftingDays";
import Expiry from "./partials/Expiry";
import Address from "./partials/Address";
import AdvanceBalance from "./partials/AdvanceBalance";
// import Hidden from '@mui/material/Hidden';
import { fireAdCreditQTYListingBuySellNow, fireCompanyNotAddedEvent } from "../../helpers";
import { useSelector } from "react-redux";
import { addDays, endOfDay } from "date-fns";
import dateformat from "dateformat";
import Emitter from "../../services/emitter";
import { Navigate, Link } from "react-router-dom";
import NumberOfCreditDays from "./partials/NumberOfCreditDays";
import NumberOfPaymentDays from "./partials/NumberOfPaymentDays";
import CurrencyValidationDialog from "../partials/chat/CurrencyValidationDialog";
import VesselDropdown from "./partials/VesselDropdown";
import IsHotCheckbox from "./partials/IsHotCheckbox";
import ListingVisibility from "./partials/ListingVisibility";
import { CommodityUser } from "../../api/resources/CommodityUser";
import DeliveryTerms from "./partials/DeliveryTerms";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import Paymentvariable from "./partials/Paymentvariable";
import ImageUploadingInput from "../ImageUploadingInput";
import { ConsoleCommodity } from "../../api/resources/admin-api/ConsoleCommodity";
import SelectAddress from "./partials/SelectAddress";
import { CommodityCommon } from "../../api/resources/admin-api/CommodityCommon";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
	paperSpacing: {
		// color: theme.palette.common.white,
		padding: theme.spacing(1, 2),
		[theme.breakpoints.down("md")]: {
			padding: theme.spacing(2.5, 0),
		},
	},
	buttonLoaderWrapper: {
		// margin: theme.spacing(1),
		position: "relative",
		marginRight: 0,
	},
	tabItems: {
		"& .MuiTab-textColorPrimary.Mui-selected": {
			fontWeight: "bold",
		},
	},
	buttonProgress: {
		// color: green[500],
		position: "absolute",
		textAlign: "center",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	vesselInfoCard: {
		maxWidth: "320px",
		padding: "15px",
		backgroundColor: theme.palette.common.white,
	},
	gridHeader: {
		display: "grid",
		gridTemplateColumns: "16px auto 70px",
		// justifyItems: 'center',
		alignItems: "center",
	},
	fs12Grey: {
		fontSize: "12px",
		color: "#6a6a6a",
	},
	gridRating: {
		display: "grid",
		gridTemplateColumns: "auto 100px auto",
		alignItems: "center",
	},
	fsSmall: {
		fontSize: "10px",
	},
	tag: {
		width: "64px",
		height: "20px",
		lineHeight: "20px",
		borderRadius: "15px",
		backgroundColor: "#f2f3f5",
		textAlign: "center",
		display: "inline-block",
		marginLeft: "6px",
	},
	action: {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		gridGap: "5px",
	},
	labelColor: {
		// color: '#0645ad',
		color: theme.palette.primary.main,
		marginBottom: theme.spacing(1),
	},
	grayBackground: {
		backgroundColor: "#f2f3f5",
	},

	dialogHeader: {
		padding: theme.spacing(1),
		marginTop: theme.spacing(1.5),
		"& h2": {
			fontSize: 24,
			fontWeight: "bolder",
		},
	},
	fontSizeXs: {
		fontSize: "0.75rem",
	},
	p0: {
		padding: "0 !important",
	},

	specialRequestData: {
		backgroundColor: "#f2f5fb",
		padding: theme.spacing(0.5, 1),
		// display: 'flex',
		// justifyContent: 'space-between',
		borderRadius: "4px",
		position: "relative",
		"& .edit": {
			visibility: "hidden",
			position: "absolute",
			right: "5px",
			top: "2px",
		},
		"&:hover > .edit": {
			visibility: "visible",
		},
	},
}));

/**
 *
 * @name BuySell
 * @alias BuySell
 * @component
 * @exports BuySell
 *
 * @description
 * BuySell component to post rfq/listing
 */

function BuySell(props) {
	const classes = useStyles();
	const matchesViewport = useMediaQuery((theme) => theme.breakpoints.only("xs"));
	const userReducer = useSelector((state) => state.userReducer);
	const user = useSelector((state) => state.userReducer.user);
	// const type = 2;
	const company = useSelector((state) => state.userReducer.company);
	const states = useSelector((state) => state.commonReducer.states);
	const globalSetting = useSelector((state) => state.commonReducer.globalSettings);
	const paymentTerms = useSelector((state) => state.commonReducer.paymentTerms);
	const [open, setOpen] = React.useState(false);
	const [coalPortList, setCoalPortList] = React.useState([]);
	const [address, setAddress] = React.useState("");
	const [pincode, setPincode] = React.useState("");
	const [regionId, setRegionId] = React.useState("");
	const [port, setPort] = React.useState("");
	const [quantity, setQuantity] = React.useState("");
	//const [price, setPrice] = React.useState("");
	const [paymentTermId, setPaymentTermId] = React.useState(props.selectedContract?.paymentTerms[0]._id);
	const [deliveryTerm, setdeliveryTerm] = React.useState("");
	// const [liftingDays, setLiftingDays] = React.useState(props.marketData.is_auction == true ? 4 : "");
	const [liftingDays, setLiftingDays] = React.useState(7);
	const [expiry, setExpiry] = React.useState( 43200
		// [{ _id: "65aea07a196d757d37e0251a", label: "30 Days", minutes: 43200, type: 0 }]
	);
	const [submitting, submittingSet] = React.useState(false);
	const [rfqSuccessLoader, setRfqSuccessLoader] = useState(false);
	const [originOfCoal, setOriginOfCoal] = React.useState("");
	const [coalType, setCoalType] = React.useState("");
	const [country, setCountry] = React.useState("");
	const [advance, setAdvance] = React.useState(0);
	const [balance, setBalance] = React.useState("");
	const [numberOfCreditDays, setNumberOfCreditDays] = React.useState("30");
	const [numberOfDays, setNumberOfDays] = React.useState("");
	const [vesselAttributeId, setVesselAttributeId] = React.useState("");
	const [tempAddresses, setTempAddresses] = useState([]);
	const [allCities, setallCities] = useState([]);
	const [allStates, setallStates] = useState([]);
	const [allCountries, setallCountries] = useState([]);
	const [addAddressFormFilled,setAddressFormFilled] = useState(false);
	//const [value, setValue] = React.useState("seller");
	const [calorificValue, setCalorificValue] = React.useState("");
	const [calorificType, setCalorificType] = React.useState("");
	const [specialRequests, setSpecialRequests] = React.useState("");
	const [filledAddress, setFilledAddress] = React.useState("");
	const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
	const [mineName, setMineName] = React.useState("");
	const [miningCompany, setMiningCompany] = React.useState("");
	const [manufactureName, setManufactureName] = React.useState("");
	const [madeFrom, setMadeFrom] = React.useState("");
	const [shape, setShape] = React.useState("");
	const [refinery, setRefinery] = React.useState("");
	const [ashValue, setAshValue] = React.useState("");
	const [sulphurValue, setSulphurValue] = React.useState("");
	const [volatileMatterValue, setVolatileMatterValue] = React.useState("");
	const [sizeRangeFromValue, setSizeRangeFromValue] = React.useState("");
	const [sizeRangeToValue, setSizeRangeToValue] = React.useState("");
	const [editAddressId, setEditAddressId] = React.useState("");
	const [editAddress, setEditAddress] = React.useState("");
	const [landMark, setlandMark] = React.useState("");
	const [editPincode, setEditPincode] = React.useState("");
	const [editCity, setEditCity] = React.useState(null);
	const [editState, setEditState] = React.useState(null);
	const [editCountry, seteditCountry] = React.useState(null);
	
	const [SelectAddressInfoData, setSelectAddressInfoData] = React.useState();
	
	const [editAddressSuccess, setEditAddressSuccess] = React.useState(false);
	const [currencyValidationDialog, currencyValidationDialogSet] = React.useState(false);
	const [automateChatDialog, automateChatDialogSet] = React.useState({
		show: false,
		data: {},
	});
	const [doublePriceEAuctionDialog, doublePriceEAuctionDialogSet] = React.useState({ show: false, data: {} });
	const [isDialogMessage, isDialogMessageSet] = React.useState("");
	const [gotoPlanPage, gotoPlanPageSet] = React.useState(false);
	const [isHot, isHotSet] = React.useState(user?.ad_credits > 0 && quantity > 0 && user.ad_credits >= quantity ? true : false);
	const [listingVisibility, setListingVisibility] = React.useState("0");
	const [images, imagesSet] = React.useState([]);
	const [varName, setvarName] = React.useState("");
	const [varDefaultValue, setvarDefaultValue] = React.useState("");
	const [varPlaceholder, setvarPlaceholder] = React.useState("");
    const [settlementFieldValues, settlementFieldValuesSet] = React.useState([]);
    const [settlementFieldsArr, settlementFieldsArrSet] = React.useState([]);
    const [errorMessages, setErrorMessages] = React.useState([]);
	const [showErrors, setShowErrors] = React.useState(false);

	React.useEffect(() => {
		if (user.ad_credits >= quantity) {
			isDialogMessageSet(
				props.tabValue == "buyer"
					? "Which means, for next 30 minutes, any exactly matching offer will get automatically accepted on your behalf."
					: "Which means, for next 30 minutes, any exactly matching bid will get automatically accepted on your behalf."
			);
			gotoPlanPageSet(false);
		} else {
			isDialogMessageSet(`You are just ${quantity - user.ad_credits} Ad Credits away from creating a HOT listing.`);
			gotoPlanPageSet(true);
		}
	}, [props.tabValue, quantity, user.ad_credits]);

	React.useEffect(() => {
		if (user.ad_credits > 0 && quantity > 0 && user.ad_credits >= quantity) {
			isHotSet(true);
		} else {
			isHotSet(false);
		}
	}, [quantity, user.ad_credits]);

	const handleChange = (event, newValue) => {
		props.setTabValue(newValue);
	};

	const handleClose = () => {
		setOpen(false);
	};

	/**
	 * @function fetchData
	 * @name fetchData
	 * @description
	 * Function to fetch the static data like coal ports list, vessel list, temp addresses list, eyard groups , old rfq info, mining companies list, states list
	 *
	 */

	const fetchAddressesData = async () => {
		try {
			props.loader(true);
		if(window.location.pathname.includes('/console/admin')){
			const response = await ConsoleCommodity.fetchUserCompanyAddresses(props.userId._id, props.userId.activeCompanyId)
			console.log({response})
			if(response.status == 200) {
				const addresses = response.data.data
				setTempAddresses([...addresses]);
			}
			// let clusterAddresses=[]
			// console.log({clusterAdds:props.selectedContract.publishedClusters})
			// props.selectedContract.publishedClusters.map(pc=> {
			// 	if(pc.addresses && pc.addresses.length > 0) {
			// 		clusterAddresses = [...clusterAddresses, ...pc.addresses]
			// 	}
			// })
			// setTempAddresses([...clusterAddresses]);
		}else{
			const tempAddressesRes = await CommodityUser.getAddress(user.activeCompanyId);
			if (tempAddressesRes.status==200) {
				let clusterAddresses = [...tempAddressesRes.data.data.addresses]
			// clusterAddresses=[...tempAddressesRes.data.data]

				//  props.selectedContract.publishedClusters.map(pc=> {
				// 	 if(pc.addresses && pc.addresses.length > 0) {
				// 		 clusterAddresses = [...clusterAddresses, ...pc.addresses]
				// 	 }
				//  })
				setTempAddresses([...tempAddressesRes.data.data.addresses]);
				//  if (clusterAddresses.length>0) {
				// 	  setTempAddresses([...clusterAddresses]);
				//  }else{
				// 	setTempAddresses([...clusterAddresses]);
				//  }
				
				// setTempAddresses([...clusterAddresses,...tempAddressesRes.data.data.addresses]);
			} else {
				// if (tempAddressesRes.data.status == "fail" && tempAddressesRes.data.message == "Company Not Found Or Not Added Yet Please.") {
				// 	<Navigate to="/add-company-eKyc" />;
				// }
				setTempAddresses([]);
			}

			props.loader(false);
		}
			
		} catch (error) {
			console.error(error);
		}
		props.loader(false);
	};

	useEffect(() => {
		if(window.location.pathname.includes('/console/admin') && props.userId == null){
				setTempAddresses([])

		}else {
			fetchAddressesData();
		}
		
		// fetchData();
	}, [user.token, user.active_company, props.userId]);

	useEffect(() => {
		if(window.location.pathname.includes('/console/admin')){
			getcountry()
		}else{
			getcountry()
			// getState()
			// getCity()
		}
		
		// fetchData();
	}, []);

	const getcountry = async () => {
		try {
			props.loader(true);
		
			const countries = await CommodityCommon.Countries();
			if (countries.status==200) {
			
				 setallCountries(countries.data.data);
				// setTempAddresses([...clusterAddresses,...countries.data.data.addresses]);
			} else {
				
				setTempAddresses([]);
			}

			props.loader(false);
		} catch (error) {
			console.error(error);
		}
		props.loader(false);
	};
	

	// useEffect(() => {
	// 	if (type != 1) {
	// 		props.setTabValue("seller");
	// 	}
	// 	if (type == 1) {
	// 		props.setTabValue("buyer");
	// 	}
	// }, [props.marketData, props.vesselPriceSeller]);

    const makeSettlementFieldsArray = () => {
        const temp = props.selectedContract.commodity.fields.filter(f => (f.commoditySection && f.commoditySection.slug == 'settlement-section'));
        settlementFieldsArrSet(temp);
		// console.log('temp arr', temp)
        settlementFieldValuesSet(temp.map(t => ({_id: t._id, value: ''})));
    }

    React.useEffect(() => {
        if(props.selectedContract.commodity && props.selectedContract.commodity.fields && props.selectedContract.commodity.fields.length > 0) {
            makeSettlementFieldsArray()
        }
    }, [props.selectedContract])
	const showAdress = () => {
		let clusterAddresses=[]
		props.selectedContract.publishedClusters.map(pc=> {
			if(pc.addresses && pc.addresses.length > 0) {
				clusterAddresses = [...clusterAddresses, ...pc.addresses]
			}
		})
		setTempAddresses([...clusterAddresses]);
        }
	React.useEffect(() => {
        if(deliveryTerm.hasOwnProperty("askAddress") && deliveryTerm.askAddress) {
          
			
			fetchAddressesData()
        }else{
			// showAdress()
			fetchAddressesData()
		}
    }, [deliveryTerm])

	/**
	 * @function submitRfqForm
	 * @name submitRfqForm
	 * @description
	 * Function to store the rfq/ listing form details
	 *
	 */

	const submitRfqForm = (e) => {
		submittingSet(true);

		if(window.location.pathname.includes('/console/admin')){
			const formdata = new FormData();
			formdata.append('contractId', props?.selectedContract?._id);
			formdata.append('listingType', props.tabValue == "seller" ? 2 : 1);
			props.vesselInfoData.map(v => {
				formdata.append('contractClusterIds[]', v._id);
			});
			formdata.append('price', props.price);
			formdata.append('quantity', quantity);
			formdata.append('paymentTermId', paymentTermId);
			formdata.append('deliveryTermId', props?.selectedContract?.deliveryTerms[0]._id);
			formdata.append('liftingDays', liftingDays);
			formdata.append('isHot', isHot == true ? 1 : 0);
			formdata.append('platformVisibility', listingVisibility);
			formdata.append('validityMinutes', expiry);
			formdata.append('comments', specialRequests);
			formdata.append('isNonNegotiable', 0);
			// images.map(i => formdata.append('images', i.file));
			images.map(i => {
				if(i.url){
				 let exist = {};
				 Object.assign(exist, {filename: i.filename, url: i.url})
				  return formdata.append('existingImages', JSON.stringify(exist))
				 } else {
				   return formdata.append('images', i.file)
				 }
			   })
			// images.map(i => formdata.append('EntireImages[]', i));
			formdata.append('addressId',(SelectAddressInfoData && SelectAddressInfoData._id) ? [SelectAddressInfoData._id]:[])
			settlementFieldValues.map((sf, i) => {
				formdata.append(`settlementFields[${i}][_id]`, sf._id);
				formdata.append(`settlementFields[${i}][value]`, sf.value);
			});
			ConsoleCommodity.createListing(formdata,props.userId._id)
		
			  .then((response) => {
				if (response.status == 201) {
				 
				  imagesSet([]);
				  setRfqSuccessLoader(false);
				  setQuantity("");
				  props.setPrice("");
				  setPaymentTermId("");
				  setLiftingDays("");
			// formdata.append('contractId', props?.selectedContract?._id);
				  props.setCloseSellAccordion(false)
				  props.setCloseBuyAccordion(false)
				  setNumberOfDays("");
				  //setExpiry(props.marketData.is_auction == true ? 0.5 : 24);
				  props.setVesselInfoData([]);
				  setSpecialRequests("");
				  setSelectedAddressIndex(0);
				  props.setTabValue('');
				  setTimeout(() => {
					Emitter.emit("SNACKBAR_NOTIFICATION", {
					  message: `Listing posted successfully!`,
					  params: { variant: "success" },
					});
				  }, 300);
				  // getbestbuyer={getbestbuyer}
				  // getbestSeller={getbestSeller}
				//   props.onAddListing();
				  props.getbestbuyer()
				  props.getbestSeller()
				} else {
				  setRfqSuccessLoader(false);
				}
	
	
			  })
			  .finally(() => {
				submittingSet(false);
				});
		}else{
			const formdata = new FormData();
			formdata.append("contractId", props?.selectedContract?._id);
			formdata.append("listingType", props.tabValue == "seller" ? 2 : 1);
			props.vesselInfoData.map((v) => {
				formdata.append("contractClusterIds[]", v._id);
			});
	
			formdata.append("price", props.price);
			formdata.append("quantity", quantity);
			formdata.append("paymentTermId", paymentTermId);
		
			formdata.append('deliveryTermId', props?.selectedContract?.deliveryTerms[0]._id);
			formdata.append("liftingDays", liftingDays);
			formdata.append("isHot", isHot == true ? 1 : 0);
			formdata.append("platformVisibility", listingVisibility);
			formdata.append("validityMinutes", expiry);
			formdata.append("comments", specialRequests);
			formdata.append("isNonNegotiable", 0);
			// images.map((i) => formdata.append("images", i.file));
			// API pending in backend
			images.map(i => {
				if(i.url){
				 let exist = {};
				 Object.assign(exist, {filename: i.filename, url: i.url})
				  return formdata.append('existingImages', JSON.stringify(exist))
				 } else {
				   return formdata.append('images', i.file)
				 }
			   })
			
			formdata.append('addressId',(SelectAddressInfoData && SelectAddressInfoData._id) ? [SelectAddressInfoData._id]:[])
			settlementFieldValues.map((sf, i) => {
				formdata.append(`settlementFields[${i}][_id]`, sf._id);
				formdata.append(`settlementFields[${i}][value]`, sf.value);
			});
			CommodityUser.CreateListing(formdata)
				.then((response) => {
				
					if (response.status == 201) {
						// document.getElementById("uploadImages").value = "";
						
						setTimeout(() => {
							Emitter.emit("SNACKBAR_NOTIFICATION", {
								message: `Listing posted successfully!`,
								params: { variant: "success" },
							});
						}, 300);
					
						makeSettlementFieldsArray()
						imagesSet([]);
						setRfqSuccessLoader(false);
						props.setCloseSellAccordion(false)
				  props.setCloseBuyAccordion(false)
						setQuantity("");
						props.setPrice("");
						setPaymentTermId("");
						setLiftingDays("");
						setNumberOfDays("");
						setExpiry('')
						setdeliveryTerm('')
						setSelectAddressInfoData()
						//setExpiry(props.marketData.is_auction == true ? 0.5 : 24);
						props.setVesselInfoData([]);
						setSpecialRequests("");
						setSelectedAddressIndex(0);
						props.setTabValue('');
						// props.onAddListing();
						props.tabValue == "seller" ? 
						props.getbestSeller() : 
						props.getbestbuyer()
						// props.getbestbuyer();
						// props.getbestSeller();
						props.setNewListingCreated(props.tabValue)
					
					} else {
						setRfqSuccessLoader(false);
					}
				})
				.finally(() => {
					submittingSet(false);
				});
		}
		
	};

	/**
	 * @function storeEditAddressAction
	 * @name storeEditAddressAction
	 * @description
	 * Function to edit the existing address
	 *
	 */

	const storeEditAddressAction = (e) => {
		setAddressFormFilled(true)
		props.loader(true);
		let data = {
			landmark: landMark,
			cityId: editCity._id,
			pincode: editPincode,
			line1: editAddress,
			addressableType: "COMPANIES"
		};

		if(window.location.pathname.includes('console/admin')){
			ConsoleCommodity.addUserCompanyAddress(props.userId._id, data)
			.then((response) => {
				console.log({response})
				setEditAddressSuccess(true);
					fetchAddressesData()
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				props.loader(false);
			})
		} else {
			CommodityUser.addAddress(data)
			.then((response) => {
				if (response.status === 201) {
					setEditAddressSuccess(true);
					fetchAddressesData()
					// Product.allTempAddressForRfq({
					// 	token: user.token,
					// }).then((response) => {
					// 	setTempAddresses(response.data.data);
					// 	setSelectedAddressIndex(1);
					// });
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				props.loader(false);
			});
		}
	};

	React.useEffect(() => {
		if(errorMessages.length > 0) {
			setShowErrors(true);
			const timer = setTimeout(() => {
				setShowErrors(false);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [errorMessages])

	return (
		<Box >
			{/* <Container maxWidth="md"> */}
			
				<Grid item sm xs={12} >
					{/* <Button onClick={()=>{
						console.log('sellers: ', props.vesselPriceSeller.filter(list => list?.user?._id !== user?._id))
						console.log('buyes: ', props.vesselPriceBuyer.filter(list => list?.user?._id !== user?._id))
					}}>props</Button> */}
					<ValidatorForm
								onSubmit={(e) => {
									// userReducer.isLoggedIn && userReducer.noCompany
									// 	? fireCompanyNotAddedEvent()
									// 	: props.marketData.is_auction == true &&
									// 	  props.vesselPriceBuyer != null &&
									// 	  props.vesselPriceBuyer?.length > 0 &&
									// 	  props.price > props.vesselPriceBuyer[0]?.price_value * 2
									// 	? doublePriceEAuctionDialogSet({ show: true })
									// 	: props.marketData.is_auction == true
									// 	// ? submitRfqForm()
									// 	? console.log('auction case called', props.marketData)
									// 	: isHot == false
									// 	// ? submitRfqForm()
									// 	? console.log('is hot case called', isHot, props)
									// 	: automateChatDialogSet({ show: true });
									{
										!window.location.pathname.includes('/console/admin') 
										? (
											// (
											// 	(props.tabValue === "buyer" && props.vesselPriceSeller.filter(list => list?.user?._id !== user?._id).length > 0) || 
											// (props.tabValue === "seller" && props.vesselPriceBuyer.filter(list => list?.user?._id !== user?._id).length > 0)) 
											// ? setOpen('confirmCreateListing') 
												userReducer.isLoggedIn && userReducer.noCompany
												? fireCompanyNotAddedEvent()
												: 
												// props.marketData.is_auction == true &&
													props.vesselPriceBuyer != null &&
													props.vesselPriceBuyer?.length > 0 &&
													props.price > props.vesselPriceBuyer[0]?.price_value * 2
												? doublePriceEAuctionDialogSet({ show: true })
												// : 
												// props.marketData.is_auction == true
												// ? submitRfqForm()
												// ? console.log('auction case called', props.marketData)
												: isHot == false
												? submitRfqForm()
												// ? console.log('is hot case called', isHot, props)
												: automateChatDialogSet({ show: true })
										)
										:
										(
											userReducer.isLoggedIn && userReducer.noCompany
											? fireCompanyNotAddedEvent()
											:
											//  props.marketData.is_auction == true &&
												props.vesselPriceBuyer != null &&
												props.vesselPriceBuyer?.length > 0 &&
												props.price > props.vesselPriceBuyer[0]?.price_value * 2
											? doublePriceEAuctionDialogSet({ show: true })
											// : 
											// props.marketData.is_auction == true
											// ? submitRfqForm()
											// ? console.log('auction case called', props.marketData)
											: isHot == false
											? submitRfqForm()
											// ? console.log('is hot case called', isHot, props)
											: automateChatDialogSet({ show: true })
										)
									}
										
								}}
								onError={(errors)=>{
									// console.log(errors ,'in the form')
									// console.log(errors.map(err => err.getErrorMessage()))
									setErrorMessages(()=>{
										const messages = errors.map(err => err.getErrorMessage());
										return messages;
									});
								}}
								noValidate
							>
									<Grid container spacing={2} rowGap={3}   >
									<Grid item sm={4} xs={12} >
											<Quantity
												quantity={quantity}
												setQuantity={setQuantity}
												user={user}
												qunatityUnit={props.selectedContract?.quantityUnit}
												minimumOrderQuantity={props.selectedContract?.minQuantityListingSize}
												quantityTickSize={props.selectedContract?.quantityTickSize}
												quantityRangeMax={props.selectedContract?.quantityRangeMax}
												quantityRangeMin={props.selectedContract?.quantityRangeMin}
												isValidQuantity={props.isValidQuantity}
												setIsValidQuantity={props.setIsValidQuantity}
												// marketData={props.marketData}
												vesselPriceSeller={props?.vesselPriceSeller}
												vesselPriceBuyer={props?.vesselPriceBuyer}
												tabValue={props.tabValue}
											/>
										</Grid>
										<Grid item sm={4} xs={12} >
											<OfferPrice
												key={props.tabValue}
												originOfCoal={originOfCoal}
												country={country}
												user={user}
												qunatityUnit={props.selectedContract?.quantityUnit}
												currency={props.selectedContract?.currency}
												listingType={props.tabValue}
												price={props.price}
												setPrice={props.setPrice}
												// priceRange={props.priceRange}
												
												tickSize={globalSetting.tick_size}
												// vesselInfo={props.vesselInfo}
												// priceTickSize={props.priceTickSize}
												// isValidPrice={props.isValidPrice}
												// setIsValidPrice={props.setIsValidPrice}
												// marketData={props.marketData}
												vesselPriceSeller={props?.vesselPriceSeller}
												vesselPriceBuyer={props?.vesselPriceBuyer}
												selectedContract={props.selectedContract}
											/>
										</Grid>
										<Grid item sm={4} xs={12} >
											<VesselDropdown
												selectedContract={props.selectedContract}
												vessel_list={props.selectedContract?.publishedClusters}
												vesselInfoData={props.vesselInfoData}
												setVesselInfoData={props.setVesselInfoData}
											/>
										</Grid>
										{(
											// props.marketData.is_auction != true || 
											// (props.marketData.is_auction == true && 
												// props.vesselPriceSeller == null) && (
											<Grid item sm={4} xs={12} >
												<LiftingDays
													liftingDays={liftingDays}
													quantity={quantity}
													// marketData={props.marketData}
													setNumberOfDays={setNumberOfDays}
													setLiftingDays={setLiftingDays}
												/>
											</Grid>
										)}
										<Grid item sm={4} xs={12} >
											<PaymentTerms
												paymentTermId={paymentTermId}
												setPaymentTermId={setPaymentTermId}
												paymentTerms={props.selectedContract?.paymentTerms}
												setAdvance={setAdvance}
												user={user}
												// marketData={props.marketData}
												setvarName={setvarName}
												setvarPlaceholder={setvarPlaceholder}
												setvarDefaultValue={setvarDefaultValue}
												vesselPriceSeller={props.vesselPriceSeller}
												setNumberOfCreditDays={setNumberOfCreditDays}
											/>
										</Grid>
										
										{paymentTermId && varName && (
											<Grid item sm={4} xs={12} >
												<Paymentvariable
													varName={varName}
													varPlaceholder={varPlaceholder}
													varDefaultValue={varDefaultValue}
													setvarDefaultValue={setvarDefaultValue}
												/>
											</Grid>
										)}
										<Grid item sm={4} xs={12} >
											<Expiry
												expiry={expiry}
												expiryValue={props.expiryValue}
												setExpiry={setExpiry}
												// marketData={props.marketData}
												user={user}
												vesselPriceSeller={props.vesselPriceSeller}
											/>
										</Grid>
											
                                                {settlementFieldsArr.map((settlementField, index) => 
											<Grid item sm={12} >   
												<Grid container spacing={2} >
												<Grid item sm={4} xs={12}  key={settlementField._id}>
													{
														settlementField.inputType === "Dropdown" ?
														<Autocomplete
														name="SettlementField_name"
														size="small"
														multiple={settlementField.isMultiSelect && settlementField.isMultiSelect == 1 ? true : false}
														filterSelectedOptions
														options={
															settlementField?.multipleValues 
														}
														onChange={(e, newVal) => { 
															console.log('new vall', newVal)
																const temp = [...settlementFieldValues];
																temp[index].value = newVal || [];
																// console.log(temp);
																settlementFieldValuesSet(temp);
                                                        }}
														getOptionLabel={(option) => option}
														renderOption={(props, option) => <Box component="li" {...props} key={option}>{option}</Box>}
														value={settlementFieldValues[index].value || []}
														renderInput={params => (
															<TextValidator
																{...params}
																variant="outlined"
																label= 
																	<span style={{ fontSize: '13px'}}>{settlementField.units.length > 0 ? settlementField?.name +' - '+ settlementField?.units[0]?.name : settlementField?.name }</span>
																fullWidth
															/>
														)}
													/> 
													: 
                                                    <TextValidator
													 variant="outlined"
													 fullWidth
													 size="small"
                                                        label=<span style={{ fontSize: '13px'}}>{settlementField.name}</span>
														
                                                        value={settlementFieldValues[index].value}
                                                        onChange={e => {
                                                            const temp = [...settlementFieldValues];
                                                            temp[index].value = e.target.value;
                                                            settlementFieldValuesSet(temp);
                                                        }}
														required={settlementField.isMandatory==1?true:false}
														errorMessages={`Please enter ${settlementField.name}`}
														InputProps={{
															endAdornment: (
																<InputAdornment
																	position="end"
																	className="fsSmall"
																>
																	{settlementField?.units[0]?.name}
																</InputAdornment>
															)
														}}
                                                    />
													}
                                                </Grid>
                                            </Grid>
                                        </Grid>
											)}
										{/* <Grid item sm={4} xs={12} >
											<DeliveryTerms
												deliveryTerm={deliveryTerm}
												setdeliveryTerm={setdeliveryTerm}
												deliverytermsArray={props?.selectedContract?.deliveryTerms}
												setAdvance={setAdvance}
												user={user}
												marketData={props?.marketData}
												vesselPriceSeller={props?.vesselPriceSeller}
												setNumberOfCreditDays={setNumberOfCreditDays}
											/>
										</Grid> */}
										<Grid item sm={8} xs={8} >
										{
											<SelectAddress
												SelectAddress_list={tempAddresses}
												SelectAddressInfoData={SelectAddressInfoData}
												setSelectAddressInfoData={setSelectAddressInfoData}
											/>
										}
									<Box
										className={`${classes.labelColor} pointer`}
										onClick={(e) => setOpen("editAddressModal")}
									>
									<AddIcon className="vAlignBottom" style={{ marginTop: 3 }} /> Add Address
									</Box>
									</Grid>

										<Grid item sm={12} xs={12}>
											{!open && specialRequests.length > 0 ? (
												<Box className={classes.specialRequestData}>
													<Typography fontSize={13} style={{wordWrap: "break-word"}}>{specialRequests}</Typography>
													<IconButton
														aria-label="open comment box"
														className="edit bg-white"
														size="small"
														color="primary"
														onClick={(e) => {
															setOpen("specialCommentsModal");
														}}
													>
														<EditIcon size="small" />
													</IconButton>
												</Box>
											) : (
												<Box
													className={`${classes.labelColor} pointer`}
													onClick={(e) => {
														setOpen("specialCommentsModal");
													}}
												>
													<AddIcon className="vAlignBottom" /> Add Special Comments
												</Box>
											)}
										</Grid>

										
											<Grid item sm={12} xs={12} alignSelf="flex-end">
											<Box mb={2}>
												<ImageUploadingInput images={images} onImageChange={(selectedImages) => imagesSet(selectedImages)} />
											</Box>
										</Grid>
										<Grid item sm xs={12} alignSelf="flex-end">
											<Box className="w-100" textAlign="left" style={{display:'flex', justifyContent:'space-between'}}>
						
												<span className={classes.buttonLoaderWrapper}>
													<LoadingButton
														endIcon={<SendIcon />}
														loading={submitting}
														loadingPosition="end"
														type="submit"
														variant="contained"
														color="primary"
														//size="medium"
														disableElevation
														
														fullWidth={matchesViewport ? true : false}
														onClick={(e) => {
															userReducer.isLoggedIn && userReducer.noCompany
																? Emitter.emit("SNACKBAR_NOTIFICATION", {
																		message: `${"Please add your company first!"}`,
																		params: { variant: "error" },
																  })
																: setTimeout(() => {
																		// if (props.marketData.is_auction == true) {
																		// 	return null;
																		// } else {
																			
																		// }
																  }, 1500);
														}}
													>
														{submitting ? (
															<span>Submitting ...</span>
														) : (
															<span>
																SUBMIT YOUR{" "}
																{
																// props.marketData.is_auction == true && 
																props.vesselPriceSeller?.length > 0
																	? "BID"
																	: props.tabValue == "buyer"
																	? "REQUIREMENT"
																	: "PRODUCT"}
															</span>
														)}
													</LoadingButton>
											
												</span>
												<span style={{ }}>
													{showErrors && errorMessages.length > 0 && 
														errorMessages.map(message => 
														<Typography variant="caption" sx={{ color: 'red' }}>
															{message}<br/>
														</Typography>)
													}
												</span>
											</Box>
										</Grid>
									</Grid>
							</ValidatorForm>
					
				</Grid>
		
			
			{/* Create listing confirm */}
			<Dialog
				open={open === "confirmCreateListing"}
				maxWidth="xs"
				fullWidth={true}
				scroll="paper"
				aria-labelledby="Confirm create listing"
				onClose={(e) => {
					handleClose(e);
				}}
			>
				<DialogTitle id="alert-dialog-title" className={`text-center ${classes.dialogHeader}`}>
					Please confirm
				</DialogTitle>
				<IconButton
					aria-label="close"
					className="closeBtn"
					size="small"
					onClick={(e) => {
						handleClose(e);
					}}
				>
					<CancelIcon />
				</IconButton>
				<DialogContent dividers={true}>
					<Box >
						<Typography variant="subtitle1">
							{
								props.tabValue === "buyer" ? 
								`Hi ${user.firstName}, on clicking confirm your bid will be sent to the top 
								${props.vesselPriceSeller.filter(list => list?.user?._id !== user?._id).length > 0 && props.vesselPriceSeller.length <= 5 ? props.vesselPriceSeller.length : '5'} 
								sellers` 
								: 
								`Hi ${user.firstName}, on clicking confirm your offer will be sent to the top 
								${props.vesselPriceBuyer.filter(list => list.user?._id !== user._id).length > 0 && props.vesselPriceBuyer.length <= 5 ? props.vesselPriceBuyer.length : '5'} 
								buyers` 
							}
						</Typography>
					</Box>
				</DialogContent>
				<DialogActions>
					<Box style={{ textAlign: "center", width: '100%', display: 'flex', justifyContent: 'space-around' }}>
						<ThemeButton
							color="primary"
							variant="contained"
							disableElevation
							sx={{ alignItems: 'left' }}
							onClick={(e) => {
								// console.log('clicked confirm');
								userReducer.isLoggedIn && userReducer.noCompany
									? fireCompanyNotAddedEvent()
									: 
									// props.marketData.is_auction == true &&
										props.vesselPriceBuyer != null &&
										props.vesselPriceBuyer?.length > 0 &&
										props.price > props.vesselPriceBuyer[0]?.price_value * 2
									? doublePriceEAuctionDialogSet({ show: true })
									// : props.marketData.is_auction == true
									// ? submitRfqForm()
									// ? console.log('auction case called', props.marketData)
									: isHot == false
									? submitRfqForm()
									// ? console.log('is hot case called', isHot, props)
									: automateChatDialogSet({ show: true });
							}}
						>
							Confirm
						</ThemeButton>
						<ThemeButton
							color="primary"
							variant="outlined"
							disableElevation
							onClick={(e) => {
								setOpen("");
							}}
						>
							Cancel
						</ThemeButton>
					</Box>
				</DialogActions>
			</Dialog>

			{/* Special comments dialog */}
			<Dialog
				open={open === "specialCommentsModal"}
				maxWidth="xs"
				fullWidth={true}
				scroll="paper"
				aria-labelledby="Add special comments"
				onClose={(e) => {
					handleClose(e);
				}}
			>
				<DialogTitle id="alert-dialog-title" className={`text-center ${classes.dialogHeader}`}>
					Add Special Comments
				</DialogTitle>
				<IconButton
					aria-label="close"
					className="closeBtn"
					size="small"
					onClick={(e) => {
						handleClose(e);
					}}
				>
					<CancelIcon />
				</IconButton>
				<DialogContent dividers={true}>
					<Box py={2}>
						<InputLabel className={classes.marginBottom}>Special Comments</InputLabel>
						<TextField
					
							name="comments"
							fullWidth
							multiline
							rows="5"
							value={specialRequests}
							onChange={(e) => {
								setSpecialRequests(e.target.value);
							}}
							variant="outlined"
							size="small"
							inputProps={{
								maxlength: "500"
							}}
							InputProps={{
								style: { overflowWrap: "break-word"} 
							}}
							helperText={
								<Box align="right">
									Words: {specialRequests?.length}
									{/* {globalSetting?.settings_special_comment_length} */}
									<span style={{ color: "#2264e9" }}>/500</span>
								</Box>
							}
						/>
					</Box>
					<Box style={{ textAlign: "center" }}>
						<ThemeButton
							color="primary"
							variant="contained"
							disableElevation
							onClick={(e) => {
								handleClose(e);
							}}
						>
							Done
						</ThemeButton>
					</Box>
				</DialogContent>
			</Dialog>

			{/* Create dialog for edit existing address */}
			<Dialog
				open={open === "editAddressModal"}
				scroll="paper"
				aria-labelledby="Add address"
				onClose={(e) => {
					handleClose(e);
				}}
			>
				{editAddressSuccess && (
					<>
						<DialogTitle align="center" className={`${"text-white"}`}>
							<AnimatedSuccess />
						</DialogTitle>
						<DialogContent>
							<Box sx={{ textAlign: "center" }} mb={1}>
								<Typography variant="h5">Great!!</Typography>
							</Box>
							<Box mb={2}>
								<Typography variant="subtitle1">Address has been added successfully.</Typography>
							</Box>
							<Box mb={2}>
								<ThemeButton
									style={{
										background: "#4CAF50",
										color: "#fff",
									}}
									type="button"
									onClick={(e) => {
										handleClose(e);
										setEditAddressSuccess(false);
									}}
									variant="contained"
									disableElevation
									size="small"
									fullWidth
								>
									Done
								</ThemeButton>
							</Box>
						</DialogContent>
					</>
				)}
				{!editAddressSuccess && (
					<>
						<DialogTitle align="center" id="addNewAddress">
							Add New Address
						</DialogTitle>
						<IconButton
							aria-label="close"
							className="closeBtn"
							size="small"
							onClick={(e) => {
								handleClose(e);
							}}
						>
							<CancelIcon />
						</IconButton>
						<ValidatorForm onSubmit={storeEditAddressAction} noValidate>
							<DialogContent dividers={true}>
								<EditAddress
									allCountries={allCountries}
									addAddressFormFilled={addAddressFormFilled}
									editCity={editCity}
									editState={editState}
									editPincode={editPincode}
									setEditCity={setEditCity}
									editAddress={editAddress}
									editCountry={editCountry}
									seteditCountry={seteditCountry}
									setEditState={setEditState}
									setlandMark={setlandMark}
									landMark={landMark}
									setEditAddress={setEditAddress}
									setEditPincode={setEditPincode}
									loader={props.loader}
								/>
								<Box sx={{ textAlign: "center" }} mt={2} mb={2}>
									<ThemeButton variant="contained" disableElevation color="primary" type="submit">
										Save
									</ThemeButton>
								</Box>
							</DialogContent>
						</ValidatorForm>
					</>
				)}
			</Dialog>
		</Box>
	);
}

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, { loader })(BuySell);
