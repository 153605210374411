import { Box, Button, Skeleton } from '@mui/material';
import React from 'react'
import ContentLoader from "react-content-loader";

export default function CardLoader({ size = 3, ...props}) {
    return (
        <>
            {/* <ContentLoader */}
            <div className="card-skeleton"
                speed={3}
                {...props}
                >
                {
                    Array.from({ length: size }).map((_, index) => (
                        <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 1.5 }} />
                    ))
                }
            {/* </ContentLoader> */}
            </div>
        </>
    );
}
