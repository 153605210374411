import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    InputLabel,
    Box,
    MenuItem,
    Tooltip,
    Typography
} from "@mui/material";
import { SelectValidator } from "react-material-ui-form-validator";
import LanguageTranslate from '../../partials/UnderScoreLanguage'
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    toolTipIcon: {
        fontSize: 14,
        marginLeft: 5
    },
}));

/**
 *
 * @name DeliveryTerms
 * @alias DeliveryTerms
 * @component
 * @exports DeliveryTerms
 *
 * @description
 * Payment Terms input field
 *
 */

export default function DeliveryTerms(props) {
    // console.log(props)
    const classes = useStyles();

    return (
        <Box>
           { console.log(props)}
            {/* <InputLabel className={classes.marginBottom}><LanguageTranslate s='Delivery Terms' /><sup style={{ color: "red" }}>*</sup>
           
            </InputLabel> */}
            <SelectValidator
                native="true"
                required
                label=<span style={{ fontSize: '13px'}}>Delivery Terms</span>
                variant="outlined"
                name="deliveryTerm_term_id"
                fullWidth
                size="small"
                validators={[
                    "required",
                ]}
                errorMessages={[
                    "delivery Terms field is required",
                ]}
                value={props.deliveryTerm}
                onChange={e => {
                    console.log({lolo: e.target.value})
                    if (e.target.value.hasOwnProperty("askAddress")) {
                        props.setdeliveryTerm(e.target.value);
                    //    alert('Please Enter your Location')
                    }else{
                        props.setdeliveryTerm(e.target.value);
                    }
                   
                    // props.setAdvance(e.target.value == 4 ? 10 : 0);
                    // props.setNumberOfCreditDays(e.target.value == 10 ? 0 : (e.target.value == 2 || e.target.value == 3) ? '30' : '');
                }}
            >
                {/* {(props.paymentTermId != 6 && props.paymentTerms && props.paymentTerms.length > 0) && props.paymentTerms.map((p, key) => (
                    <MenuItem
                        value={p.id}
                        key={key}
                    >
                        {p.payment_title}
                    </MenuItem>
                ))} */}
                {(props.deliverytermsArray.length > 0) && props.deliverytermsArray.map((p, key) => (
                        <MenuItem
                            value={p}
                            key={key}
                        >
                            {p.name}
                        </MenuItem>))}
               
            </SelectValidator>
        </Box>
    );
}
