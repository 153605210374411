import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { TextValidator } from "react-material-ui-form-validator";
import { storeCTAClick } from "../../../helpers"
import {
    Grid,
    InputLabel,
    Box,
    CircularProgress,
    Button
} from "@mui/material";
import { Autocomplete } from '@mui/material';
import LanguageTranslate from '../../partials/UnderScoreLanguage'
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },

    gridCol2: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: 'center',
        gridGap: '5px',
        '& .MuiButton-startIcon': {
            marginRight: '1px'
        },
        [theme.breakpoints.only("xs")]: {
            gridTemplateColumns: "1fr 200px",
        },
    },
    labelColor: {
        color: theme.palette.primary.main
    },
    fxSM: {
        [theme.breakpoints.only("xs")]: {
            fontSize: 14
        }
    }

}));

/**
 *
 * @name Vessel
 * @alias Vessel
 * @component
 * @exports Vessel
 *
 * @description
 * Vessel input field
 *
 */

export default function Vessel(props) {
    const classes = useStyles();
    return (
        <Box>
          
            {/* <InputLabel className={classes.marginBottom}>{props?.selectedContract?.clusterName?.plural
}<sup style={{ color: "red" }}>*</sup></InputLabel> */}
            <Autocomplete
                multiple
                // InputLabel='nik'
                name="vessel_name"
                size="small"
                filterSelectedOptions
                options={
                    (props.vessel_list && props.vessel_list.length > 0) ? props.vessel_list : []
                }
                onChange={(e, vessel) => {
                    if (vessel) {
                        props.setVesselInfoData(vessel);
                    }
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => <Box component="li" {...props} key={option._id}>{option.name}</Box>}
                value={props.vesselInfoData}
                renderInput={params => (
                    <TextValidator
                        {...params}
                        variant="outlined"
                       
                        label= <span style={{ fontSize: '13px'}}>{props?.selectedContract?.clusterName?.plural
                        }</span>
                        required
                        fullWidth
                        validators={[
                            "required"
                        ]}
                        errorMessages={[
                            "Vessel Name field is required"
                        ]}
                        value={props.vesselInfoData}
                    />
                )}
            />
        </Box>
    );
}
