import React, { useState, useEffect } from "react";
import { loader } from "../../redux/actions/commonActions";
import eventsTracking from "../../eventsTracking"
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Navigate, useLocation } from "react-router-dom";
import Hidden from '@mui/material/Hidden';
import { cDecrypt } from "../../helpers";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import FormA from "./FormA";
// import FormB from "./FormB";
// import FormC from "./FormC";
import ReviewCompanyForm from "./ReviewCompanyForm";
import makeStyles from '@mui/styles/makeStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import { Product } from "../../api/resources/Product";
import { User } from "../../api/resources/User";
import { Helmet } from "react-helmet";
import LanguageTranslate from '../partials/UnderScoreLanguage'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';

import {
    Grid,
    Typography,
    Container,
    Button,
    Box,
    Paper
} from "@mui/material";
import { ControlsStrategy } from "react-alice-carousel";
import { CommodityUserLogin } from "../../api/resources/CommodityUserLogin";
import { CommodityUser } from "../../api/resources/CommodityUser";
import AddCompanyWithNoGst from "./AddCompanyWithNoGst";
import { CommodityCommon } from "../../api/resources/admin-api/CommodityCommon";
import { refreshUser } from "../../redux/actions/userActions";

const useStyles = makeStyles(theme => ({
    root: {
        width: "90%",
        marginTop: theme.spacing(4),
        margin: "auto"
    },
    paperSpacing: {
        padding: theme.spacing(4),
        marginBottom: theme.spacing(3)
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    actionsContainer: {
        marginBottom: theme.spacing(2)
    },
    resetContainer: {
        padding: theme.spacing(3)
    },
    my4: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    titleAndBack: {
        display: 'grid',
        gridTemplateColumns: '120px 1fr 120px',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: '80px 1fr 80px',
        },
        '& h1': {
            [theme.breakpoints.down("sm")]: {
                fontSize: '25px',
                fontWeight: 'bold'
            }
        }
    },
    gridCol2: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: 'center',
        gridGap: '5px',
        padding: 20,
        marginLeft: 15,
        marginRight: 15
    },
}));

/**
 *
 * @name AddCompanyForm
 * @alias AddCompanyForm
 * @component
 *
 * @description
 * Add Company Main Form
 */

function AddCompanyForm(props) {
    const classes = useStyles();
    let navigate = useNavigate();
    const location = useLocation();
    let GSTINTAX = location?.search ? cDecrypt((location?.search).slice(1)) : ""
    //console.log(GSTINTAX)
    const [postSuccess, setPostSuccess] = React.useState(false);
    const user = useSelector(state => state.userReducer.user);
    const coaltypes = useSelector(state => state.commonReducer.coalTypes);
    const states = useSelector(state => state.commonReducer.states);
    const countries = useSelector(state => state.commonReducer.countries);
    const dispatch = useDispatch();
    // console.log(states,countries)
    const [submittingForm, setSubmittingForm] = React.useState(false);
    const [apiErrorResponse, setApiErrorResponse] = React.useState(null);
    const [apiValidationErrors, setApiValidationErrors] = React.useState([]);
    const [review, setReview] = React.useState(false);
    const queryString = new URLSearchParams(location.search);
    const [userIndustry, setUserIndustry] = React.useState('');
	

    // console.log(user)
    //  const[loader, isLoading] = useState(isLoading: false)
    // Handle
    const [preFIllInput, setPreFillInput] = useState({
        coaltypes: [],
        mining_companies: [],
        countries: [],
        vessel_list: [],
        coal_port: [],
        payment_terms: [],
        states: [],
        cities: [],
        industries: []
    });
    const [inputVal, setInputVal] = useState([
        {
            company_tin: GSTINTAX ? GSTINTAX : "",
            company_tan: "",
            usage_checkbox: [],
            company_name: "",
            company_url: "",
            company_email: "",
            company_number: "",
            industry_id: "",
            company_type: "",
            //company_yoe: "",
            // monthly_coal_qty: "",
            // number_of_employees: "",
            //partner_list: [getPartnerListKeys()],
            company_address: "",
            region_country: "",
            region_state: "",
            region_name: "",
            company_pincode: "",
            company_pan: "",
            company_region: "",
            pin_code: "",
            //consentAgreement: "",
            document_upload: [],
            //cities: [],
        }
    ]);
    const [companyNoGstChildStates, setCompanyNoGstChildStates] = useState({
        companyName: '',
        aadharName: null,
        address: '',
        selectedCityId: '',
        pincode: '',
      
      });
      const handleChildStates = (newStates) => {
        setCompanyNoGstChildStates(newStates);
      };
    useEffect(() => {
        // Fetch email or phone details
        let inputs = inputVal;
        if (user.companies == null) {
            inputs[0].company_email = user.email;
            inputs[0].company_number = user.contact_number;
        }
    }, [user, inputVal]);

    // useEffect(() => {
    //     fetchIndustry()
    // },[])

    const fetchIndustry = async () => {
        const res = await CommodityCommon.singleIndustry(user.industryId);
        console.log({indusRes:res})
        setUserIndustry(res.data.data.name)
      }

    /**
     * @function submitForm
     * @name submitForm
     * @description
     * Function to store the add new company details
     *
     */

    const submitForm = () => {
        // setSubmittingForm(true);
        // setApiValidationErrors([]);
        // console.log('submit',inputVal[0])
        // console.log('op',companyNoGstChildStates)
        var params = {
            name: companyNoGstChildStates.companyName?companyNoGstChildStates.companyName:inputVal[0].company_name,
            country_mobile_prefix: "+91",
            contact_number: parseInt(user.contact_number),
            // gst: companyNoGstChildStates.aadharName?companyNoGstChildStates.aadharName:inputVal[0].company_tin,
            gst: inputVal[0].company_tin ? inputVal[0]?.company_tin : '',
            adhaar: companyNoGstChildStates.aadharName ? companyNoGstChildStates.aadharName : '',
            pan: inputVal[0].company_pan ? inputVal[0].company_pan : '' ,
            address: {
                "line1": companyNoGstChildStates.address?companyNoGstChildStates.address:inputVal[0].company_address,
                "cityId": companyNoGstChildStates.selectedCityId?companyNoGstChildStates.selectedCityId:inputVal[0].region_name,
                "pincode": companyNoGstChildStates.pincode?companyNoGstChildStates.pincode:inputVal[0].company_pincode,
                "addressableType": "COMPANIES"
            },
            // industry_id: '64885ed4108b422b30f98ceb'
            // industry_id: user.industryId ? user.industryId : '659d7afdfd7fa17f77089532'
            industry_id: user?.industryId
        };
// console.log (params)
        props.loader(true);
        CommodityUser.addCompnay(params)
        .then(response => {
                if(response.data.statusCode == 201)
                {
                    setPostSuccess(true);
                    dispatch(refreshUser());
                }
                // if (response.data.status === "success") {
                //     //console.group(response.data)
                //     ReactGA.event({ category: 'User', action: 'User added new company successfully' });
                //     setPostSuccess(true);
                // }
                // if (response.data.status === "fail") {
                //     ReactGA.event({ category: 'User', action: 'User failed adding new company with api error' });
                //     setApiErrorResponse(response.data.message);
                //     if (
                //         response.data.validation_errors &&
                //         response.data.errors
                //     ) {
                //         setApiValidationErrors(response.data.errors);
                //     }
                // }
            })
            .finally(() => {
                props.loader(false);
                setSubmittingForm(false);
            });
    };

    const updateValues = (inputs, index) => {
        inputVal[index] = inputs;
        setInputVal(inputVal);
    };

    if (postSuccess === true) {
        // return <Navigate to="/company-added" />;
        return <Navigate to="/markets" />;
    }
    //  haveGST=localStorage.getItem('haveGST');
//     React.useState(()=>{
// let gstCheck=localStorage.getItem('haveGST')
//     },[])
    return (
        <>
      
        {localStorage.getItem('haveGST')==='Yes'?   <Box className={`${classes.newsletter} ${classes.mt4}`}>
           
           <Helmet>
               <title>Add Company</title>
               <meta name="description" content="" />
           </Helmet>
           <Container component="main" maxWidth="md">
               <Grid item sm={12}>
                   <Box className={classes.titleAndBack}>
                       <Box>
                           {user && user.companies && user.companies.length > 0 &&
                               <IconButton
                                   aria-label="back"
                                   size="small"
                                   onClick={e => { e.preventDefault(); navigate(-1); }}
                               >
                                   <KeyboardBackspaceIcon />
                               </IconButton>
                           }
                       </Box>
                       <Box my={4}>
                           <Typography component="h1" variant="h5" align="center">
                               <strong>{review ? 'Review Company Details' : 'Add Your Company'}</strong> <br />
                               <Box sx={{ color: '#6A6A6A', py: .5, fontSize: '16px', mb: 2, mt: 1 }}>Add your GSTIN / Tax ID for verification to get complete access <br /> to live commodity prices and start buying/selling</Box>
                           </Typography>
                       </Box>

                   </Box>
                   <Container component="main" maxWidth={review ? "md" : "xs"}>
                       <ValidatorForm onSubmit={e => {  setReview(true) }} onError={e => { setReview(false); ReactGA.event({ category: 'User', action: 'User failed adding new company with validations errors' }); }} noValidate>
                           <Box style={{ display: review == true ? 'none' : 'block' }}>
                               <FormA
                                   values={inputVal[0]}
                                   states={states}
                                   industries={preFIllInput.industries}
                                //    coaltypes={coaltypes}
                                   user={user}
                                   phoneCode={user?.phoneCode}
                                   navigate={navigate}
                                   countries={countries}
                                   validationErrors={apiValidationErrors}
                                   onValuesChange={inputs =>
                                       updateValues(inputs, 0)
                                   }
                                   onAddress={inputs => {
                                       inputVal[0] = inputs;
                                       setInputVal(inputVal);
                                   }}
                                   onSubmit={e =>setReview(true)}
                               />
                           </Box>

                           {review &&
                               <ReviewCompanyForm
                                   preFIllInput={preFIllInput}
                                   coaltypes={coaltypes}
                                   states={states}
                                   countries={countries}
                                   values={inputVal}
                                   onValuesChange={inputs =>
                                       updateValues(inputs, 0)
                                   }
                                   phoneCode={user?.phoneCode}
                                   onSubmit={submitForm}
                                   submittingForm={submittingForm}
                                   apiErrorResponse={apiErrorResponse}
                                   review={review}
                                   onBack={e => setReview(false)}
                               />
                           }

                       </ValidatorForm>
                       <Box textAlign="center" p={2} style={{ textDecoration: 'underline', color: '#2959BA' }}>
                           <Button variant="text" color="primary" >Need Help?</Button>
                       </Box>

                       <Box className={classes.gridCol2} textAlign="center">
                           <Box sx={{ color: '#6A6A6A', fontSize: '14px', mb: 1 }}>
                               <img src="/images/reliable-deal_icon.svg" alt="secure" width='20' /><br />
                               Reliable Deals</Box>
                           <Box sx={{ color: '#6A6A6A', fontSize: '14px', mb: 1 }}>
                               <img src="/images/Secure.svg" alt="secure" width='20' /><br />
                               100% secure</Box>
                       </Box>
                   </Container>
               </Grid>
           </Container>
       </Box>: <Box>
       <AddCompanyWithNoGst sendStatesToParent={(newChildState)=>{
        setCompanyNoGstChildStates(newChildState)
       }} companyNoGstChildStates={companyNoGstChildStates} submitForm={submitForm} 
       user={user}
       fetchIndustry = {fetchIndustry}
       userIndustry = {userIndustry}
       />
        </Box>}
        </>
     
    );
}

const mapStateToProps = state => {
    return {
        loader: state.commonReducer.loader
    };
};

export default connect(mapStateToProps, { loader })(AddCompanyForm);
