import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    InputAdornment,
    Box,
    IconButton,
    InputLabel
} from "@mui/material";
import LanguageTranslate from '../../partials/UnderScoreLanguage'
import { TextValidator } from "react-material-ui-form-validator";
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles(theme => ({
    background: {
        background: '#F2F3F5',
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
}));

/**
 *
 * @name Address
 * @alias Address
 * @component
 * @exports Address
 *
 * @description
 * All address details added by user like company address, Gst address , Port address and other addresses
 *
 */

export default function Address(props) {
    console.log(props)
    const classes = useStyles();

    return (
        <Box>
            <Box style={{ position: 'relative' }}>
                {/* <InputLabel className={classes.marginBottom}><LanguageTranslate s='Address' /><sup style={{ color: "red" }}>*</sup></InputLabel> */}
                <TextValidator
                    className={classes.background}
                    variant="outlined"
                    label="Address"
                    disabled
                    fullWidth
                    name="address"
                    value={props.tempAddresses[0].full}
                    // validators={["required"]}
                    // errorMessages={[
                    //     "Address field is required",
                    // ]}
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    color="primary"
                                    disabled={props.vesselType == "1"}
                                    onClick={e => { props.onEditAddressDialog(e) }}
                                    edge="end"
                                >
                                    <EditIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
        </Box>
    );
}
