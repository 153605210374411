import React, { useEffect } from "react";
// import { makeStyles } from "@mui/material/styles";
import { Grid, Box, MenuItem,Autocomplete, TextField, Typography } from "@mui/material";
import { validatorRegEx } from "../../../helpers";
import SearchIcon from '@mui/icons-material/Search';
import {
    // ValidatorForm,
    TextValidator,
    SelectValidator
} from "react-material-ui-form-validator";
import { Product } from '../../../api/resources/Product'
import LanguageTranslate from '../../partials/UnderScoreLanguage'
import { CommodityCommon } from "../../../api/resources/admin-api/CommodityCommon";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Button } from "react-scroll";

// const useStyles = makeStyles(theme => ({
//     white: {
//         color: theme.palette.common.white
//     }
// }));

/**
 *
 * @name EditAddress
 * @alias EditAddress
 * @component
 * @exports EditAddress
 *
 * @description
 * Dialog to edit existing address in rfq/listing form
 *
 */

export default function EditAddress(props) {
    // const classes = useStyles();
    const [cities, setCities] = React.useState([]);
    const [states, setStates] = React.useState([]);
    const [searchCountryTerm, setCountrySearchTerm] = React.useState('');
    const filteredCountry=searchCountryTerm?props.allCountries.filter((country) => country.name.toLowerCase().includes(searchCountryTerm.toLowerCase())):props.allCountries

    const previousEditCountry = React.useRef();
    const previousEditState = React.useRef();

    const getState =async () => {
		var param = Object.assign({
            country_id: props.editCountry.id,
        }, param)

		try {
			// props.loader(true);
		// console.log('set state params', param)
			const states = await CommodityCommon.getALLStates(param);
			if (states.status==200) {
			
				 setStates(states.data.data);
				
			} else {
				
				setStates([]);
			}

			// props.loader(false);
		} catch (error) {
			console.error(error);
		}
		// props.loader(false);
	};
	const getCity = async (param) => {
        
		var param = Object.assign({
            state_id: props.editState.id,

        }, param)
		try {
			// props.loader(true);
		
			const cities = await CommodityCommon.getAllCity(param);
			if (cities.status==200) {
		
			setCities(cities.data.data);
				
			} else {
				
				setCities([]);
			}

			// props.loader(false);
		} catch (error) {
			console.error(error);
		}
		// props.loader(false);
	};

    useEffect(() => {
        if (props.editState && props.editState !== previousEditState.current
        ) {
            getCity();
            previousEditState.current = props.editState;
        }
        
    }, [props.editState]);
    useEffect(() => {
        if (props.editCountry && props.editCountry !== previousEditCountry.current) {
            // console.log('set state use effect')
            getState();
            previousEditCountry.current = props.editCountry;
        }
    }, [props.editCountry]);

    return (
        <Box >
            <Grid container spacing={2} >
                <Grid item sm={12} xs={12}>
                    <TextValidator
                        label={<LanguageTranslate s='Address' />}
                        name="editAddress"
                        fullWidth
                        multiline
                        rows="4"
                        value={props.editAddress}
                        onChange={e => { props.setEditAddress(e.target.value) }}
                        variant="outlined"
                        required
                        size="small"
                        validators={["required"]}
                        errorMessages={["Address field is required"]}
                    />
                </Grid>
                {/* landmark not required right now */}
                {/* <Grid item sm={12} xs={12}>
                    <TextValidator
                        label={<LanguageTranslate s='Landmark' />}
                        name="landmark"
                        fullWidth
                        rows="1"
                        value={props.landMark}
                        onChange={e => { props.setlandMark(e.target.value) }}
                        variant="outlined"
                        required
                        size="small"
                        validators={["required"]}
                        errorMessages={["landMark field is required"]}
                    />
                </Grid> */}
             {/* <button onClick={()=>console.log(props.editCountry)}>click</button> */}
             <Grid item sm xs={12}>
             <Autocomplete
            id="tags-outlined"
            options={props.allCountries}
            getOptionLabel={(option) => option.name}
            value={props.editCountry}
            label='Country'
            size="small"
            onChange={(event, newValue) => {
                props.seteditCountry(newValue);
                props.setEditState(null);
            }}
            renderInput={(params) => (
                <TextValidator
                    {...params}
                    label="Country"
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                    placeholder="Search by Country"
                    validators={props.editCountry ? [] : ['required']}
            errorMessages={props.editCountry ? [] : ['Country is required']}

                ><TextField
                fullWidth
                placeholder="Search by Country"
            /></TextValidator>
            )}
        />
  
                </Grid>
                <Grid item sm xs={12}>
                <Autocomplete
                         
                            id="tags-outlined"
                            // limitTags={1}
                            // style={{padding: '4px'}}
                           
                            options={states}
                          
                            getOptionLabel={(option) => option.name}
                            value={props.editState}
                       
                            size="small"
                            onChange={(event, newValue) => { props.setEditState(newValue); props.setEditCity(null) }}
                            renderInput={(params) => (
                                <TextValidator
                                {...params}
                                label="State"
                                variant="outlined"
                                fullWidth
                                size="small"
                                required
                                placeholder="Search by State"
                                validators={props.editState ? [] : ['required']}
                                errorMessages={props.editState ? [] : ['Country is required']}
            
                            ><TextField
                            fullWidth
                            placeholder="Search by State"
                        /></TextValidator>
                                
                            )}
                        />
 
                </Grid>
                {/* <Grid item sm xs={12}>
                    <SelectValidator
                        native="true"
                        required
                        variant="outlined"
                        label={<LanguageTranslate s='State' />}
                        name="state"
                        fullWidth
                        validators={["required"]}
                        errorMessages={["State field is required"]}
                        size="small"
                        value={props.editState}
                        onChange={e => { props.setEditState(e.target.value); props.setEditCity("") }}
                    >
                        {(states && states.length > 0) && states.map((state, key) => (
                            <MenuItem
                                value={state}
                                key={key}
                            >
                                {state.name}
                            </MenuItem>
                        ))}
                    </SelectValidator>
                </Grid> */}
                         <Grid item sm xs={12}>
                <Autocomplete
                         
                            id="tags-outlined"
                            // limitTags={1}
                            // style={{padding: '4px'}}
                            
                            options={cities}
                            getOptionLabel={(option) => option.name}
                            value={props.editCity}
                           required
                            size="small"
                            onChange={(event, newValue) => { props.setEditCity(newValue)}}
                            renderInput={(params) => (
                                <TextValidator
                    {...params}
                    label="City"
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                    placeholder="Search by City"
                    validators={props.editCity ? [] : ['required']}
                    errorMessages={props.editCity ? [] : ['Country is required']}

                ><TextField
                fullWidth
                placeholder="Search by City"
            /></TextValidator>
                            )}
                        />
                </Grid>
                {/* <Grid item sm xs={12}>
                    <SelectValidator
                        native="true"
                        required
                        variant="outlined"
                        label={<LanguageTranslate s='City' />}
                        name="city"
                        fullWidth
                        validators={["required"]}
                        errorMessages={["City field is required"]}
                        size="small"
                        value={props.editCity}
                        onChange={e => { props.setEditCity(e.target.value) }}
                    >
                        {(cities && cities.length > 0) && cities.map((city, key) => (
                            <MenuItem
                                value={city}
                                key={key}
                            >
                                {city.name}
                            </MenuItem>
                        ))}
                    </SelectValidator>
                </Grid> */}
                <Grid item sm xs={12}>
                    <TextValidator
                        label={<LanguageTranslate s='Pin Code' />}
                        required
                        name="pincode"
                        fullWidth
                        value={props.editPincode}
                        onChange={e => { props.setEditPincode(e.target.value) }}
                        variant="outlined"
                        size="small"
                        validators={[
                            `matchRegexp:${validatorRegEx("pin_code")}`,
                            "required"
                        ]}
                        errorMessages={[
                            'Must be an Integer',
                            "Pincode field is required"
                        ]}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
