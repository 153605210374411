import resource from 'resource-axios';
import axios from '../baseCommodity';
import { config } from '../../config';
import { makeQueryString } from '../../helpers'


export const CommodityProduct = resource(config.common_api_commodity_url, {

    myDealsv2: (params) => {
		var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return axios.get(`${config.common_api_commodity_url}/user/deals?${queryString}`)
    },

	virtualOffice: {
		myListings: (params) => {
			var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
			return axios.get(`${config.common_api_commodity_url}/user/listings?${queryString}`)
		},
		inboxChatList: (params) => {
			var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
			return axios.get(`${config.common_api_commodity_url}/user/communications/inbox?${queryString}`)
		},
	},

	viewProductDetails: (params) => {
		// var queryString = ''
        // if(params)
        // {
        //     queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        // }
		return axios.get(`${config.common_api_commodity_url}/user/listings?search[_id]=`,params)
	},

    fetchListingBySlug: (slug) => {
		return axios.get(`${config.common_api_commodity_url}/user/listings?search[slug]=${slug}`)
	},
	showUserListingWithoutAuth:(slug)=>{
		return axios.get(`${config.common_api_commodity_url}/listings/${slug}`)
	},
	updateUserProfile: (params) => {
		return axios.put(`${config.common_api_commodity_url}/user/profile/update-user`, params);
	},

}, axios);
