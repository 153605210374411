import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    Box,
    InputLabel,
    InputAdornment
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { validatorRegEx } from "../../../helpers";

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    backgroundWhite: {
        backgroundColor: 'white'
    },
}));

/**
 *
 * @name NumberOfCreditDays
 * @alias NumberOfCreditDays
 * @component
 * @exports NumberOfCreditDays
 *
 * @description
 * Number of credit days input field
 *
 */

export default function Paymentvariable(props) {
 
    const classes = useStyles();
    return (
        <Box>
            {/* <InputLabel className={`${classes.marginBottom}`}> {props.varName}<sup style={{ color: "red" }}>*</sup>
            </InputLabel> */}
            <TextValidator
                className={classes.backgroundWhite}
                variant="outlined"
                type="number"
                fullWidth
                required
                label=     <span style={{ fontSize: '13px'}}>{props.varName}</span>
           
                name="no_od_credit_days"
                value={props.varDefaultValue}
                onChange={e => { props.setvarDefaultValue(e.target.value) }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                        >
                            {props.varName}
                        </InputAdornment>
                    )
                }}
                validators={["required", "isNumber",   `minNumber:${0}`]}
                errorMessages={[`No. of ${props.varName}  is required.", "Only numeric values accepted.`, `${props.varName} can not be Negative !`]}
                size="small"
            />
        </Box>
    );
}
