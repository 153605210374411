import React, { useState, useEffect } from "react";
import { socket, socketAdmin } from '../../socket';
import Emitter from '../../services/emitter';
import eventsTracking from '../../eventsTracking';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router"
import { cEncrypt, fireBidOfferSentEvent } from "../../helpers";
import { Link } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import MinimizeIcon from '@mui/icons-material/Minimize';
import ThemeButton from "../partials/ThemeButton";
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReactGA from "react-ga4";
import FeedbackIcon from '@mui/icons-material/Feedback';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { User } from "../../api/resources/User";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import makeStyles from '@mui/styles/makeStyles';
import { Product } from "../../api/resources/Product";
import AcceptRejectCounterOptionsAndMessage from './chat/AcceptRejectCounterOptionsAndMessage'
import OfferAndBidMessage from './chat/OfferAndBidMessage'
import RePostListingModal from "../virtualOffice/partials/RePostListingModal";
import dateformat from "dateformat";
import {
    Slide, Grid,

    Paper,


    Dialog,
    DialogTitle,


    DialogContent,
    DialogActions,

    Rating,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    LinearProgress,
    Divider,
    DialogContentText,
} from '@mui/material';
import ReferencePopUp from "./chat/ReferencePopUp";
import CurrencyValidationDialog from "./chat/CurrencyValidationDialog";
import MutualContactsDialog from "../partials/MutualContactsDialog";
import DealRejectionForm from "./chat/DealRejectionForm";
import { CommodityCommon } from "../../api/resources/admin-api/CommodityCommon";
import { CommodityUser } from "../../api/resources/CommodityUser";
import Flag from "./Flag";
import { ListingThumb } from "../commodity-market-page/MarketPrices";
import SpecialRequest from "../commodity-market-page/partials/SpecialRequest";
import ImageGalleryBox from "../ImageGalleryBox";
import { ConsoleCommodity } from "../../api/resources/admin-api/ConsoleCommodity";
import ExpandMore from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(theme => ({
    chatHeader: {
        background: theme.palette.common.white,
        padding: theme.spacing(1.5),
    },
    chatInfo: {
        backgroundColor: theme.palette.primary.main
    },
    chatInfoWhite: {
        backgroundColor: theme.palette.common.white
    },
    gridCol2Icon: {
        display: "grid",
        gridGap: '5px',
        gridTemplateColumns: '18px auto',
        '& img': {
            width: 12
        }
    },
    gridCol2Button: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: "center",
    },
    gridCol2IconStatic: {
        // display: "grid",
        // gridGap: '5px',
        //gridTemplateColumns: '12px auto',
        alignItems: 'center',
        '& img': {
            width: 12
        }
    },
    gridCol2Static: {
        display: "grid",
        gridTemplateColumns: "1.5fr .5fr",
        alignItems: "center",
        [theme.breakpoints.only("xs")]: {
            gridTemplateColumns: "1fr",
        },
    },
    inputRadio: {
        padding: theme.spacing(.7),
        paddingBottom: theme.spacing(.7),
        paddingRight: theme.spacing(2),
        textAlign: 'left',
        marginBottom: theme.spacing(1),
        '&.MuiFormControlLabel-root': {
            marginLeft: theme.spacing(0),

        },
        '& .MuiButtonBase-root': {
            padding: 5
        }
    },
    inputBG: {
        background: '#eef2f5',
    },
    chatBody__smDown: {
        // maxHeight: '20rem;',
        [theme.breakpoints.only("xs")]: {
            maxHeight: '100%',
            paddingBottom: '180px'
        }
    },
    chatModal: {
        background: '#eef2f5',
        fontSize: '14px',
        //width: '360px',
        //position: 'fixed',
        //right: '35px',
        maxHeight: '700px',
        //overflowY: 'auto',
        bottom: 0,
        zIndex: 1200,
        boxShadow: '-1px 0 5px #55555569'
    },
}));

const closeModalBtnStyle = {
    position: "absolute",
    top: 8,
    right: 4,
    backgroundColor: "white"
}

function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const SentMessageBubble = ({ children, chat, isSystem, communicationData }) => {

    return <div className="chat-thread-response" style={isSystem == true 
        ? { background: 'linear-gradient(0deg, #E0E0E0, #E0E0E0), #FFFFFF', margin: 'auto', clear: 'both', float: 'none', marginBottom: '12px' } 
        : communicationData?.buyerUserId == chat?.senderUserId ? { background: 'linear-gradient(0deg, rgba(43, 255, 0, 0.3), rgba(78, 159, 61, 0.3)), #FFFFFF' } 
        : { background: 'linear-gradient(0deg, rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.2)), #FFFFFF' }}>{children}</div>
}

const ReceivedMessageBubble = ({ children, chat, isSystem, communicationData }) => {
    // console.log(isSystem)
    return <div className="chat-thread-initiated" style={isSystem == true 
        ? { background: 'linear-gradient(0deg, #E0E0E0, #E0E0E0), #FFFFFF', margin: 'auto', clear: 'both', float: 'none', marginBottom: '12px' } 
        : communicationData?.buyerUserId == chat?.senderUserId ? { background: 'linear-gradient(0deg, rgba(78, 159, 61, 0.3), rgba(78, 159, 61, 0.3)), #FFFFFF' } 
        : { background: 'linear-gradient(0deg, rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.2)), #FFFFFF' }}>{children}</div>
}

const MessageBubble = ({ children, chat, user, isSystem, communicationData }) => {

    if (chat.receiverCompanyId == user?.activeCompanyId) {
        return <SentMessageBubble communicationData={communicationData} chat={chat} >{children} </SentMessageBubble>
    }
    return <ReceivedMessageBubble communicationData={communicationData} chat={chat} isSystem={isSystem}>{children}</ReceivedMessageBubble>
}

const NextActionOptions = ({ bid, offer, onSubmit }) => {
    const [action, actionSet] = React.useState(null)
    const classes = useStyles();
    return <FormControl component="fieldset" color="primary">
        <Box mb={1}>Are you interested in this {bid && 'bid'}{offer && 'offer'}</Box>
        <RadioGroup aria-label="action" value={action} name="action" onChange={e => { actionSet(e.target.value); onSubmit({ action: e.target.value }) }} style={{ display: 'block' }}>
            <FormControlLabel className={`${classes.inputRadio} ${classes.inputBG} w-100`} value={4} control={<Radio size="small" color="primary" />} label="Accept" />
            <FormControlLabel className={`${classes.inputRadio} ${classes.inputBG} w-100`} value='5' control={<Radio size="small" color="primary" />} label={`${action == '5' ? 'Reject due to' : 'Reject'}`} />
            {bid && <FormControlLabel className={`${classes.inputRadio} ${classes.inputBG} w-100`} value='2' control={<Radio size="small" color="primary" />} label="Counter Offer" />}
            {offer && <FormControlLabel className={`${classes.inputRadio} ${classes.inputBG} w-100`} value='1' control={<Radio size="small" color="primary" />} label="Counter Bid" />}
        </RadioGroup>
    </FormControl>
}


const NextActionMessageBubbles = ({ contractData, chatCount, product, price, payment_term_id, cc_percentage, days, currency, productCompanyId, product_type, user, lastMessage, firstMessage, onSubmit, sendingMessage, sendingMessageSet, submittedNextAction, minimumOrderQuantity, quantityTickSize, quantityRangeMax, quantityRangeMin, priceTickSize, listing, creditDays, userReferenceList, userReferenceListSet, specialRequest, paymentDays, lastMessageAction, brandStore, expiryValue,
    setexpiryValue, chats, rejectreasons, deliverytermsArray, contract, quanityt, counterSettlementFields, setCounterSettlementFields, consoleUser, settlementUnits, userType, commData }) => {
    // console.log('nextAction', counterSettlementFields)
    // 1 => Bid, 2 => Offer, 3 => Accept, 4 => Reject, 5 => Close, 6 => Withdraw, 7 => Award, 8 => View quote

    const [action, actionSet] = React.useState('');
    const [reOffer, reOfferSet] = React.useState(false);
    const [reBid, reBidSet] = React.useState(false);

    React.useEffect(() => {

        if (submittedNextAction == true) {
            actionSet('')
        }
    }, [submittedNextAction])

    
    
    if(window.location.pathname.includes('console/admin')) {
            if (chatCount == 1 && lastMessage.senderUserId == consoleUser?._id) {
                return <></>
            }
            if (
                // bid condition 
        
                // (product_type == 1 && productCompanyId == user.company_id) ||
                // (product_type == 2 && productCompanyId != user.company_id)
                lastMessage.action == 1 && 
                lastMessage.senderUserId != consoleUser._id
                // lastMessage.senderUserId == consoleUser._id // condition to check for buyer was incorrect
            ) {
                // Current user is buyer
                return <>
        {/* <Button onClick={()=>console.log(lastMessage)}>lastMes</Button> */}
        {/* <Button onClick={()=>console.log(consoleUser)}>consoleUser</Button> */}
        {/* <Button onClick={()=>console.log(commData)}>commData</Button> */}
                    {lastMessage.action == 1 && contractData &&
                        <SentMessageBubble>
                            <NextActionOptions
                                bid={true}
                                onSubmit={e => actionSet(e.action)}
                                productCompanyId={productCompanyId}
                                product={product}
                                user={consoleUser}
                                product_type={product_type}
                                lastMessage={lastMessage} />
                        </SentMessageBubble>
                    }
                    {((action == 1 || action == 2 || action == 5 || action == 4)) &&
                        <SentMessageBubble isSystem={true}>
                            <AcceptRejectCounterOptionsAndMessage
                            source={'any action acceptRejectCounter buyer admin'}
                            settlementUnits={settlementUnits}
                                counterSettlementFields={counterSettlementFields}
                                setCounterSettlementFields={setCounterSettlementFields}
                                contractData={contractData}
                                bid={true}
                                action={action}
                                productCompanyId={productCompanyId}
                                product_type={product_type}
                                product={product}
                                brandStore={brandStore}
                                lastMessage={lastMessage}
                                price={price}
                                paymentTermId={lastMessage?.paymentTerm?._id}
                                ccPercentage={''}
                                liftingDays={lastMessage.liftingDays}
                                quantity={lastMessage.quantity}
                                currency={currency}
                                minimumOrderQuantity={minimumOrderQuantity}
                                quantityTickSize={quantityTickSize}
                                quantityRangeMax={quantityRangeMax}
                                quantityRangeMin={quantityRangeMin}
                                priceTickSize={priceTickSize}
                                creditDays={''}
                                specialRequest={specialRequest}
                                sendingMessageSet={e => sendingMessageSet(e)}
                                sendingMessage={sendingMessage}
                                userReferenceListSet={e => userReferenceListSet(e)}
                                userReferenceList={userReferenceList}
                                rejectreasons={rejectreasons}
                                expiryValue={expiryValue}
                                setexpiryValue={setexpiryValue}
                                deliverytermsArray={deliverytermsArray}
                                contract={contract}
                                onSubmit={e => {
                                    if (action == 5) {
                                        onSubmit({ action: action, message: e.message, reason_ids: e.reasons, data: e.data, autoQuote: e.autoQuote })
                                    }
                                    else {
                                        onSubmit({ action: action, message: e.message, data: e.data, autoQuote: e.autoQuote, communicationId: chats[0].communicationId })
                                    }
                                }} />
                        </SentMessageBubble>
                    }
                    {(lastMessage.action == 5 || lastMessage.action == 6 || lastMessage.action == 3 || (lastMessage.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.createdAt, "yyyy-mm-dd HH:MM:ss"))) &&
                        <>
                            {(
                                ((lastMessage.action == 5) || lastMessage.action == 6 ||
                                    (lastMessage.action == 3) || (lastMessage.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.createdAt, "yyyy-mm-dd HH:MM:ss")))) &&
                                    <>
                                    {/* <h1>buyer expireed case</h1> */}
                                <SentMessageBubble>
                                    {((lastMessage.action == 5 || lastMessage.action == 6 || lastMessage.action == 3) && action != 1) &&
                                        <Box textAlign="center" >
                                            <Button size="small" variant="contained" color="primary" onClick={e => { actionSet(1); reBidSet(true); }}>
                                                SEND YOUR BID
                                            </Button>
                                        </Box>
                                    }
                                    {(action == 1 && contractData) &&
                                        <OfferAndBidMessage
                                            contractClusterIds={lastMessage?.contractClusterIds || []}
                                            source={'action 1 and contract data admin'}
                                            contractData={contractData}
                                            productCompanyId={productCompanyId}
                                            product={product}
                                            product_type={product_type}
                                            price={price}
                                            paymentTermId={payment_term_id}
                                            ccPercentage={cc_percentage}
                                            liftingDays={days}
                                            reBid={reBid}
                                            brandStore={brandStore}
                                            currency={currency}
                                            creditDays={lastMessageAction?.message_log?.data?.no_credit_days ? lastMessageAction?.message_log?.data?.no_credit_days : creditDays}
                                            paymentDays={lastMessageAction?.message_log?.data?.payment_days ? lastMessageAction?.message_log?.data?.payment_days : paymentDays}
                                            quantity={quanityt}
                                            minimumOrderQuantity={minimumOrderQuantity}
                                            quantityTickSize={quantityTickSize}
                                            quantityRangeMax={quantityRangeMax}
                                            quantityRangeMin={quantityRangeMin}
                                            priceTickSize={priceTickSize}
                                            expiryValue={expiryValue}
                                            setexpiryValue={setexpiryValue}
                                            sendingMessage={sendingMessage}
                                            sendingMessageSet={e => sendingMessageSet(e)} userReferenceListSet={e => userReferenceListSet(e)} userReferenceList={userReferenceList}
                                            specialRequest={specialRequest}
                                            deliverytermsArray={deliverytermsArray}
                                            contract={contract}
        
                                            onSubmit={e => onSubmit({ message: e.message, action: action, data: e.data })}
                                        />
                                    }
                                </SentMessageBubble>
                                </>
                            }
                        </>
                    }
                </>
            }
            else {
        
                // Current user is seller
        
                //  offer condition
                // <Button onClick={()=>console.log(lastMessage)}>lastMesElse</Button>
                if (lastMessage.action == 2 && contractData && lastMessage.senderUserId != consoleUser._id) {
                    // console.log('admin console seller condition')
                    return <>
                    {/* <Button onClick={()=>console.log(lastMessage)}>lastMessage</Button> */}
                        {/* <Button onClick={()=>console.log(commData)}>commData</Button> */}
                        <SentMessageBubble>
                            <NextActionOptions
                                offer={true}
                                onSubmit={e => actionSet(e.action)}
                                productCompanyId={productCompanyId}
                                product={product}
                                user={consoleUser}
                                product_type={product_type}
                                lastMessage={lastMessage} />
                        </SentMessageBubble>
                        {(action == 1 || action == 2 || action == 5 || action == 4) &&
                            <SentMessageBubble isSystem={true}>
                                <AcceptRejectCounterOptionsAndMessage
                                source={'any action acceptRejectCounter seller admin'}
                                settlementUnits={settlementUnits}
                                    counterSettlementFields={counterSettlementFields}
                                    setCounterSettlementFields={setCounterSettlementFields}
                                    contractData={contractData}
                                    offer={true}
                                    action={action}
                                    productCompanyId={productCompanyId}
                                    product_type={product_type}
                                    product={product}
                                    brandStore={brandStore}
                                    lastMessage={lastMessage}
                                    // price={lastMessage.price}
                                    price={price}
                                    paymentTermId={lastMessage?.paymentTerm?._id}
                                    ccPercentage={''}
                                    liftingDays={lastMessage.liftingDays}
                                    quantity={lastMessage.quantity}
                                    currency={currency}
                                    minimumOrderQuantity={minimumOrderQuantity}
                                    quantityTickSize={quantityTickSize}
                                    quantityRangeMax={quantityRangeMax}
                                    quantityRangeMin={quantityRangeMin}
                                    priceTickSize={priceTickSize}
                                    creditDays={''}
                                    specialRequest={specialRequest}
                                    sendingMessageSet={e => sendingMessageSet(e)}
                                    sendingMessage={sendingMessage}
                                    userReferenceListSet={e => userReferenceListSet(e)}
                                    userReferenceList={userReferenceList}
                                    rejectreasons={rejectreasons}
                                    expiryValue={expiryValue}
                                    setexpiryValue={setexpiryValue}
                                    deliverytermsArray={deliverytermsArray}
                                    contract={contract}
                                    onSubmit={e => {
                                        if (action == 5) {
                                            onSubmit({ action: action, message: e.message, reason_ids: e.reasons, data: e.data, autoQuote: e.autoQuote })
                                        }
                                        else {
                                            onSubmit({ action: action, message: e.message, data: e.data, autoQuote: e.autoQuote, communicationId: chats[0].communicationId })
                                        }
                                    }} />
                            </SentMessageBubble>
                        }
                    </>
                }
                if (
                    (lastMessage.action == 5 || lastMessage.action == 6 || lastMessage.action == 3 || 
                    (lastMessage.action == 0 && 
                        dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.createdAt, "yyyy-mm-dd HH:MM:ss")
                    ))
                ) {
                    if ((lastMessage.action == 5) || lastMessage.action == 6 ||
                        (lastMessage.action == 3) || (lastMessage.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.createdAt, "yyyy-mm-dd HH:MM:ss"))) {
                        return <SentMessageBubble>
                            {((lastMessage.action == 5 || lastMessage.action == 6 || lastMessage.action == 3) && action != 2) &&
                                <Box textAlign="center">
                                    <Button size="small" variant="contained" color="primary" onClick={e => { actionSet(2); reOfferSet(true); }}>
                                        SEND YOUR OFFER
                                    </Button>
                                </Box>
                            }
                            {(action == 2 && contractData) &&
                                <OfferAndBidMessage
                                contractClusterIds={lastMessage?.contractClusterIds || []}
                                source={'action 2 and contract data admin'}
                                    sendingMessage={sendingMessage}
                                    sendingMessageSet={e => sendingMessageSet(e)}
                                    userReferenceListSet={e => userReferenceListSet(e)}
                                    userReferenceList={''}
                                    productCompanyId={productCompanyId}
                                    product={product}
                                    product_type={product_type}
                                    price={price}
                                    brandStore={''}
                                    paymentTermId={''}
                                    ccPercentage={''}
                                    liftingDays={days}
                                    reOffer={reOffer}
                                    currency={currency}
                                    quantity={quanityt}
                                    minimumOrderQuantity={minimumOrderQuantity}
                                    quantityTickSize={quantityTickSize}
                                    quantityRangeMax={quantityRangeMax}
                                    quantityRangeMin={quantityRangeMin}
                                    priceTickSize={priceTickSize}
                                    creditDays={''}
                                    paymentDays={''}
                                    specialRequest={''}
                                    expiryValue={expiryValue}
                                    setexpiryValue={setexpiryValue}
                                    deliverytermsArray={deliverytermsArray}
                                    contract={contract}
                                    contractData={contractData}
                                    onSubmit={e => onSubmit({ message: e.message, data: e.data, action: action })}
                                />
        
                            }
                        </SentMessageBubble>
                    }
                }
                return <></>
            }
    } else {
        //  =========================== user side chat render =================================
        if (chatCount == 1 && lastMessage.company_id == user?.activeCompanyId) {
            return <></>
        }
        if (
            // bid condition 
    
            // (product_type == 1 && productCompanyId == user.company_id) ||
            // (product_type == 2 && productCompanyId != user.company_id)
            // lastMessage.action == 1 && lastMessage.senderCompanyId != user?.activeCompanyId
            lastMessage.action == 1 && 
            // lastMessage.senderCompanyId == user?.activeCompanyId
            lastMessage.senderCompanyId != user?.activeCompanyId
        ) {
            // console.log('user sender != logged in user ', lastMessage)
            // console.log('contractData', contractData)
            // console.log({action})
            // Current user is buyer
            return <>
                {lastMessage.action == 1 && contractData &&
                    <SentMessageBubble>
                        <NextActionOptions
                            bid={true}
                            onSubmit={e => actionSet(e.action)}
                            productCompanyId={productCompanyId}
                            product={product}
                            user={user}
                            product_type={product_type}
                            lastMessage={lastMessage} />
                    </SentMessageBubble>
                }
                {((action == 1 || action == 2 || action == 5 || action == 4)) &&
                    <SentMessageBubble isSystem={true}>
                        <AcceptRejectCounterOptionsAndMessage
                        settlementUnits={settlementUnits}
                            counterSettlementFields={counterSettlementFields}
                            setCounterSettlementFields={setCounterSettlementFields}
                            contractData={contractData}
                            bid={true}
                            action={action}
                            productCompanyId={productCompanyId}
                            product_type={product_type}
                            product={product}
                            brandStore={brandStore}
                            lastMessage={lastMessage}
                            price={price}
                            paymentTermId={lastMessage?.paymentTerm?._id}
                            ccPercentage={''}
                            liftingDays={lastMessage.liftingDays}
                            quantity={lastMessage.quantity}
                            currency={currency}
                            minimumOrderQuantity={minimumOrderQuantity}
                            quantityTickSize={quantityTickSize}
                            quantityRangeMax={quantityRangeMax}
                            quantityRangeMin={quantityRangeMin}
                            priceTickSize={priceTickSize}
                            creditDays={''}
                            specialRequest={specialRequest}
                            sendingMessageSet={e => sendingMessageSet(e)}
                            sendingMessage={sendingMessage}
                            userReferenceListSet={e => userReferenceListSet(e)}
                            userReferenceList={userReferenceList}
                            rejectreasons={rejectreasons}
                            expiryValue={expiryValue}
                            setexpiryValue={setexpiryValue}
                            deliverytermsArray={deliverytermsArray}
                            contract={contract}
    
                            onSubmit={e => {
                                if (action == 5) {
                                    onSubmit({ action: action, message: e.message, reason_ids: e.reasons, data: e.data, autoQuote: e.autoQuote })
                                }
                                else {
                                    onSubmit({ action: action, message: e.message, data: e.data, autoQuote: e.autoQuote, communicationId: chats[0].communicationId })
                                }
                            }} />
                    </SentMessageBubble>
                }
                {(lastMessage.action == 5 || lastMessage.action == 6 || lastMessage.action == 3 || (lastMessage.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.createdAt, "yyyy-mm-dd HH:MM:ss"))) &&
                    <>
                        {(
                            ((lastMessage.action == 5) || lastMessage.action == 6 ||
                                (lastMessage.action == 3) || (lastMessage.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.createdAt, "yyyy-mm-dd HH:MM:ss")))) &&
                            <SentMessageBubble>
                                {((lastMessage.action == 5 || lastMessage.action == 6 || lastMessage.action == 3) && action != 1) &&
                                    <Box textAlign="center">
                                        <Button size="small" variant="contained" color="primary" onClick={e => { actionSet(1); reBidSet(true); }}>
                                            SEND YOUR BID
                                        </Button>
                                    </Box>
                                }
                                {(action == 1 && contractData) &&
                                    <OfferAndBidMessage
                                        contractClusterIds={lastMessage?.contractClusterIds || []}
                                        contractData={contractData}
                                        productCompanyId={productCompanyId}
                                        product={product}
                                        product_type={product_type}
                                        price={price}
                                        paymentTermId={payment_term_id}
                                        ccPercentage={cc_percentage}
                                        liftingDays={days}
                                        reBid={reBid}
                                        brandStore={brandStore}
                                        currency={currency}
                                        creditDays={lastMessageAction?.message_log?.data?.no_credit_days ? lastMessageAction?.message_log?.data?.no_credit_days : creditDays}
                                        paymentDays={lastMessageAction?.message_log?.data?.payment_days ? lastMessageAction?.message_log?.data?.payment_days : paymentDays}
                                        quantity={quanityt}
                                        minimumOrderQuantity={minimumOrderQuantity}
                                        quantityTickSize={quantityTickSize}
                                        quantityRangeMax={quantityRangeMax}
                                        quantityRangeMin={quantityRangeMin}
                                        priceTickSize={priceTickSize}
                                        expiryValue={expiryValue}
                                        setexpiryValue={setexpiryValue}
                                        sendingMessage={sendingMessage}
                                        sendingMessageSet={e => sendingMessageSet(e)} userReferenceListSet={e => userReferenceListSet(e)} userReferenceList={userReferenceList}
                                        specialRequest={specialRequest}
                                        deliverytermsArray={deliverytermsArray}
                                        contract={contract}
    
                                        onSubmit={e => onSubmit({ message: e.message, action: action, data: e.data })}
                                    />
                                }
                            </SentMessageBubble>
                        }
                    </>
                }
            </>
        }
        else {
            // console.log('sender == logged in user')
            // Current user is seller
    
            //  offer condition
    
            if (lastMessage.action == 2 && contractData && lastMessage.senderCompanyId != user?.activeCompanyId) {
                return <>
                    <SentMessageBubble>
                        <NextActionOptions
                            offer={true}
                            onSubmit={e => actionSet(e.action)}
                            productCompanyId={productCompanyId}
                            product={product}
                            user={user}
                            product_type={product_type}
                            lastMessage={lastMessage} />
                    </SentMessageBubble>
                    {(action == 1 || action == 2 || action == 5 || action == 4) &&
                        <SentMessageBubble isSystem={true}>
                            <AcceptRejectCounterOptionsAndMessage
                            settlementUnits={settlementUnits}
                                counterSettlementFields={counterSettlementFields}
                                setCounterSettlementFields={setCounterSettlementFields}
                                contractData={contractData}
                                offer={true}
                                action={action}
                                productCompanyId={productCompanyId}
                                product_type={product_type}
                                product={product}
                                brandStore={brandStore}
                                lastMessage={lastMessage}
                                // price={lastMessage.price}
                                price={price}
                                paymentTermId={lastMessage?.paymentTerm?._id}
                                ccPercentage={''}
                                liftingDays={lastMessage.liftingDays}
                                quantity={lastMessage.quantity}
                                currency={currency}
                                minimumOrderQuantity={minimumOrderQuantity}
                                quantityTickSize={quantityTickSize}
                                quantityRangeMax={quantityRangeMax}
                                quantityRangeMin={quantityRangeMin}
                                priceTickSize={priceTickSize}
                                creditDays={''}
                                specialRequest={specialRequest}
                                sendingMessageSet={e => sendingMessageSet(e)}
                                sendingMessage={sendingMessage}
                                userReferenceListSet={e => userReferenceListSet(e)}
                                userReferenceList={userReferenceList}
                                rejectreasons={rejectreasons}
                                expiryValue={expiryValue}
                                setexpiryValue={setexpiryValue}
                                deliverytermsArray={deliverytermsArray}
                                contract={contract}
                                onSubmit={e => {
                                    if (action == 5) {
                                        onSubmit({ action: action, message: e.message, reason_ids: e.reasons, data: e.data, autoQuote: e.autoQuote })
                                    }
                                    else {
                                        onSubmit({ action: action, message: e.message, data: e.data, autoQuote: e.autoQuote, communicationId: chats[0].communicationId })
                                    }
                                }} />
                        </SentMessageBubble>
                    }
                </>
            }
            if ((lastMessage.action == 5 || lastMessage.action == 6 || lastMessage.action == 3 || (lastMessage.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.createdAt, "yyyy-mm-dd HH:MM:ss")))) {
                if ((lastMessage.action == 5) || lastMessage.action == 6 ||
                    (lastMessage.action == 3) || (lastMessage.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.createdAt, "yyyy-mm-dd HH:MM:ss"))) {
                    return <SentMessageBubble>
                        {((lastMessage.action == 5 || lastMessage.action == 6 || lastMessage.action == 3) && action != 2) &&
                            <Box textAlign="center" >
                                <Button size="small" variant="contained" color="primary" onClick={e => { actionSet(2); reOfferSet(true); }}>
                                    SEND YOUR OFFER
                                </Button>
                            </Box>
                        }
                        {(action == 2 && contractData) &&
                            <OfferAndBidMessage
                                contractClusterIds={lastMessage?.contractClusterIds || []}
                                sendingMessage={sendingMessage}
                                sendingMessageSet={e => sendingMessageSet(e)}
                                userReferenceListSet={e => userReferenceListSet(e)}
                                userReferenceList={''}
                                productCompanyId={productCompanyId}
                                product={product}
                                product_type={product_type}
                                price={price}
                                brandStore={''}
                                paymentTermId={''}
                                ccPercentage={''}
                                liftingDays={days}
                                reOffer={reOffer}
                                currency={currency}
                                quantity={quanityt}
                                minimumOrderQuantity={minimumOrderQuantity}
                                quantityTickSize={quantityTickSize}
                                quantityRangeMax={quantityRangeMax}
                                quantityRangeMin={quantityRangeMin}
                                priceTickSize={priceTickSize}
                                creditDays={''}
                                paymentDays={''}
                                specialRequest={''}
                                expiryValue={expiryValue}
                                setexpiryValue={setexpiryValue}
                                deliverytermsArray={deliverytermsArray}
                                contract={contract}
                                contractData={contractData}
                                onSubmit={e => onSubmit({ message: e.message, data: e.data, action: action })}
                            />
    
                        }
                    </SentMessageBubble>
                }
            }
            return <></>
        }
    }
    
    
}

/**
* @name ChatModalV3
* @alias ChatModalV3
* @component
* @description
* Chat box for user
*
*/

export default function ChatModalV3(props) {
    // console.log({ propsInChatModal: props })
    const navigate = useNavigate();
    const classes = useStyles();
    const [chatThreadInit, chatThreadInitSet] = React.useState(null)
    const [chatVisibility, chatVisibilitySet] = useState(false);
    const user = useSelector(state => state.userReducer.user);
    const globalSetting = useSelector(state => state.commonReducer.globalSettings);
    const [chats, setChats] = useState([]);
    const [communicationData, setCommunicationData] = useState({});
    const [dealDocData, setdealDocData] = useState({});
    const prevChats = usePrevious(chats);
    const [newMessage, setNewMessage] = useState("");
    // const [commYes, commYesSet] = useState(null);
    const [firstMessage, firstMessageSet] = useState(null);
    const [lastMessage, lastMessageSet] = useState(null);
    const [lastMessageAction, lastMessageActionSet] = useState(null);
    // const [data, setData] = useState(props.data)
    const [sendingMessage, sendingMessageSet] = React.useState(false)
    const [userReferenceList, userReferenceListSet] = React.useState([]);
    // const [referencePopUp, referencePopUpSet] = React.useState(false);
    // const [currencyValidationDialog, currencyValidationDialogSet] = React.useState(false);
    const [submittedNextAction, submittedNextActionSet] = React.useState(null)
    // const [blockDate, setBlockDate] = useState(null)
    const [isVisible, setIsVisible] = React.useState(false);
    // const [badgesData, setBadgesData] = React.useState();
    const [currentUserType, currentUserTypeSet] = React.useState('')
    const [communicationId, communicationIdSet] = React.useState(null)
    const [listing, listingSet] = React.useState(null)
    // const [mutualContactLoading, setMutualContactLoading] = React.useState(false);
    // const [commYesNoLoading, setCommYesNoLoading] = React.useState(false);
    // const [mutualContactModalOpen, mutualContactModalOpenSet] = React.useState(false);
    // const [mutualContactData, mutualContactDataSet] = React.useState([]);
    const [rejectReasons, rejectReasonsSet] = React.useState([]);
    const [commNoDialog, setCommNoDialog] = React.useState(false);
    const [contractData, setContractData] = React.useState();
    const [expiryValue, setexpiryValue] = React.useState([]);
    const [chatRoomId, chatRoomIdSet] = React.useState(null);
    const [selectedListingThumb, selectedListingThumbSet] = React.useState(null);
    const [fetchedImagesFromChat, setFetchedImagesFromChat] = React.useState([])
    const [counterSettlementFields, setCounterSettlementFields] = React.useState(null);
    const [settlementUnits, setSettlementUnits] = React.useState([])
    // const [fetchedContract, setFetchedContract] = React.useState(null);
    React.useEffect(() => {
        setIsVisible(true)
    }, [])

    React.useEffect(() => {
        if (firstMessage && communicationId == null) {
            communicationIdSet(firstMessage.communicationId)
        }
    }, [firstMessage])

    // React.useEffect(() => {
    //     // Redirect user to add company page if no company is added
    //     if (!user?.activeCompanyId || !user.active_company) {
    //         navigate('/add-company-eKyc')
    //     }
    // }, [user])

    // React.useEffect(() => {
    //     setIsVisible(true)
    // }, [])

    // React.useEffect(() => {
    //     // Redirect user to add company page if no company is added
    //     if (!user?.activeCompanyId || !user.active_company) {
    //         navigate('/add-company-eKyc')
    //     }
    // }, [user])

    React.useEffect(() => {
        chatThreadInitSet(true)

        fetchExpiryValues();
        fetchContract(props?.data?.contractId);

        fetchChats(props.data.chat_company_id);
        rejectreasonsData(1)

        // let interval = setInterval(() => {
        //     fetchChats(params);
        // }, 3000);

        // return () => clearInterval(interval);

    }, [
        props.data?.chat_company_id,
        props.data?.listing_id, ,

    ]);

    const fetchExpiryValues = async (params) => {
        if (window.location.pathname.includes('/console/admin')) {
            try {
                const res = await ConsoleCommodity.expiryValues({ type: 1 });
                if (res.status == 200) {
                    setexpiryValue(res.data.data.expiryValues);
                }
            } catch (e) {
                console.error(e);
            }
        } else {
            try {
                const res = await CommodityUser.expiryValues({ type: 1 });
                if (res.status == 200) {
                    setexpiryValue(res.data.data.expiryValues);
                }
            } catch (e) {
                console.error(e);
            }
        }
    };
    const fetchContract = (id) => {
        if (window.location.pathname.includes('/console/admin')) {
            var params = Object.assign({
                "search[_id]": id,

            });
            ConsoleCommodity
                .Contracts(params)
                .then(response => {

                    if (response.status == 200) {
                        setContractData(response.data.data.contracts[0])
                    }
                    else {

                    }
                });
        } else {
            var params = Object.assign({
                "search[_id]": id,
            });
            CommodityUser
                .Contracts(params)
                .then(response => {

                    if (response.status == 200) {
                        setContractData(response.data.data.contracts[0])
                    }
                });
        }
    };

    const fetchChats = (id) => {

        if (window.location.pathname.includes('/console/admin')) {
            console.log('fetch chats admin', { id, propUser: props.userId })
            ConsoleCommodity
                .fetchMsgs(props.userId._id, id)
                .then(response => {

                    if (response.status == 200) {
                        console.log({ responseFetchChats: response })

                        if (response?.data?.data?.messages?.length != chats.length && response.data?.data?.messages?.length > 0) {
                            chatThreadInitSet(false)
                            var resData = response.data.data.messages.reverse()
                            setChats(resData);
                            setCommunicationData(response.data.data?.communication);
                            setdealDocData(response.data.data?.dealDoc)
                            chatRoomIdSet(response.data.data?.communication.chatRoomId)
                            setFetchedImagesFromChat(response.data.data.communication.lastMessage.images)
                            setCounterSettlementFields(response.data.data.communication.lastBidOfferMessage.settlementFields)
                            setSettlementUnits(response.data.data.communication.listing.settlementFields)
                            lastMessageSet(response.data.data?.communication?.lastMessage);
                            lastMessageActionSet(response.data.data?.communication?.lastMessage?.action)
                            if (sendingMessage == true) {
                                sendingMessageSet(false)
                            }
                        }

                        if (response?.data?.data?.messages.length > 0 && chatThreadInit != true) {
                            chatThreadInitSet(false)
                            if (sendingMessage == true) {
                                sendingMessageSet(false)
                            }
                        }
                    }
                    else {
                        chatThreadInitSet(true)
                    }
                });
        } else {
            CommodityUser
                .fetchChatMessages(id)
                .then(response => {
                    if (response.status == 200) {
                        console.log({ responseChatMessage:response })
                        setCounterSettlementFields(response.data.data.communication?.lastBidOfferMessage?.settlementFields)
                        if (response.data.data.messages?.length != chats?.length && response.data.data.messages?.length > 0) {
                            chatThreadInitSet(false)
                            var resData = response.data.data.messages.reverse()
                            setChats(resData);
                            setCommunicationData(response.data.data?.communication);
                            setdealDocData(response.data.data?.dealDoc)
                            chatRoomIdSet(response.data.data?.communication.chatRoomId)
                            setFetchedImagesFromChat(response.data.data.communication.lastMessage.images)
                            setSettlementUnits(response.data.data.communication.listing.settlementFields)
                            // setBlockDate(response.data.first_block_date);
                            // if (resData.length === 1) {
                            //     firstMessageSet(resData[0]);
                            // } else {
                            //     firstMessageSet(resData[resData.length - 2]);
                            // }
                            lastMessageSet(response.data.data?.communication?.lastMessage);
                            // const lastActionMsg = resData.filter(e => {
                            //     return e.action == 1 || e.action == 2;
                            // })
                            lastMessageActionSet(response.data.data?.communication?.lastMessage?.action)

                            if (sendingMessage == true) {
                                sendingMessageSet(false)
                            }
                        }

                        if (response.data.data.messages?.length > 0 && chatThreadInit != true) {
                            chatThreadInitSet(false)
                            if (sendingMessage == true) {
                                sendingMessageSet(false)
                            }
                        }
                    }
                    else {
                        chatThreadInitSet(true)
                    }
                });
        }

    };

    const connectToRoom = () => {
        if (chatRoomId) {
            socket.emit('joinRoom', chatRoomId);
            socketAdmin.emit('joinRoom', chatRoomId);
            // socket.emit('joinRoom', 'newChatMessage');
            socket.on('newChatMessage', (data) => {
                fetchChats(data.communicationId)
            });
            socketAdmin.on('newChatMessage', (data) => {
                fetchChats(data.communicationId)
            });
        }
    }

    React.useEffect(() => {
        if (chatRoomId) {
            connectToRoom();
        }
        return () => {
            socket.off(chatRoomId);
            socket.off('newChatMessage')
            socketAdmin.off(chatRoomId);
            socketAdmin.off('newChatMessage')
        }
    }, [chatRoomId])

    const rejectreasonsData = (type) => {
        if (window.location.pathname.includes('/console/admin')) {
            ConsoleCommodity.dealConsoleRejectReason(type).then(response => {
               
                if (response.status == 200) {
                    rejectReasonsSet(response.data.data.rejectReasons)
                }
               
            });
        } else {
            CommodityUser
                .dealRejectReason(type)
                .then(response => {

                    if (response.status == 200) {
                        rejectReasonsSet(response.data.data.rejectReasons)
                    }
                    else {

                    }
                });
        }


    };

    React.useEffect(() => {
        Emitter.on("OVERSHOOT_QUANTITY", (e) => {
            sendingMessageSet(false)
        });
    }, [])

    // React.useEffect(() => {
    //     if (prevChats != undefined && chats.length != prevChats.length) {
    //         const lastmsg = chats[chats.length - 1]

    //         if (sendingMessage == true) {
    //             sendingMessageSet(false)
    //         }
    //     }
    // }, [chats]);

    React.useEffect(() => {
        if (prevChats != undefined && chats.length != prevChats.length) {
            var chatBody = document.getElementById("chatBody");
            if (chatBody) {
                chatBody.scrollTop = chatBody.scrollHeight;
            }
        }
        return () => { }
    }, [chats, props.data, props.data.listing_id, listing])

    const sendMessage = e => {
        e.preventDefault();
        sendMessageHttpReq({ message: newMessage })
    };

    const sendMessageHttpReq = (data) => {
        // console.log('data in send message', data);

        if (window.location.pathname.includes('/console/admin')) {

            sendingMessageSet(true)
            const formdata = new FormData()
            // accept case
            if (data?.action == "4" || data?.action == 3) {
                formdata.append('action', data.action ? parseInt(data.action) : 0)
                formdata.append('listingId', props?.data?.listing_id)
                formdata.append('originListingId', props?.data?.originListingId)
                if (communicationData?._id) {
                    formdata.append('communicationId', communicationData._id);
                }
                // reject case
            } else if (data?.action == "5") {
                formdata.append('action', data.action ? parseInt(data.action) : 0)
                formdata.append('listingId', props?.data?.listing_id)
                formdata.append('originListingId', props?.data?.originListingId)
                if (communicationData?._id) {
                    formdata.append('communicationId', communicationData._id);
                }
                // append each reason to separate field lines with the same key because formdata does not take data in array.
                data.reason_ids && data.reason_ids.map(item => {
                    formdata.append('rejectReasonIds', item);
                })
                // formdata.append('otherRejectReasonText', "other");

                // every case except accept and reject
            } else {
                formdata.append('action', data.action ? parseInt(data.action) : 0)
                formdata.append('listingId', props?.data?.listing_id)
                formdata.append('originListingId', props?.data?.originListingId)
                formdata.append('quantity', parseInt(data?.data?.quantity))
                formdata.append('price', data?.data?.price)
                formdata.append('validity', 30)
                formdata.append('paymentTermId', data?.data?.paymentModeId)
                formdata.append('liftingDays', parseInt(data?.data?.lifting));
                if (communicationData?._id) {
                    formdata.append('communicationId', communicationData._id);
                }
                formdata.append('text', newMessage)
                formdata.append('comments', data?.data?.comment);
                data?.data?.images.map((item) => formdata.append('images', item.file))
                data?.data?.settlementFields.map((item) => formdata.append(`settlementFields[${item.name}]`, item.value))
                data?.data?.contractClusters.map(c => formdata.append('contractClusterIds[]', c._id))
            }

            ConsoleCommodity.sendMsg(props.userId._id, formdata)
                .then(response => {
                    sendingMessageSet(false)
                    console.log('res send message', response)
                    if (communicationId == null) {
                        communicationIdSet(response.data.data.communicationId)
                    }
                    setNewMessage("");
                    fetchChats(response.data.data.communicationId);

                    submittedNextActionSet(true);
                })
                .catch(error => {
                    console.log('error while sending message', error)
                    console.log('error response ', error.response)
                })
                .finally(() => {
                    sendingMessageSet(false)
                });


        } else {
            sendingMessageSet(true)
            const formdata = new FormData()
            // accept case
            if (data?.action == "4" || data?.action == 3) {
                formdata.append('action', data.action ? parseInt(data.action) : 0)
                formdata.append('listingId', props?.data?.listing_id)
                formdata.append('originListingId', props?.data?.originListingId)
                if (communicationData?._id) {
                    formdata.append('communicationId', communicationData._id);
                }
                // reject case
            } else if (data?.action == "5") {
                formdata.append('action', data.action ? parseInt(data.action) : 0)
                formdata.append('listingId', props?.data?.listing_id)
                formdata.append('originListingId', props?.data?.originListingId)
                if (communicationData?._id) {
                    formdata.append('communicationId', communicationData._id);
                }
                // append each reason to separate field lines with the same key because formdata does not take data in array.
                data.reason_ids && data.reason_ids.map(item => {
                    formdata.append('rejectReasonIds', item);
                })
                // formdata.append('otherRejectReasonText', "other");

                // every case except accept and reject
            } else {
                formdata.append('action', data.action ? parseInt(data.action) : 0)
                formdata.append('listingId', props?.data?.listing_id)
                formdata.append('originListingId', props?.data?.originListingId)
                formdata.append('quantity', parseInt(data?.data?.quantity))
                formdata.append('price', data?.data?.price)
                formdata.append('validity', 30)
                formdata.append('paymentTermId', data?.data?.paymentModeId)
                formdata.append('liftingDays', parseInt(data?.data?.lifting));
                if (communicationData?._id) {
                    formdata.append('communicationId', communicationData._id);
                }
                formdata.append('text', newMessage)
                formdata.append('comments', data?.data?.comment);
                data?.data?.images.map((item) => formdata.append('images', item.file))
                data?.data?.settlementFields.map((item) => formdata.append(`settlementFields[${item.name}]`, item.value))
                data?.data?.contractClusters.map(c => formdata.append('contractClusterIds[]', c._id))
            }

            CommodityUser.sendChat(formdata)
                .then(response => {
                    console.log({responoseSendMsg:response})
                    sendingMessageSet(false)

                    if (communicationId == null) {
                        communicationIdSet(response.data.data.communicationId)
                    }
                    setNewMessage("");
                    fetchChats(response.data.data.communicationId);

                    submittedNextActionSet(true);

                })
                .finally(() => {
                    sendingMessageSet(false)
                });
        }
        //  console.log(props.data.product._id?props.data.product._id:props?.data?.listing_id)dsjbbjfdjdshjgdsghhfdshgdfsg
        // if ((user?.country_mobile_prefix != '+91' && listing.currency != 'INR') || (user?.country_mobile_prefix == '+91' && listing.currency)) {

    }

    const handleChatVisibility = () => {
        chatVisibilitySet(!chatVisibility)
    }

    const chatVisibilityStyle = chatVisibility ? { top: 'calc( 100vh - 45px)' } : {};

    return (
        <>
            <Slide direction="up" in={isVisible} mountOnEnter unmountOnExit>
                <div className={props?.static === true ? classes.chatModal : `chatModal`} 
                // style={chatVisibility === true ? { top: 'calc( 100vh - 45px )'} : {}}>
                style={
                    !window.location.pathname.includes('console/admin/') ? (
                        !props.matches ? { ...chatVisibilityStyle, maxHeight: '820px', zIndex: 500, top: '50px', bottom: '56px', position: 'absolute'} : {...chatVisibilityStyle}
                    ) : (
                        // !props.matches ? { ...chatVisibilityStyle,} : 
                        {...chatVisibilityStyle}   
                    )
                }
                    >
                    {/* <Box className={props?.static === true ? classes.chatInfoWhite : `${classes.chatInfo} text-white`} sx={{ p: 1.5, borderBottom: '1px solid #ccc' }} >

                        <Box className={(props?.type === "expired-listings" || props?.type == "inbox-chat-history" || props?.type == "inbox") ? classes.gridCol2Button : ''}>

                            <Box textAlign='right'>
                                {props?.type == "inbox-chat-history" && props.footerButtons
                                }
                                {props?.type == "inbox" && props.footerButtons
                                }
                                {props?.type === "expired-listings" &&
                                    <RePostListingModal
                                        selectedListingID={props.data.listing_id}
                                        selectedQuantity={listing.quantity}
                                        isAuction={listing?.vessel_attribute?.vessel?.is_auction}
                                    />
                                }
                            </Box>
                        </Box>
                    </Box> */}
                    {props?.static === true ?
                        //chat dialog view for my inbox/my listings
                        // chat header
                        <Box className={classes.chatHeader} mb={1}>
                            <Box sx={{ mb: .75 }}>
                                <Box>

                                <Typography component="strong" variant="subtitle2" >
{/* <Button onClick={()=>console.log('contract', contractData, 'selectedListingThumb', selectedListingThumb)}>cccccccc</Button> */}
                                        {
                                        // lastMessage && lastMessage.action == 8 ? (badgesData?.company_name) :
                                        // (
                                            <>
                                        {/* 'Mandi.trade sec User' */}
                                <Grid container spacing={2} style={{ marginTop:"-50px"}}>
                                    <Grid item xs={8} style={{paddingBottom:'-10px', marginTop:'50px'}}>
                                        {/* <Button onClick={()=>console.log(contractData)}>cont</Button>
                                        <Button onClick={()=>console.log(props)}>propos</Button> */}
                                        <Typography variant="caption" component="strong">
                                            {window.location.pathname.includes('/console/admin') &&
                                             props.data.commNo && `comm no. - ${props.data.commNo} | list no. - ${props.data.listing.listingNumber}` 
                                            //  : props.data.commNo && `comm no. - ${props.data?.commNo} | list no. - ${props.data?.listingNo}`
                                            }
                                        </Typography>
                                        <Box sx={{marginTop:'10px', marginBottom:"20px"}}>
                                                <Box fontWeight="bold" style={{ marginTop:'15px' }}>
                                                    <Box style={{display:'flex', flexDirection:"row", justifyContent:"space-between", alignItems:'center'}}> 
                                                    <Tooltip title={contractData?.name}>
                                                        {contractData?.name.length > 50 ? `${contractData?.name}...` : contractData?.name}
                                                    </Tooltip> <span style={{backgroundColor:props?.data?.listing?.listingType == 1 ? "#32CD32" : "#EF0107", borderRadius:'25%', padding:'5px', color:'whitesmoke', paddingRight:'10px', paddingLeft:'10px'}}>
                                                        {props?.data?.listing?.listingType == 1 ? 'Buy' : 'Sell'}
                                                        </span>
                                                    </Box>
                                                        <p style={{ color: "#2A59B7", marginTop:"5px", }}>{contractData?.commodity?.commodityType?.name}-{contractData?.commodity?.name}
                                                        </p>
                                                </Box>
                                                {/* </Box> */}
                                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginTop: '-30px',}}>
                                                    <Box style={{ marginBottom: '-20px', marginTop: "20px" }}>
                                                        <Box sx={{display:"flex", flexDirection:'row', justifyContent:'space-between', gap: "16px"}}>
                                                            <Box style={{ color: "#2A59B7", marginTop: '0' }}>
                                                                {props.data.product && props.data.product.quantity} {props.data.product && props.data.product.quantityUnit.name}
                                                            </Box>
                                                            <Box style={{ color: "#b72a57", marginTop: '0' }}>
                                                                {props.data.product && props.data.product.price} {props.data.product && props.data.product.currency}/{props.data.product && props.data.product.quantityUnit.name}
                                                            </Box>
                                                        </Box>
                                                        <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: "#2A59B7", marginTop: '0' }}>
                                                            {props.data.product?.settlementFields?.map((s, index) => (
                                                                <span key={index} style={{ marginRight: '4px' }}>{s?.value}-{s?.units[0]?.name || ''}&nbsp;</span>
                                                            ))}
                                                        </Box>
                                                    </Box>
                                                
                                                </Box>
                                            </Box>

                                            {/* </AccordionDetails>
                                        </Accordion> */}
                                    </Grid>
                                    <Grid item xs={4}>
                                    {/* {contractData?.images && contractData?.images.length > 0 && (
                                        <ListingThumb
                                        onClick={() =>
                                            selectedListingThumbSet(contractData)
                                        }
                                        image={contractData?.images[0]}
                                        />
                                    )} */}
                                        <Box 
                                        sx={{ 
                                            marginTop: '50px',
                                            height: '100px', 
                                            width: '100px', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            alignItems: 'center',
                                            overflow: 'hidden' // This ensures that any overflow is hidden
                                        }}
                                        >
                                        {
                                        props.data?.listing?.images && props.data?.listing?.images.length > 0 ? 
                                            (
                                                
                                                <ListingThumb
                                                onClick={() => selectedListingThumbSet(props.data.listing)}
                                                image={props.data.listing.images[0]}
                                                sx={{ 
                                                    width: '100%', 
                                                    height: '100%', 
                                                    objectFit: 'cover' // Ensures the image covers the entire box without distortion
                                                }}
                                                />
                                            )
                                        :
                                        contractData?.images && contractData?.images?.length > 0 && (
                                            <ListingThumb
                                            onClick={() => selectedListingThumbSet(contractData)}
                                            image={contractData?.images[0]}
                                            sx={{ 
                                                width: '100%', 
                                                height: '100%', 
                                                objectFit: 'cover' // Ensures the image covers the entire box without distortion
                                            }}
                                            />
                                        )}
                                        </Box>
                                    </Grid>
                                </Grid>
                                            </>
                                        // )
                                        }
                                    </Typography>

                                    <Box className={classes.gridCol2IconStatic} sx={{ mb: .75, display: { xs: 'block' } }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>{'🚩 : changes in values are flagged'}</Box>
                                            <Box>
                                                <Link color="primary" style={{ textDecoration: 'underline', textTransform: 'inherit' }}
                                                    // to={`/view-product-details/${cEncrypt(
                                                    //     props.data.listing_id
                                                    // )}`}
                                                    to={`/listings/${props.data.listingSlug}`}
                                                    target="_blank"
                                                    rel="noopener"
                                                    component={Link}
                                                > View listing details
                                                </Link>
                                            </Box>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        :
                        //chat dialog view for markets page and notification
                        // chat header
                        <Box className={classes.chatHeader} style={{ marginBottom: '4px', padding: '2px 10px' }}>
                            <Box sx={{ mb: .75 }}>
                                <Box>
                                    <Typography component="strong" variant="subtitle2">
{/* <Button onClick={()=>console.log('contract', contractData, 'selectedListingThumb', selectedListingThumb)}>cccccccc</Button> */}
                                        {
                                        // lastMessage && lastMessage.action == 8 ? (badgesData?.company_name) :
                                        // (
                                            <>
                                        {/* 'Mandi.trade sec User' */}
                                <Grid container spacing={2} style={{ 
                                    // padding: 0,
                                    paddingBottom: 0,
                                    // margin: 0,
                                    marginTop: '-20px',
                                    // marginLeft: 1,
                                    // height: 'auto',
                                    // border:"2px solid blue"
                                    }} >
                                    <Grid item xs={8} style={{
                                        // display: 'flex',
                                        // flexDirection: 'column',
                                        // padding: 1, 
                                        paddingBottom: 0,
                                        // margin:0, 
                                        height: 'auto',
                                        // border:"2px solid red"
                                        }}>
                                            <Box sx={{ 
                                                marginTop: '10px',
                                                // marginBottom:"0px",
                                                // paddingBottom: 0,
                                                padding: 0,
                                                }}>
                                                <Box fontWeight="bold" style={{ marginTop:'0px' }}>
                                                    {
                                                        window.location.pathname.includes('/console/admin') && 
                                                        <Typography variant="" sx={{marginTop: 0}}>
                                                            {props.data.commNo && `comm no. - ${props.data.commNo} | list no. - ${props.data.listing.listingNumber}`}
                                                        </Typography>
                                                    }
                                                    <Box style={{display:'flex', flexDirection:"row", justifyContent:"space-between", alignItems:'center'}}>
                                                    <Tooltip title={contractData?.name}>
                                                        {contractData?.name.length > 50 ? `${contractData?.name}...` : contractData?.name}
                                                    </Tooltip>
                                                     <span style={{backgroundColor:props?.data?.listing?.listingType == 1 ? "#32CD32" : "#EF0107", borderRadius:'25%', padding: '5px 10px', color:'whitesmoke', paddingRight:'10px', paddingLeft:'10px'}}>
                                                        {props?.data?.listing?.listingType == 1 ? 'Buy' : 'Sell'}
                                                        </span>
                                                    </Box>
                                                        <p style={{ color: "#2A59B7", marginTop:"5px", }}>{contractData?.commodity?.commodityType?.name}-{contractData?.commodity?.name}
                                                        </p>
                                                </Box>
                                                {/* </Box> */}
                                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', margin: 0}}>
                                                    <Box style={{ margin: 0 }}>
                                                        <Box sx={{display:"flex", flexDirection:'row', justifyContent:'space-between', gap: '16px'}}>
                                                            <Box style={{ color: "#2A59B7", marginTop: '0' }}>
                                                                {props.data.listing && props.data.listing.quantity} {props.data.listing && props.data.listing.quantityUnit.name}
                                                            </Box>
                                                            <Box style={{ color: "#2A59B7", margin: '0' }}>
                                                                {props.data.listing && props.data.listing.price} {props.data.listing && props.data.listing.currency}/{props.data.listing && props.data.listing.quantityUnit.name}
                                                            </Box>
                                                        </Box>
                                                        <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: "#2A59B7", margin: 0 }}>
                                                            {props.data.listing?.settlementFields?.filter(sf => sf?.value).map((s, index, arr) => (
                                                                <span key={index} style={{ marginRight: '4px' }}>{s?.value}-{s?.units[0]?.name}&nbsp;</span>
                                                            ))}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>

                                            {/* </AccordionDetails>
                                        </Accordion> */}
                                    </Grid>
                                    <Grid item xs={4}>
                                    {/* {contractData?.images && contractData?.images.length > 0 && (
                                        <ListingThumb
                                        onClick={() =>
                                            selectedListingThumbSet(contractData)
                                        }
                                        image={contractData?.images[0]}
                                        />
                                    )} */}
                                        <Box 
                                        sx={{ 
                                            marginTop: '50px',
                                            height: '100px', 
                                            width: '100px', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            alignItems: 'center',
                                            overflow: 'hidden' // This ensures that any overflow is hidden
                                        }}
                                        >
                                        {props.data?.listing?.images && props?.data?.listing?.images?.length > 0 ? 
                                            (
                                                <ListingThumb
                                                onClick={() => selectedListingThumbSet(props.data.listing)}
                                                image={props.data.listing.images[0]}
                                                sx={{ 
                                                    width: '100%', 
                                                    height: '100%', 
                                                    objectFit: 'cover'
                                                }}
                                                />
                                            )
                                        :
                                        contractData?.images && contractData?.images?.length > 0 && (
                                            <ListingThumb
                                            onClick={() => selectedListingThumbSet(contractData)}
                                            image={contractData?.images[0]}
                                            sx={{ 
                                                width: '100%', 
                                                height: '100%', 
                                                objectFit: 'cover'
                                            }}
                                            />
                                        )}
                                        </Box>
                                    </Grid>
                                </Grid>
                                            </>
                                        // )
                                        }
                                    </Typography>
                                    <Box className={classes.gridCol2IconStatic} sx={{ mb: .75, display: { xs: 'block' } }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>{'🚩 : changes in values are flagged'}</Box>
                                            <Box>
                                                <Link color="primary" style={{ textDecoration: 'underline', textTransform: 'inherit' }}
                                                    // to={`/view-product-details/${cEncrypt(
                                                    //     props.data.listing_id
                                                    // )}`}
                                                    to={`/listings/${props.data.listingSlug}`}
                                                    target="_blank"
                                                    rel="noopener"
                                                    component={Link}
                                                > View listing details
                                                </Link>
                                            </Box>

                                        </Box>


                                    </Box>
                                </Box>
                            </Box>

                            <Box align="right">
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    sx={{ position: 'absolute', top: '-2px', right: '50px', width: '85%' }}
                                    onClick={e => {
                                        handleChatVisibility(e);
                                    }}
                                >
                                    <MinimizeIcon fontSize='small' style={{ position:'relative', color: 'transparent', marginBottom: 5, borderRadius:'50%', width:'100%' }} />

                                </IconButton>

                                <IconButton
                                    aria-label="close"
                                    size="small"

                                    onClick={e => {
                                        setIsVisible(false);
                                        ReactGA.event({ category: 'User', action: `${currentUserType == 'buyer' ? 'Buyer' : 'Seller'} closed chat window` });
                                        setTimeout(() => {
                                            props.onClose(e);
                                        }, 500);
                                    }}
                                    style={closeModalBtnStyle}
                                >
                                    <img src={`/images/close-icon.png`} alt="close" width='22' />
                                </IconButton>
                            </Box>

                        </Box>
                    }

                    {/* Chat body */}
                    <div id="chatBody" className={`chat-body clearfix ${classes.chatBody__smDown}`} 
                    // style={{ maxHeight: '80vh', overflowY: 'auto'}}
                    >
                        {/* To show listings comments */}
                        {/* {listing?.special_requests &&
                            <Box className={classes.gridCol2Icon} sx={{ fontSize: 10, color: '#2a2a2a', border: '1px solid #EA9129', p: .6, backgroundColor: '#FFF5EA', borderRadius: '4px', m: 1, mt: 0.2 }}>
                                <FeedbackIcon size="small" style={{ color: "#EA9129", fontSize: 20 }} />
                                <Typography style={{ fontSize: 12 }}>{listing.special_requests}</Typography></Box>
                        } */}

                        {/* To show fetch messages */}
                        {/* it will always run */}
                        {chats.length > 0 &&
                            chats.map((chat, index) => (
                                <>
                                    <MessageBubble productCompanyId={listing?.company_id} chat={chat} key={index} user={user} communicationData={communicationData} isSystem={chat.isSystem}>

                                        {chat.senderCompanyId !== user?.activeCompanyId && (
                                            <Box mb={1}>
                                                <strong>
                                                    <>Mandi.trade User</>
                                                </strong>
                                            </Box>
                                        )}

                                        <span style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }} >{chat.message}</span>
                                        <br></br>

                                        {chat.createdAt && <strong style={{ whiteSpace: 'pre-wrap', fontSize: '12px', marginTop: 1 }} >{new Date(chat.createdAt).toLocaleString("en-US", { timeZone: 'Asia/Kolkata' })}</strong>}

                                        <Grid item sm={12} style={{ marginTop: 5 }}>
                                            {chat?.images?.map((item, i) => (
                                                <Box key={i} style={{ display: "flex", flexDirection: 'column', marginBottom: "15px" }}>
                                                    <img width="175px" height="175px" src={`${process.env.REACT_APP_API_URL_COMMODITY}/${item.url}`} alt={`Image ${i}`} />
                                                </Box>
                                            ))}
                                        </Grid>
                                        {
                                            window.location.pathname.includes('/console/admin') ? (
                                                (lastMessage && lastMessage._id == chat._id && (chat.action == 1 || chat.action == 2) && chat.senderUserId === props.userId._id) &&
                                                <Box mt={2} >
                                                    <Button disabled={sendingMessage} size='small' type='button' color="primary" variant='contained' onClick={e =>{
                                                     { sendMessageHttpReq({ message: 'Unfortunately, we are withdrawing the ' + (lastMessage.action == 1 ? 'bid ' : 'offer') + ' due to unforeseen circumstances.', action: 3, communicationId: chat.communicationId, data: {} }) }}}>
                                                        {sendingMessage && <><CircularProgress size={24} /> &nbsp; </>}Withdraw
                                                    </Button>
                                                </Box>
                                            )
                                                :
                                               
                                                (
                                                    (lastMessage && lastMessage._id == chat._id && (chat.action == 1 || chat.action == 2) && chat.senderCompanyId === user?.activeCompanyId) &&
                                                <Box mt={2}>
                                                    <Button disabled={sendingMessage} size='small' type='button' color="primary" variant='contained' onClick={e => { sendMessageHttpReq({ message: 'Unfortunately, we are withdrawing the ' + (lastMessage.action == 1 ? 'bid ' : 'offer') + ' due to unforeseen circumstances.', action: 3, communicationId: chat.communicationId, data: {} }) }}>
                                                        {sendingMessage && <><CircularProgress size={24} /> &nbsp; </>}Withdraw
                                                    </Button>
                                                </Box>
                                                )
                                        }
                                        <br />

                                        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                            {/* {chat.sender_user._id == user._id && <>
                                                {(chat && chat.receiver_status && chat.receiver_status !== null && chat.receiver_status == 1) ? <DoneAllIcon style={{ fontSize: '20px', marginRight: '4px', verticalAlign: 'bottom', color: '#2959BA' }} /> : <DoneIcon style={{ verticalAlign: 'bottom', marginRight: '4px', fontSize: '20px' }} />
                                                }</>
                                            } */}
                                            {chat.senderUserId == user._id && <>
                                                {<DoneIcon style={{ verticalAlign: 'bottom', marginRight: '4px', fontSize: '20px' }} />
                                                }</>
                                            }

                                    
                                        </Box>

                                    </MessageBubble>


                                    {chat?.action == 8 && dealDocData && dealDocData.url && (
                                        <div style={{ margin: 'auto', clear: 'both', float: 'none', marginBottom: '12px' }} className={`chat-thread-system`}>


                                            <Box mb={.5} style={{ fontSize: '12px' }}>

                                                <Box mb={1}>You can download this deal document from here

                                                </Box>
                                                <Box textAlign="center">

                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(e) => {
                                                            window.open(process.env.REACT_APP_API_URL_COMMODITY + dealDocData?.url, "_blank")
                                                        }}
                                                    >
                                                        Download
                                                    </Button>

                                                </Box>

                                            </Box>
                                        </div>
                                    )}


                                </>
                            ))
                        }

                        {/* To show next action msg bubbles like accept, reject or counter bid/offer once first message is sent from another user*/}

                        {(chats.length > 0 && lastMessage && contractData) && 

                            <NextActionMessageBubbles
                            userType={props.userType}
                            consoleUser = {props.userId} // the user that the admin user is impersonating as
                            settlementUnits={settlementUnits} 
                                counterSettlementFields={counterSettlementFields}
                                setCounterSettlementFields={setCounterSettlementFields}
                                contractData={contractData}
                                sendingMessageSet={e => sendingMessageSet(e)}
                                sendingMessage={sendingMessage}
                                userReferenceListSet={e => userReferenceListSet(e)}
                                userReferenceList={''}
                                productCompanyId={props?.data.commodityId}
                                chatCount={chats.length}
                                product={props?.data}
                                // brandStore={props?.data?.brandStore}
                            commData={communicationData}
                                product_type={props?.data.listingType}
                                price={communicationData?.lastBidOfferMessage?.price}
                                payment_term_id={props?.data?.paymentTerm?._id}
                                // cc_percentage={listing?.cc_percentage}
                                // paymentDays={listing?.no_payment_days}
                                days={communicationData?.lastBidOfferMessage?.liftingDays}
                                // creditDays={listing?.num_credit_days}
                                currency={communicationData?.lastBidOfferMessage?.currency}
                                minimumOrderQuantity={props?.data?.contract ? props?.data?.contract.minQuantityListingSize : 25}
                                quantityTickSize={props?.data?.contract ? props?.data?.contract.quantityTickSize : 1} quantityRangeMax={props?.data?.contract ? props?.data?.contract.priceTickSize : 2.5}
                                quantityRangeMin={props?.data?.contract ? props?.data?.contract.tradeMultipleMin : 0.5}
                                priceTickSize={props?.data?.contract ? props?.data?.contract.priceTickSize : 1}
                                listing={listing}
                                specialRequest={communicationData?.lastBidOfferMessage?.comments}
                                lastMessageAction={lastMessageAction}
                                user={user}
                                lastMessage={lastMessage}
                                firstMessage={''}
                                onSubmit={e => { 
                                    sendMessageHttpReq({ 
                                        message: e.message, 
                                        action: e.action, 
                                        reason_ids: e.reason_ids, 
                                        data: e.data, 
                                        autoQuote: e.autoQuote, 
                                        communicationId: chats[0].communicationId,
                                        contractClusterIds: e.contractClusterIds
                                    }) 
                                }}
                                submittedNextAction={submittedNextAction}
                                expiryValue={expiryValue}
                                setexpiryValue={setexpiryValue}

                                chats={chats}
                                rejectreasons={rejectReasons}

                                deliverytermsArray={contractData?.deliveryTerms}
                                contract={contractData?.paymentTerms}

                                quanityt={communicationData?.lastBidOfferMessage?.quantity}
                            />

                        }

                        {/* To show bid/offer form [first component for chat to run] */}
                        {(chatThreadInit === true && chats.length == 0 && contractData) &&

                            <OfferAndBidMessage
                                contractClusterIds={lastMessage?.contractClusterIds || []}
                                settlementFields={props.settlementFields}
                                setSettlementFields={props.setSettlementFields}
                                sendingMessageSet={e => sendingMessageSet(e)}
                                sendingMessage={sendingMessage}
                                userReferenceListSet={e => userReferenceListSet(e)} userReferenceList={userReferenceList}
                                specialRequest={props.data.comments}
                                productCompanyId={''}

                                setexpiryValue={setexpiryValue}
                                product={''}
                                brandStore={''}
                                product_type={props.data.product_type}
                                price={props.data.price}
                                paymentTermId={''}
                                ccPercentage={''}
                                paymentDays={''}
                                liftingDays={props.data.days}
                                creditDays={''}
                                quantity={props.data.quanityt}
                                currency={''}
                                minimumOrderQuantity={25}
                                quantityTickSize={1}
                                quantityRangeMax={2.5}
                                expiryValue={expiryValue}

                                quantityRangeMin={0.5}
                                priceTickSize={1}
                                lastMessageAction={lastMessageAction}
                                deliverytermsArray={contractData?.deliveryTerms}
                                deliveryTerm={props.data.deliveryTerm}
                                contract={contractData?.paymentTerms}
                                contractData={contractData}
                                onSubmit={e => {

                                    sendMessageHttpReq({
                                        message: e.message,
                                        data: e.data,
                                        action: props?.data.product_type == 1 ? 2 : 1
                                    });
                                }}

                            />
                        }
                    </div>

                    {/* To show chat footer */}
                    <div className="chat-footer">
                        {(lastMessage && lastMessage.action == 8) &&
                            <form
                                onSubmit={e => {
                                    sendMessage(e);
                                    setNewMessage("");
                                }}
                            >
                                <div className="input-action" >
                                    <input
                                        className="chat-input"
                                        name="message"
                                        value={newMessage}
                                        onChange={e => setNewMessage(e.target.value)}
                                        required
                                    />
                                    <button type="submit">
                                        <SendIcon className="text-white h-100" />
                                    </button>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </Slide>


            {(commNoDialog === true) &&
                <DealRejectionForm
                    show={commNoDialog}
                    communicationId={communicationId}
                    listingId={props?.data?.listing_id}
                    setCommNoDialog={setCommNoDialog}
                    rejectReasons={rejectReasons}

                />
            }


            <Dialog
                open={!!selectedListingThumb}
                onClose={() => selectedListingThumbSet(null)}
                aria-labelledby="selectedListingThumb-title"
                aria-describedby="selectedListingThumb-description"
                maxWidth="md"
            >
                {selectedListingThumb && (
                    <DialogTitle id="selectedListingThumb-title">
                        <Typography variant="overline" display="block">
                            {selectedListingThumb?.contract?.commodity?.commodityType?.name} -{' '}
                            {selectedListingThumb?.contract?.commodity?.name}
                        </Typography>
                        <Grid container>
                            <Grid item sm={6}>

                                <Box
                                    style={{
                                        color:
                                            selectedListingThumb.listingType == 1 ? 'green' : 'red',
                                    }}
                                >
                                    {selectedListingThumb.listingType == 1 ? 'Buy' : 'Sell'}{' '}
                                    {selectedListingThumb.price} {selectedListingThumb?.currency}
                                </Box>
                                <Box>
                                    <span className={classes.textMuted}>
                                        {selectedListingThumb.paymentTerm?.name + ' Payment'}
                                    </span>
                                </Box>
                            </Grid>
                            <Grid item sm={6} textAlign="right">
                                <Box>
                                    <strong>
                                        {selectedListingThumb.quantity}{' '}
                                        {selectedListingThumb?.quantityUnit?.name} |
                                    </strong>
                                    <SpecialRequest
                                        specialRequestData={selectedListingThumb.special_requests}
                                        view_count={selectedListingThumb?.views}
                                    /><Button
                                        variant="contained"
                                        color="primary"
                                        component={RouterLink}
                                        to={`/listings/${selectedListingThumb.slug}`}

                                        size="small">View</Button>
                                </Box>
                                <Box>
                                    <span className={classes.textMuted}>
                                        {selectedListingThumb?.liftingDays} Lifting Days
                                    </span>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                )}
                {selectedListingThumb && (
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={8}>
                                <ImageGalleryBox
                                    thumbnailPosition="left"
                                    items={selectedListingThumb?.images.map((i) => ({
                                        // original: i.url,
                                        original: `${process.env.REACT_APP_API_URL_COMMODITY}/${i.url}`,
                                        // thumbnail: i.url,
                                        thumbnail: `${process.env.REACT_APP_API_URL_COMMODITY}/${i.url}`,
                                    }))}
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <DialogContentText mb={2} id="selectedListingThumb-description">
                                    <Typography variant="h6">
                                        {selectedListingThumb?.contract?.name} -{' '}
                                        {selectedListingThumb?.contract?.clusterName?.plural}
                                    </Typography>
                                    {selectedListingThumb?.contractClusters?.map((cc) => (
                                        <Box mb={2} key={cc._id}>
                                            <Typography
                                                variant="subtitle1"
                                                style={{ margin: 0, padding: 0 }}
                                            >
                                                {cc.name}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                style={{ margin: 0, padding: 0 }}
                                            >
                                                {cc.fields
                                                    .filter((f) => f.isMandatory)
                                                    .map((f) => `${f.name} - ${f.value}`)
                                                    .join(' | ')}
                                            </Typography>
                                        </Box>
                                    ))}
                                </DialogContentText>
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => selectedListingThumbSet(null)}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}